import React, { useState, useEffect, useContext } from "react";
import { IonChip, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import HeaderLocal from "Components/Header/HeaderLocal";
import { chevronDownOutline } from "ionicons/icons";
import { Accordion } from "react-bootstrap";
import "./LocalPickupView.css";
import { UseReload } from "App";
import ApiRequest from "Services/Api.Service";
import useLocalStorage from "../../../Services/useLocalStorage";
import { useHistory } from "react-router";
import Skeleton from "Components/Skeleton";
import { IonButton } from "@ionic/react";
import { dateShow } from "../../../events";
import PaginationCom from "Components/Pagination/Pagination";
import Config from "Config";

const LocalPickupView: React.FC = () => {
  const [viewNow, setViewNow] = useState("upcoming");
  const [reload] = useContext<any>(UseReload);
  const history = useHistory();
  const [status, setStatus] = useState<any>("SCHEDULED");
  const [pendingPickups, setPendingPickups]: any = useState([]);
  const [schedulePickups, setSchedulePickups] = useState([]);
  const [canceledPickups, setCancelPickups] = useState([]);
  const [completedPickups, setCompletedPickups] = useState([]);
  const [loading, setLoading] = useState(true)
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  const [pageNum, setPageNum] = useState(1);
  const [limit, setLimit]: any = useState(5);
  const [totalData, setTotalData] = useState(0)

  const schduleClick = () => {
    history.push("/w/schedule-pickup");
  };

  useEffect(() => {
    setLoading(true)
    const fetchUrl = `v1/users/pickup?limit=${limit}&page=${pageNum}&expand[]=address&expand[]=pickupSlot${status ? `&status=${status}` : ''}`

    ApiRequest("GET", fetchUrl, {
      isAuthenticated: false,
      token: loggedInUser.token,
    }).then(
      (data) => {
        if (data.success) {
          setTotalData(data?.total)
          if (status === 'PENDING') setPendingPickups(data?.data)
          if (status === 'SCHEDULED') setSchedulePickups(data?.data)
          if (status === 'CANCELLED') setCancelPickups(data?.data)
          if (status === 'COMPLETED') setCompletedPickups(data?.data)
        }
      }
    )
      .finally(() => {
        setLoading(false)
      })
  }, [status, pageNum, reload.schedule, loggedInUser.token, limit]);

  return (
    <div className="local_pickup_div">
      <HeaderLocal
        show={{ ul: false, location: false, search: false, profile: false }}
      />
      <div className="container local_container" id="hide_scrollBar">
        <div className="row local_row" id='hide_scrollBar'>
          <div className="col-lg-6 col-md-8 col-sm-12 list_pickup_view">
            <div className="pickUp_view_local">
              <p className="pickups_title hidden">
                <span
                  onClick={() => setViewNow("upcoming")}
                  className={viewNow === "upcoming" ? "active" : ""}
                >
                  Upcoming Pickups
                </span>
                <span
                  onClick={() => setViewNow("complete")}
                  className={viewNow === "complete" ? "active" : ""}
                >
                  Complete Pickups
                </span>
              </p>

              <Accordion className="text-center">
                {viewNow === "upcoming" ? (
                  <>
                    <IonGrid>
                      <IonRow>
                        <IonCol className="pending_status local_pending_status">
                          {/* <IonChip
                            color="primary"
                            className={
                              status === "PENDING" ? "activePending" : "pending"
                            }
                            onClick={(i) => setStatus("PENDING")}
                          >
                            <span>Upcoming</span>
                          </IonChip> */}

                          <IonChip
                            color="success"
                            className={
                              status === "SCHEDULED"
                                ? "activeSchedule middle"
                                : "schedule middle"
                            }
                            onClick={(i) => { setStatus("SCHEDULED"); setPageNum(1) }}
                          >
                            <span> Scheduled</span>
                          </IonChip>
                          <IonChip
                            color="danger"
                            className={
                              status === "CANCELLED"
                                ? "activeCanceled"
                                : "cancel"
                            }
                            onClick={(i) => { setStatus("CANCELLED"); setPageNum(1) }}
                          >
                            <span>Cancelled</span>
                          </IonChip>
                          <IonChip
                            color="warning"
                            className={`${status === "COMPLETED"
                              ? "activeCompleted"
                              : "cancel"} completed_status`}
                            onClick={(i) => { setStatus("COMPLETED"); setPageNum(1) }}
                          >
                            <span>Completed</span>
                          </IonChip>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <ul className="pickups_list_ul style-2">
                      {status === "PENDING" ? (
                        loading ? (
                          <Skeleton />
                        ) :
                          pendingPickups.length ?
                            pendingPickups.map((item: any, i: number) => (
                              <Schedule key={i} item={item} i={i} />
                            ))
                            : (
                              <p className="text-center mt-2">
                                Nothing Pending Pickups!
                              </p>
                            )
                      ) : null}
                      {status === "SCHEDULED" ? (
                        loading ? (
                          <Skeleton />
                        ) :
                          schedulePickups.length ?
                            schedulePickups.map((item: any, i: number) => (
                              <Schedule status={status} key={i} item={item} i={i} />
                            ))
                            : (
                              <p className="text-center mt-2">
                                Nothing Scheduled Pickups!
                              </p>
                            )
                      ) : null}
                      {status === "CANCELLED" ? (
                        loading ? (
                          <Skeleton />
                        ) :
                          canceledPickups.length ?
                            canceledPickups.map((item: any, i: number) => (
                              <Schedule status={status} key={i} item={item} i={i} />
                            ))
                            : (
                              <p className="text-center mt-2">
                                There is no Cancelled Pickup at this moment
                              </p>
                            )
                      ) : null}
                      {status === "COMPLETED" ? (
                        loading ? (
                          <Skeleton />
                        ) :
                          completedPickups.length ?
                            completedPickups.map((item: any, i: number) => (
                              <Schedule status={status} key={i} item={item} i={i} />
                            ))
                            : (
                              <p className="text-center mt-2">
                                No Completed Pickups!
                              </p>
                            )
                      ) : null}
                    </ul>
                  </>
                ) : (
                  <ul className="pickups_list_ul style-2">
                    sf
                  </ul>
                )}
                <hr className="schedule_pickup_hr" />
                <div className="flex items-center justify-between pagination_show_items">
                  {
                    totalData ?
                      <PaginationCom onChange={(page) => setPageNum(page)} totalPage={Math.ceil(totalData / limit)} />
                      : null
                  }
                  <div className="flex items-center gap-2">
                    <span className="show_pickup_title">Show Pickups Item : </span>
                      <select id='fetch_limit_select' className="fetch_limit" value={limit} onChange={(e) => { setLimit(e.target.value); setPageNum(1) }}>
                        <option value={5}>05 items</option>
                        <option value={10}>10 items</option>
                        <option value={15}>15 items</option>
                        <option value={25}>25 items</option>
                        <option value={50}>50 items</option>
                      </select>
                    <div>
                    </div>
                  </div>
                </div>

                <IonButton
                  type="button"
                  className="schedule_pickup_button_local"
                  onClick={schduleClick}
                >
                  Schedule Another Pickup
                </IonButton>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalPickupView;

const Schedule = ({ status, item, i }: any) => {
  const [dropShow, setDropShow] = useState(false);


  return (
    <li className={`pickup_li `} onClick={() => setDropShow(prev => !prev)}>
      <div className="accordion_btn">
        <div className="main_view">
          <div className="flex justify-start flex-col  gap-2">
            <p className="pickup_details_title">
              Pickup Id
            </p>
            <p className='pickup_details_result'>
              {item?.id}
            </p>
          </div>
          <div className="flex flex-col justify-start  gap-2">
            <p className="pickup_details_title">
              Pickup Time
            </p>
            <p className='pickup_details_result'>
              {item?.pickupSlot?.from_time} - {item?.pickupSlot?.to_time}
            </p>
          </div>
          <div className="flex flex-col justify-start  gap-2">
            <p className="pickup_details_title">
              Expected Weight
            </p>
            <p className='pickup_details_result'>
              {item?.expected_weight}
            </p>
          </div>
          <div className="flex flex-col justify-start  gap-2">
            <p className="pickup_details_title">
              Pickup Date
            </p>
            <p className='pickup_details_result'>
              {item?.pickup_date}
            </p>
          </div>
          <div className="flex justify-start flex-col  gap-2">
            <p className="pickup_details_title">
              Pickup Created Time
            </p>
            <p className='pickup_details_result'>
              {dateShow(item?.created_at * 1000)}

              <p >
                {
                  `
              ${new Date(item?.created_at * 1000).getHours()}:${new Date(item?.created_at * 1000).getMinutes()} ${new Date(item?.created_at * 1000).getHours() > 12 ? 'PM' : 'AM'}`
                }
              </p>
            </p>
          </div>
          <div className="flex flex-col justify-start  gap-2">
            <p className="pickup_details_title status">
              {
                status === 'COMPLETED' ? 'Completed' :
                  status === 'SCHEDULED' ? 'Scheduled' :
                    'Cancelled'
              } Date
            </p>
            <p className='pickup_details_result'>
              {dateShow(item?.updated_at * 1000)}

            </p>
            <p style={{ fontSize: "0.8rem" }}>
              {
                `
              ${new Date(item?.updated_at * 1000).getHours()}:${new Date(item?.updated_at * 1000).getMinutes()} ${(new Date(item?.updated_at * 1000).getHours()) > 12 ? 'PM' : 'AM'}`
              }
            </p>
          </div>



          <p
            className={`status_pickup 
          ${item?.status === 'SCHEDULED' ? 'schedule' : ''} 
          ${item?.status === 'CANCELLED' ? 'cancelled' : ''}
          ${item?.status === 'COMPLETED' ? 'completed' : ''}`}
          >
            {item?.status}
          </p>

          <IonIcon style={dropShow ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} className="down_arrow" icon={chevronDownOutline}></IonIcon>
        </div>
      </div>

      <br />

      <div className={`details_view view ${dropShow ? 'active' : ''}`}>
        <div className="details_d_flex ">
          <div className="details_view_ans address">
            <p className="details_view_ques">
              Address:
            </p>
            <div>
              <p className="">
                {`${item?.address?.line1 ? `${item?.address?.line1}, ` : ''}` +
                  `${item?.address?.landmark ? `${item?.address?.landmark}, ` : ''}` +
                  `${item?.address?.city ? `${item?.address?.city}` : ''}` +
                  `${item?.address?.state ? `, ${item?.address?.state}` : ''}` +
                  `${item?.address?.pincode ? `, ${item?.address?.pincode}` : ''}`
                }
              </p>
            </div>
          </div>
        </div>
        <p className="cancel_call_p">
          For rescheduling or cancellation please call us at <a href={`tel:+91${Config.CONTACT.SUPPORT_PHONE}`}>+91{Config.CONTACT.SUPPORT_PHONE}</a>
        </p>
      </div>
    </li>
  );
};
