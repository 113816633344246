/**
 *  User Events Function.....
 */
const windowFcWidget = (event: any) => {
  window.fcWidget &&
    window.fcWidget.track(`${event}`, {
      //Key and value can be anything relatable to your web app.
      cartValue: 5000,
      lastItemCategory: "electronics"
    });
};

export const userTabClick = (event: any) => {
  //After Page and Widget is Loaded.
  //Check if widget is loaded using the isLoaded Condition
  //Event name add_to_cart can also be set to your preference.
  windowFcWidget(event);
};

export const schedulePickUpSuccessuflly = (event: any) => {
  windowFcWidget(event);
};
export const userEditProfile = (event: any) => {
  windowFcWidget(event);
};
export const userIsNow = (event: any) => {
  windowFcWidget(event);
};

export const dateShow = (val: number) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const getMonthName = monthNames[new Date(val).getMonth()];
  const getYear = new Date(val).getFullYear();
  const getDate = new Date(val).getDate();
  return getDate + " " + getMonthName + " " + getYear;
};
