import { IonButton, IonIcon, IonSpinner, IonToast } from "@ionic/react";
import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router";
import "./LocalLogin.css";
import ApiRequest from "Services/Api.Service";
import useLocalStorage from "Services/useLocalStorage";
import { UserProfile } from "App";
import { chevronBackOutline } from "ionicons/icons";
import { useMutation } from "react-query";
import { userProfileStore } from "Store/createStore";
import FirstLoginView from "Components/Micro/FirstLogin";
import { TrackEvent } from "Services/TEvents.Service";
import Config from "Config";

type Inputs = {
  example: string;
  exampleRequired: string;
  firstName: string;
  lastName: string;
  email: string;
  isDeveloper: boolean;
};

interface Container {
  loginShow: any;
  scheduleErr: any;
  setScheduleErr: any
}

const LocalLogin: React.FC<Container> = (props: any) => {
  const { scheduleErr, setScheduleErr } = props;
  const [loginShow, setLoginShow] = props.loginShow;
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>("LoginUser", {});
  const [stateOtp, setStateOtp] = useState({ otp: "" });
  const [loadingSpin, setLoadingSping] = useState(false);
  const { register, handleSubmit }: any = useForm<Inputs>();
  const history = useHistory();
  const [error, setError] = useState<any>("");
  const [phoneNumberLength, setPhoneNumberLength] = useState<any>('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [resendCodeStop, setResendCodeStop] = useState(0);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [timeOut, setTimeOut] = useState(false);
  const [inValidOtp, setInValidOtp] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [oldUserContent, setOldUserContent] = useState<any>({});
  const [appMeta, setAppMeta] = useState<any>({});
  const { setUserProfile } = userProfileStore()

  const postData = (data: any) => {
    return ApiRequest("POST", data.path, { body: data.body })
  }

  let phoneNumberMutation = useMutation(postData)


  //Login FOrm SUbmit
  const onPhoneNumSubmit = (data: any) => {
    if (isValid(phoneNumberLength)) {
      const phoneNumber = data.phoneNumber;
      const phone_num_obj = { phone: phoneNumber };
      phoneNumberMutation.mutate({ path: "v1/users/auth/init", body: phone_num_obj }, {
        onSuccess: (data) => {
          if (data.success) {
            setLoadingSping(false);
            setLoginShow("otp");
            setPhoneNumber(phoneNumber);
          } else {
            setError(data?.data?.message);
            setLoadingSping(false);
          }
        },
        onError: (err: any) => {
          setError(err.message);
        }
      })
    };
  }

  function isValid(string: string) {
    let numberLng = string.length >= 10
    if (!numberLng) {
      setError("Number should be 10 digit!");
      return false
    }
    return true;
  }
  const numberLengthSet = (e: any) => {
    setError("");
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setPhoneNumberLength(onlyDigits);
  };


  //OTP VALUE CHECK
  const handleChange = (otp: any) => {
    setStateOtp({ otp: otp });
    setInValidOtp(false);
  };
  // Resend code Auth
  const resendCode = () => {
    setMinutes(1);
    setResendCodeStop(resendCodeStop + 1);
    setTimeOut(false);
    const phoneOtp = { phone: phoneNumber };

    ApiRequest("POST", "v1/users/auth/init", { body: phoneOtp }).then((data) => { }
    );
  };

  useEffect(() => {
    ApiRequest("GET", "v1/meta").then((data: any) => {
      setAppMeta(data.data);
    });
  }, []);

  useEffect(() => {
    if (loginShow === "otp") {
      let myInterval = setInterval(() => {
        if (seconds === 1 && minutes === 0) {
          setTimeOut(true);
        }
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }

  });

  //OTP SUBMITED VALUE
  const onSubmitOtp = () => {
    const codes = stateOtp.otp;
    const phoneCode = { phone: phoneNumber, code: codes };
    if (stateOtp.otp.length === 4) {
      TrackEvent("otp_entered")
      phoneNumberMutation.mutate({ path: "v1/users/auth/verify", body: phoneCode }, {
        onSuccess: (data) => {
          if (data.success === true) {
            let { user, token, expiry, first_login } = data?.data;
            TrackEvent('Session_started', {phone: user.phone})

            if(user.email) window?.dataLayer.push({user_email:  user.email})
            if(user.name) window?.dataLayer.push({user_name:  user.name})
            if(user.pincode) window?.dataLayer.push({pincode:  user.pincode})

            setLoggedInUser({ ...loggedInUser, ...user, expiry, token, first_login });
            setProfile({ ...profile, ...user });
            setShowToast1(true);
            setInValidOtp(false);
            setOldUserContent({ ...user, token, expiry, first_login })
            setUserProfile(user)
            if (first_login) setLoginShow("update_profile");
            if (!first_login) history.push("/w/schedule-pickup");
          } else {
            setInValidOtp(true);
          }
        },
        onError: (err: any) => {
          setError(err.message);
        }
      })

    } else {
      setInValidOtp(true);
    }
  };

  //view pickup function
  const viewPickUp = (name: string) => {
    if (name === "viewPickup") {
      history.push("/w/pickup-view");
    } else if (name === "schedulePickup") {
      history.push("/w/schedule-pickup");
    }
  };

  useEffect(() => {
    if (loggedInUser.token || loggedInUser.name) {
      ApiRequest("GET", "v1/users/profile", {
        isAuthenticated: false,
        token: loggedInUser.token,
      }).then((res: any) => {

        setUserProfile(res.data)
        // setLoginShow("first_login");
        // setOldUserContent(data.data);
      });
    }
  }, [loggedInUser.token]);

  const backToPhone = () => {
    setLoginShow("phone")
    setPhoneNumberLength('')
  }

  return (
    <div
      className={
        loginShow === "first_login"
          ? "local_login_form first_login_form"
          : "local_login_form"
      }
    >
      {/* Phone Number Login */}
      {loginShow === "phone" && (
        <div>
          <form onSubmit={handleSubmit(onPhoneNumSubmit)}>
            <h4 className="local_login_title">Schedule a Pickup</h4>
            <p className="local_mobile_text">Mobile</p>
            <div className={`local_input_box ${scheduleErr?.status ? 'border-r' : ''}`}>
              <span style={{ width: '2rem' }}>+91</span>
              <input
                {...register('phoneNumber')}
                type="tel"
                maxLength={10}
                value={phoneNumberLength}
                onChange={(e) => { numberLengthSet(e); setScheduleErr({ status: false, msg: '' }) }}
                className={`sign_header_input `}
                name="phoneNumber"
                id="phoneNumber"
                autoComplete="new-password"
              />

            </div>

            <p className="error_para">{error || (scheduleErr?.status && scheduleErr?.msg)} </p>
            <IonButton type="submit" className="local_next_button">
              {phoneNumberMutation.isLoading ? <IonSpinner name="lines" /> : " NEXT "}
            </IonButton>
            <SupportNumber />
          </form>
        </div>
      )}
      {/* OTP Varification */}
      {loginShow === "otp" && (
        <div>
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message="Login Successfully"
            duration={700}
          />
          <form onSubmit={handleSubmit(onSubmitOtp)}>
            <p onClick={backToPhone} className="back">
              <IonIcon
                className="back"
                icon={chevronBackOutline}
              />
            </p>
            <h4 className="local_login_title">

              <span>Enter OTP</span>
            </h4>
            <OtpInput
              containerStyle="otpInput_main_div"
              value={stateOtp.otp}
              onChange={handleChange}
              inputStyle="input_get_otp"
              numInputs={4}
              isInputNum={true}
              shouldAutoFocus={true}
            />

            {inValidOtp && (
              <p className="sign_resend_code invalid">Invalid OTP Entered.</p>
            )}
            {resendCodeStop === 3 ? (
              <p className="check_inbox">{/* Check your Inbox! */}</p>
            ) : timeOut ? (
              <p onClick={resendCode} className="receive_otp">
                Didn’t receive OTP? <span> Resend</span>
              </p>
            ) : (
              <>
                <p className="receive_otp">
                  No code? Resend after
                  {minutes === 0 && seconds === 0 ? null : (
                    <>
                      {" "}
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </>
                  )}
                </p>
                <p>
                  <i>OR</i>
                  <a style={{ fontWeight: 'bold', marginLeft: '8px' }} href={`https://scrapuncle.authlink.me/?redirectUri=https://${window.location.host}/callback`}>
                    Login with Whatsapp
                  </a>
                </p>
                <p className="help_text">
                  Facing Problems? Call us at{" "}
                  <a href={`tel:+91${Config.CONTACT.SUPPORT_PHONE}`}> +91{Config.CONTACT.SUPPORT_PHONE}</a>
                </p>
              </>
            )}
            <IonButton type="submit" className="local_next_button">
              {" "}
              {loadingSpin ? <IonSpinner name="lines" /> : " NEXT "}
            </IonButton>
          </form>
        </div>
      )}
      {/* First Login View */}
      {loginShow === "update_profile" && (
        <FirstLoginView
          user={loggedInUser}
          onSuccess={() => {
            setLoginShow("first_login");
          }}
        />
      )}
      {loginShow === "first_login" && (
        <div className="old_user_div">
          <h4 className="local_login_title">
            Nice to see you
            {
              profile?.name || loggedInUser?.name ? `, ${(profile && profile.name) || (loggedInUser && loggedInUser.name)}!` : ''
            }

          </h4>
          <p className="receive_otp mt-3">Impact made by Scrapuncle</p>
          <div className="old_user_img">
            <div className="first_login_kg">
              <span className="lcd">
                {appMeta?.impact?.recycled || "..."}
              </span>
              <span>Recycled</span>
            </div>
            <div className="first_login_euro hidden">
              {oldUserContent &&
                oldUserContent.impact &&
                oldUserContent.impact.earned || appMeta?.impact?.earned}
              <span>Earned</span>
            </div>
            <img
              src="https://ikp.edgekit.net/y8s2vhk66ef/Main_Page_Illustration_e9Jz4TCH361.png?updatedAt=1628127523534"
              alt=""
            />
            <p className="receive_otp">Wish to recycle more? </p>
          </div>
          <div className="button_div">
            <IonButton
              onClick={() => viewPickUp("schedulePickup")}
              className="local_next_button old_user_button pickupBtn"
            >
              Schedule a pickup
            </IonButton>
            <IonButton
              onClick={() => viewPickUp("viewPickup")}
              className="mt-3 local_next_button old_user_button pickupBtn"
            >
              Check my pickups
            </IonButton>
          </div>
          <div className="logout_facing_problems">
            <a
              href="#"
              style={{ fontWeight: 700, fontSize: "15px", textAlign: "center" }}
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              Logout
            </a>
            <p className="help_text">
              Facing Problems? Call us at
              <a href={`tel:+91${Config.CONTACT.SUPPORT_PHONE}`}> +91{Config.CONTACT.SUPPORT_PHONE}</a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocalLogin;



const SupportNumber = () => {
  const [callNum, setCallNum] = useState(`91${Config.CONTACT.SUPPORT_PHONE}`)

  return <p className="help_text  support_number">
    Facing Problems? Call us at{" "}
    <a href={`tel:+${callNum}`}> +{callNum}</a>
  </p>
}