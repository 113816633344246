import React, { useEffect, useState } from "react";
import {
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import PickupComplete from "Components/LocalHome/PickupComplete/PickupComplete";
import LocalPickupView from "Components/LocalHome/LocalPickupView/LocalPickupView";
import LocalSchedule from "Components/LocalHome/LocalSchedule/LocalSchedule";
import LocalRate from "Components/LocalHome/LocalRate/LocalRate";
import LocalHome from "Components/LocalHome/LocalHome";
import PrivateRoute from "Components/PrivateRoute/PrivateRoute";
import Tab1 from "Pages/Tab1";
import Tab2 from "Pages/Tab2";
import Tab3 from "Pages/Tab3";
import Tab4 from "Pages/Tab4";
import Tab5 from "Pages/Tab5";
import UpcomingPickupDetailsWeb from "Components/UpcomingPickupDetails/UpcomingPickupDetails2/UpcomingPickupDetailsWeb";
import AboutView from "Components/About/AboutView";
import AppSettingView from "Components/AppSettings/AppSettingView";
import SaveAddressView from "Components/SaveAddress/SaveAddressView";
import SupportView from "Components/Support/SupportView";
import ReferFriendView from "Components/ReferFriend/ReferFriendView";
import ConfirmationPickupWeb from "Components/ConfirmationPickup/ConfirmationPickupWeb";
import MapAddressWeb from "Components/MapAddress/MapAddressWeb";
import LoginPage from "LoginForm/LoginPage/LoginPage";
import SignUpOTP from "LoginForm/SignUPOTP/SignUpOTP";
import SignUpOTPVerification from "LoginForm/SignUPOTP/SignUpOTPVerification";
import SignUpDetails from "LoginForm/SignUPOTP/SignUpDetails";
import BussinessDetails from "LoginForm/SignUPOTP/BussinessDetails";
import EditProfileView from "Components/EditProfile/EditProfileView";
import SearchAddress from "Components/SearchAddress/SearchAddress";
import CartItems from "Components/UpcomingPickupDetails/CartItems";
import EditCardView from "Components/EditCard/EditCardView";
// import { userTabClick } from "Events";

const AppMode = () => {
  const [activeTabShow, setActiveTabShow] = useState<any>("");
  const fontColor: any = {
    color: "#3ab34a",
    fontWeight: "500",
  };

  useEffect(() => {
    const currentPage = window.location.pathname;
    if (currentPage === "/") {
      setActiveTabShow("/home");
    } else {
      setActiveTabShow(currentPage);
    }
  }, []);

  const tabClick = (event: any) => {
    setActiveTabShow(event);
  };
  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <PrivateRoute path="/" exact component={Tab1} />
          <PrivateRoute path="/home" component={Tab1} />
          <PrivateRoute path="/rate-list" component={Tab2} />
          <PrivateRoute path="/additem" component={Tab2} />
          <PrivateRoute path="/tab3" component={Tab3} />
          <PrivateRoute path="/pickup" component={Tab4} />
          <PrivateRoute path="/profile" component={Tab5} />
          <PrivateRoute
            path="/viewPickUp"
            component={UpcomingPickupDetailsWeb}
          />
          <PrivateRoute
            path="/pickup/:id"
            component={UpcomingPickupDetailsWeb}
          />
          <PrivateRoute path="/about" component={AboutView} />
          <PrivateRoute path="/appsettings" component={AppSettingView} />
          <PrivateRoute path="/saveaddresses" component={SaveAddressView} />
          <PrivateRoute path="/support" component={SupportView} />
          <PrivateRoute path="/referfriend" component={ReferFriendView} />

          <PrivateRoute
            path="/confirmationpickup"
            component={ConfirmationPickupWeb}
          />
          <PrivateRoute path="/schedulefill" component={Tab3} />
        </IonRouterOutlet>
        <IonTabBar className="IonTabBar" slot="bottom">
          <IonTabButton tab="home" href="/home">
            <div className="flex_column" onClick={(e) => tabClick("/home")}>
              {activeTabShow === "/home" ? (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Vector_0RYNC8NHed.png"
                  alt=""
                />
              ) : (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/house_klNGaZBRt.png"
                  alt=""
                />
              )}
              <IonLabel style={activeTabShow === "/home" ? fontColor : null}>
                Home
              </IonLabel>
            </div>
          </IonTabButton>
          <IonTabButton tab="additem" href="/additem">
            <div className="flex_column" onClick={(e) => tabClick("/additem")}>
              {activeTabShow === "/additem" ? (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/color_calender_VMzcWSdT_.png"
                  alt=""
                />
              ) : (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Vector_1__hDDn_bTZr.png"
                  alt=""
                />
              )}
              <IonLabel style={activeTabShow === "/additem" ? fontColor : null}>
                Rates
              </IonLabel>
            </div>
          </IonTabButton>
          <IonTabButton tab="schedulefill" href="/schedulefill">
            <div
              className="flex_column"
              onClick={(e) => tabClick("/schedulefill")}
            >
              {activeTabShow === "/schedulefill" ? (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Unioncolor_qk1_kcTwj.png"
                  alt=""
                />
              ) : (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Union_1___wnHvrWAC.png"
                  alt=""
                />
              )}
              <IonLabel
                style={activeTabShow === "/schedulefill" ? fontColor : null}
              >
                Schedule
              </IonLabel>
            </div>
          </IonTabButton>
          <IonTabButton tab="pickup" href="/pickup">
            <div className="flex_column" onClick={(e) => tabClick("/pickup")}>
              {activeTabShow === "/pickup" ? (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Union1Color_Op6PmF5Qy.png"
                  alt=""
                />
              ) : (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Union_tKJfHLZ3U.png"
                  alt=""
                />
              )}
              <IonLabel style={activeTabShow === "/pickup" ? fontColor : null}>
                Pickups
              </IonLabel>
            </div>
          </IonTabButton>
          colorSet
          <IonTabButton tab="profile" href="/profile">
            <div className="flex_column" onClick={(e) => tabClick("/profile")}>
              {activeTabShow === "/profile" ? (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/profile_Eo_19iiaM.png"
                  alt=""
                />
              ) : (
                <img
                  className="tabBar_image"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Vector_2__CYKb8QMXh.png"
                  alt=""
                />
              )}
              <IonLabel style={activeTabShow === "/profile" ? fontColor : null}>
                Profile
              </IonLabel>
            </div>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>

      <Route path="/login-page" component={LoginPage} />
      <Route path="/auth/init" component={SignUpOTP} />
      <Route path="/auth/verify" component={SignUpOTPVerification} />
      <Route path="/auth/signup" component={SignUpDetails} />
      <Route path="/bussinessDetails" component={BussinessDetails} />
      <PrivateRoute path="/editProfile" component={EditProfileView} />
      <PrivateRoute path="/searchaddress" component={SearchAddress} />
      <PrivateRoute path="/cartitems" component={CartItems} />
      <PrivateRoute path="/editcard" component={EditCardView} />
      <PrivateRoute path="/mapaddress" component={MapAddressWeb} />
      <PrivateRoute path="/about" component={AboutView} />
      <PrivateRoute path="/appsettings" component={AppSettingView} />
      <PrivateRoute path="/saveaddresses" component={SaveAddressView} />
      <PrivateRoute path="/support" component={SupportView} />
      <PrivateRoute path="/referfriend" component={ReferFriendView} />
    </IonReactRouter>
  );
};

export default AppMode;
