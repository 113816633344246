import { IonIcon } from "@ionic/react";
import { bagOutline, homeOutline, locationOutline } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ApiRequest from "../../../Services/Api.Service";
import "./WebHeader.css";
import { IonToast } from "@ionic/react";
import { useMutation } from "react-query";
import GooglePlaceSearch from "./GooglePlaceSearch";
import useLocalStorage from "Services/useLocalStorage";
import GoogleMapAreaSelect from "./GoogleMapAreaSelect";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import './addressSet.css'
import { useHistory } from "react-router";


interface sideBar {
  setSideBarOpen: any;
  setShowItem: any;
  setValue: any;
  setAllowedToFetchSchedule: any;
  features: any;
  setSelectAddrss?: any
}

const AddressSet: React.FC<sideBar> = (props) => {
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  const [addMore, setAddMore] = useState(true);
  const { register, reset, formState: { errors }, handleSubmit, setValue, clearErrors }: any = useForm();
  const [showToast1, setShowToast1] = useState({ status: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [openGoogleMap, setOpenGoogleMap]: any = useState(false);
  const [selectAddress, setSelectAddress]: any = useState({});
  const [title, setTitle] = useState('');
  const [otherShow, setOtherShow] = useState(false);
  const [resetPin, setResetPin] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const postData = ({ path, body, token }: any) => {
    return ApiRequest("POST", path, { body: body, isAuthenticated: false, token })
  };

  let newAddressCreate = useMutation(postData)

  const addMoreClick = () => {
    setAddMore(true);
  };

  const createNewAddress = (formData: NewAddressFormPayload) => {
    let data;
    window.dataLayer?.push({
      event: 'click_new_address',
      new_address_full: formData?.line1,
      new_address_landmark: formData?.landmark,
      new_address_pincode: formData?.pincode,
    })
    if (formData.hasOwnProperty('address')) {
      data = {
        ...formData,
        ...selectAddress,
        location: {
          lat: selectAddress?.location?.lat || '', lng: selectAddress?.location?.lng || ''
        }
      }
    } else {
      data = { ...formData }
    }
    delete data.address;
    data.locality = 'Unknown' // Locality is Removed
    setLoading(true)
    newAddressCreate.mutate({ path: "v1/users/address", body: data, token: loggedInUser?.token }, {
      onSuccess: (res) => {
        if (res.success) {
          setShowToast1({ status: true, message: 'Add Address Succesfully.' });
          props.setAllowedToFetchSchedule(true)
          props.setSideBarOpen(false);
          resetallFeild()
          props.hasOwnProperty('setSelectAddrss') && props.setSelectAddrss(res.data)
        } else {
          setShowToast1({ status: true, message: res?.message });
        }
        setLoading(false)
      },
      onError: (err: any) => {
        setLoading(false)
      }
    })
  };

  useEffect(() => {
    if (JSON.stringify(selectAddress) !== '{}') {
      setValue('address', selectAddress)
      if (selectAddress?.state) {
        setValue('state', selectAddress?.state)
        clearErrors('state')
      }
      if (selectAddress?.pincode) {
        setValue('pincode', selectAddress?.pincode)
        clearErrors('pincode')
      }
    }
  }, [selectAddress])

  const resetallFeild = () => {
    reset({ landmark: "", pincode: "", title: '', state: "", address: "", city: '', line1: '' });
    setTitle('')
    setOtherShow(false)
    setSelectAddress({})
    setResetPin(prev => !prev)
  }


  return (
    <div>
      <IonToast
        isOpen={showToast1.status}
        onDidDismiss={() => setShowToast1({ status: false, message: "" })}
        message={showToast1.message}
        duration={2000}
      />
      {
        openGoogleMap &&
        <GoogleMapAreaSelect
          openGoogleMap={openGoogleMap}
          setOpenGoogleMap={setOpenGoogleMap}
          location={selectAddress?.location}
          setSelectAddress={setSelectAddress}
          selectAddress={selectAddress}
        />
      }

      <form onSubmit={handleSubmit(createNewAddress)}>

        <div className={`address_search_box  main_address_div`}>
          <label htmlFor="landmark" className={` ${errors.line1 ? 'text-red' : ''}`}>Enter Full Address <span style={{ color: 'red' }}>*</span></label>
          <input
            {...register("line1", { required: true })}
            placeholder='House no., building, apartment'
            name="line1"
            type="text"
            className="searchBox_input"
            id="line1"
            autoComplete="new-password"
          />
        </div>
        {/* <div className={`current_location search hidden`}>
          <label className={`sidebar_location_label ${errors.address ? 'text-red' : ''} `} htmlFor="address">
            <span> Locality <span style={{ color: 'red' }}>*</span></span>
          </label>
          <div className="w-full ">
            <div className="input_">
              <GooglePlaceSearch
                setLocalityValue={setValue}
                resetPin={resetPin}
                features={props.features}
                setSelectAddress={setSelectAddress}
                setOpenGoogleMap={setOpenGoogleMap}
                gotValue={selectAddress?.locality}
                clearErrors={clearErrors}
                register={register}
                required={true}
                name={'address'}
              />
            </div>
          </div>
        </div> */}

        {/* <div className={`address_search_box `}>
          <label htmlFor="state" className={errors.state ? "text-red " : ''}> State <span style={{ color: 'red' }}>*</span></label>
          <input
            {...register("state", { required: true })}
            placeholder='State'
            name="state"
            type="text"
            className="searchBox_input"
            id="state"
            autoComplete="new-password"
            defaultValue={selectAddress?.state || ''}
          />
        </div> */}

        {
          addMore ? (
            <>
              <div className={`address_search_box landmark_box`}>
                <label className={errors.landmark ? "text-red " : ''} htmlFor="landmark"> Landmark</label>
                <input
                  {...register("landmark", { required: false })}
                  placeholder='E.g. near apollo hospital'
                  name="landmark"
                  type="text"
                  className="searchBox_input"
                  id="landmark"
                  autoComplete="new-password"
                />
              </div>
              <div className={`address_search_box pincode_box`}>
                <label className={errors.pincode ? "text-red " : ''} htmlFor="pincode"> Pincode <span style={{ color: 'red' }}>*</span></label>
                <InputFeild
                  setValue={setValue}
                  name="pincode"
                  type="number"
                  customClass="searchBox_input"
                  maxLength={6}
                  placeholder='6 digits [0-9] PIN code'
                  register={register}
                  required={true}
                  clearErrors={clearErrors}
                  defaultValue={selectAddress?.pincode}
                  resetPin={resetPin}
                />
              </div >
              <TitleOptions
                otherShow={otherShow}
                setOtherShow={setOtherShow}
                title={title}
                setTitle={setTitle}
                errors={errors}
                setValue={setValue}
                register={register}
                clearErrors={clearErrors}
              />
              <p className="ignored_label"> Fields marked with <span style={{ color: 'red' }}>*</span> can’t be ignored</p>

              <button
                type={loading ? 'button' : "submit"}
                className="current_location skip_btn save_address_button">
                <p className="save_address_p">
                  {
                    loading ? 'Loading...' :
                      'SAVE ADDRESS & PROCEED'
                  }

                </p>
              </button>
            </>
          ) : (
            <>
              <div
                onClick={addMoreClick}
                className="current_location add_more_details"
              >
                <p>ADD MORE DETAILS</p>
                <small>for faster checkout</small>
              </div>
              {/* <div onClick={skipAddLater} className="current_location skip_btn">
              <p>SKIP & ADD LATER</p>
              <small>to quickly see retaurants</small>
            </div> */}
            </>
          )
        }
      </form>
    </div >
  );
};

export default AddressSet;

const InputFeild = ({ setValue, resetPin, clearErrors, type = 'text', required = false, name = '', placeholder = '', defaultValue = '', maxLength = 500, customClass = '', register }: any) => {
  const [val, setVal] = useState('')

  const onChange = (e: any) => {
    if (type === 'number') {
      if (maxLength + 1 > e.target.value.length) {
        setVal(e.target.value.replace(/[^0-9]/g, ""))
        setValue('pincode', e.target.value)
        clearErrors('pincode')
      }
    } else {
      setVal(e.target.value)
    }
  }

  useEffect(() => {
    register(name, { required: true })
    if (defaultValue) {
      setVal(defaultValue)
    } else {
      setVal('')
    }
  }, [defaultValue, resetPin])

  return (
    <input
      {...register(name, { required })}
      type={type}
      name={name}
      placeholder={placeholder}
      maxLength={maxLength}
      className={'searchBox_input ' + customClass}
      value={val || ''}
      onChange={(e) => onChange(e)}
      autoComplete="new-password"
    />
  )
}

const TitleOptions = ({ otherShow, setOtherShow, title, setTitle, clearErrors, errors, setValue, register }: any) => {

  const titleClick = (titleName: any) => {
    if (titleName === "other") {
      setOtherShow(true);

    } else {
      setValue('title', titleName);
      setTitle(titleName)
    }
  };

  const onOtherInputChange = (value: any) => {
    clearErrors('title')
    setValue('title', value);
  }


  return (
    <>{
      otherShow ? (
        <div className="Other_click">
          <div
            onClick={() => titleClick("first_other")}
            className="first_other "
          >
            <IonIcon icon={locationOutline} />
            <span>Other</span>
          </div>
          <div className="second_other">
            <input
              onChange={(e: any) => onOtherInputChange(e.target.value)}
              autoFocus={true}
              placeholder="Dad's home, my man cave"
              type="text"
              id=""
            />
          </div>
          <div className="third_other">
            <p
              onClick={() => setOtherShow(false)}
              className="cancel_color"
            >
              Cancel
            </p>
          </div>
        </div>
      ) : (
        <div className={`title_set`}>
          <div
            onClick={() => titleClick("home")}
            className={
              title === "home"
                ? "first_title active_category"
                : "first_title "
            }
          >
            <IonIcon className={!title ? errors?.title ? 'text-red' : '' : ''} icon={homeOutline} />
            <span className={!title ? errors?.title ? 'text-red' : '' : ''}> Home</span>
          </div>
          <div
            onClick={() => titleClick("work")}
            className={
              title === "work"
                ? "second_title active_category"
                : "second_title "
            }
          >
            <IonIcon className={!title ? errors?.title ? 'text-red' : '' : ''} icon={bagOutline} />
            <span className={!title ? errors?.title ? 'text-red' : '' : ''}> Work</span>
          </div>
          <div
            onClick={() => setOtherShow(true)}
            className={
              title === "other"
                ? "third_title active_category"
                : "third_title "
            }
          >
            <IonIcon className={!title ? errors?.title ? 'text-red' : '' : ''} icon={locationOutline} />
            <span className={!title ? errors?.title ? 'text-red' : '' : ''}>Other</span>
          </div >
        </div >
      )
    }
    </>
  )
}