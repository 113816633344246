import { IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ScheduleFill from "../Components/ScheduleFill/ScheduleFill";
import SideBar from "../Components/WebView/SideBar/SideBar";
import WebHeader from "../Components/WebView/WebHeader/WebHeader";
import "./Tab3.css";
import styled from "styled-components";
import { UseActiveTabShow, UserProfile } from "../App";
import { useLocation } from "react-router";

const DivPadding = styled.div`
  padding-top: 0px;
  padding-left: 13rem;
  padding-right: 0px;
`;
const Div = styled.div`
  padding-top: 0px;
`;
const DivHeight = styled.div`
  overflow: hidden;
  height: 88vh;
  margin-top: 1rem;
`;

const Tab3: React.FC = () => {
  const [profile, setProfile] = useContext<any>(UserProfile);
  const location = useLocation();

  useEffect(() => {
    setProfile({ ...profile, location: location.pathname });
  }, []);
  const [addItems, setAddItems] = useState<any>({});
  const [filterDate, setFilterData] = useState("");
  const [activeTabShow, setActiveTabShow] = useContext<any>(UseActiveTabShow);
  const [sideBarOpen, setSideBarOpen] = useState<any>(false);
  useEffect(() => {
    setActiveTabShow(window.location.pathname);
  }, [window.location.pathname]);
  
  return (
    <IonPage>
      {window.screen.availWidth < 992 ? (
        <div className="mobile-view">
          <ScheduleFill
            sideBarOpen={[sideBarOpen, setSideBarOpen]}
            manuBar={true}
          />
        </div>
      ) : (
        <div className="web-view style-2">
          <>
            <div>
              <WebHeader
                sideBarOpen={[sideBarOpen, setSideBarOpen]}
                setAddItems={setAddItems}
                filterData={[filterDate, setFilterData]}
              />
              <div className="web_view_main_content">
                <SideBar />
                <DivPadding className="web_view_impact_content web_view_top style-2">
                  <DivHeight className="style-2">
                    <ScheduleFill
                      sideBarOpen={[sideBarOpen, setSideBarOpen]}
                      manuBar={false}
                    />
                  </DivHeight>
                </DivPadding>
                <Div className="web_view_upcoming_picksup web_view_top"></Div>
              </div>
            </div>
          </>
        </div>
      )}
    </IonPage>
  );
};

export default Tab3;
