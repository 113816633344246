import { IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ConfirmationPickup from "./ConfirmationPickup";

import "./ConfirmationPickup.css";
import styled from "styled-components";
import { UserProfile } from "../../App";
import { useLocation } from "react-router";
import WebHeader from "Components/WebView/WebHeader/WebHeader";
import SideBar from "Components/WebView/SideBar/SideBar";

const DivPadding = styled.div`
  padding-top: 0px;
  padding-left: 13rem;
  padding-right: 0px;
`;
const Div = styled.div`
  padding-top: 0px;
`;
const DivHeight = styled.div`
  overflow: hidden;
  height: 88vh;
  margin-top: 1rem;
`;

const ConfirmationPickupWeb: React.FC = () => {
  const location = useLocation();
  const [setAddItems] = useState<any>({});
  const [filterDate, setFilterData] = useState("");
  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <IonPage>
      <div className="mobile-view">
        <ConfirmationPickup header={false} />
      </div>

      <div className="web-view style-2">
        <>
          <div>
            <WebHeader
              sideBarOpen={[sideBarOpen, setSideBarOpen]}
              setAddItems={setAddItems}
              filterData={[filterDate, setFilterData]}
            />
            <div className="web_view_main_content">
              <SideBar />
              <DivPadding className="web_view_impact_content web_view_top style-2">
                <DivHeight className="style-2">
                  <ConfirmationPickup header={false} />
                </DivHeight>
              </DivPadding>
              <Div className="web_view_upcoming_picksup web_view_top"></Div>
            </div>
          </div>
        </>
      </div>
    </IonPage>
  );
};

export default ConfirmationPickupWeb;
