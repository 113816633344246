import { IonCol,IonIcon, IonModal, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useState, useEffect } from "react";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";
import dayjs from "dayjs";
import { userTabClick } from "../../events";
import Skeleton from "Components/Skeleton";
import {
  closeOutline,
  location,
  calendar,
  time,
  list,
  layers,
  personCircleOutline,
} from "ionicons/icons";
interface propsToOther {
  setClickPickupToShow: any;
}

const CompletePickup: React.FC<propsToOther> = (props) => {
  const [loggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [completeData, setUpcomingData] = useState<any>({
    status: false,
    fullData: [],
  });

  useEffect(() => {
    userTabClick("completed pickup");
    ApiRequest("GET", "pickup/all?filter=status%3Dcompleted", {
      isAuthenticated: false,
      token: loggedInUser.token,
    }).then((data) =>
      setUpcomingData({ fullData: [...data.data], status: true })
    );
  }, [loggedInUser.token]);
  return (
    <div className="upcoming-pickup style-2">
      <div className="page_three_container">
        <h3 className="text-threePage">Completed Pickups</h3>
        {/* <div>
          <img
            className="threeBar"
            src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_menu_leftpoint_outline_vll6h2Bdn.png"
            alt="threeBar"
          />
          <img
            className="calender"
            src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_calendar_outline_KZgxu_ohk.png"
            alt="calender"
          />
        </div> */}
      </div>
      <IonGrid>
        {completeData.status === false ? (
          <Skeleton />
        ) : JSON.stringify(
            completeData.fullData.filter(
              (item: any) => item.status === "COMPLETED"
            )
          ) !== "[]" ? (
          completeData.fullData
            .filter((item: any) => item.status === "COMPLETED")
            .map((item: any, i: number) => (
              <Schedule
                setClickPickupToShow={props.setClickPickupToShow}
                key={i}
                item={item}
              />
            ))
        ) : (
          <p className="text-center mt-2">'Ops! There is no Completed !'</p>
        )}
        <IonImg
          className="bg_three_page"
          src="https://ikp.edgekit.net/y8s2vhk66ef/Subtract_1__OKoMNtv7qW.png"
          alt="Bg"
        />
      </IonGrid>
    </div>
  );
};

export default CompletePickup;

const Schedule = ({ item, setClickPickupToShow }: any) => {
  const [itemShow, setItemShow] = useState<any>(false);
  const showItem = () => {
    if (window.screen.availWidth > 992) {
      setClickPickupToShow(item);
    } else {
      setItemShow(true);
    }
  };

  return (
    <IonRow >
      <IonCol onClick={showItem}>
        <div className="d-flex page_three_box">
          <div className="date">
            <h3>{dayjs(item.pickup_date).toDate().toString().slice(0, 3)}</h3>

            <b> {item.pickup_date.slice(8, 10)}</b>
          </div>

          <div className="location">
            <p>{item.pickup_time.slice(0, 6)}</p>
            <p>{item.address.line1}</p>
            <span>
              {item.items[0].title},
              {item.items.length > 1 && " +" + item.items.length + " MORE"}
            </span>
          </div>
        </div>
      </IonCol>
      <IonModal
        backdropDismiss={true}
        swipeToClose={true}
        isOpen={itemShow}
        className="pickup_info_modal"
        mode="ios"
        onDidDismiss={() => setItemShow(false)}
      >
        <div className="modal_cover_div">
          <div className="modal_header">
            <h1 className="m-0 font-weight-bold">Pickup Details </h1>
            <div onClick={() => setItemShow(false)}>
              <IonIcon className="closeIcon" icon={closeOutline} />
            </div>
          </div>
          <div className="modal_content">
            <div className="pickup_agent">
              {item.pickup_agent.status === "NOT_ASSIGNED" ? (
                <>
                  <IonIcon
                    className="pickup_agent_profile"
                    icon={personCircleOutline}
                  />
                  {item.pickup_agent.status}
                </>
              ) : (
                <>
                  <img
                    src={item.pickup_agent.details.photo}
                    alt="Pickup Agent"
                  />
                  <div className="pickup_detail">
                    <p className="pickup_agent_name">
                      {item.pickup_agent.details.name}
                    </p>
                    <p className="pickup_agent_mobile">
                      {item.pickup_agent.details.mobile}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="pickup_detail_div">
              <div className="pickup_first_details">
                <p className="details_text m-0 text-muted">Details</p>
                <div className="first_details_text">
                  <p className="first_details_text_invoice m-0">Status</p>
                  <p className=" m-0 text-muted">{item.status}</p>
                </div>
                <div className="first_details_text">
                  <p className="first_details_text_invoice m-0">Invoice</p>
                  <p className=" m-0 text-muted">{item.invoice.status}</p>
                </div>
                <div className="second_details_div">
               
                  <div className="second_details_text">
                    <IonIcon
                      color="danger"
                      className="pickup_details_icon"
                      icon={location}
                    />
                    <p className="text-muted m-0 pl-3">
                      {item.address.line1}
                    </p>
                  </div>
                  <div className="second_details_text">
                    <IonIcon
                      color="tertiary"
                      className="pickup_details_icon"
                      icon={calendar}
                    />
                    <p className="text-muted m-0 pl-3">
                      {dayjs(item.pickup_date)
                        .toDate()
                        .toString()
                        .slice(0, 16)}
                    </p>
                  </div>
                  <div className="second_details_text">
                    <IonIcon
                      color="warning"
                      className="pickup_details_icon"
                      icon={time}
                    />
                    <p className="text-muted m-0 pl-3">
                      {item.pickup_time.slice(0, 16)}
                    </p>
                  </div>
                  {item.weight && (
                    <div className="second_details_text">
                      <IonIcon
                        color="secondary"
                        className="pickup_details_icon"
                        icon={layers}
                      />
                      <p className="text-muted m-0 pl-3">{item.weight}</p>
                    </div>
                  )}
                  <div className="second_details_text">
                    <IonIcon
                      color="tint"
                      className="pickup_details_icon"
                      icon={list}
                    />
                    <p className="text-muted m-0 pl-3">
                      {item && item.items[0].title}
                      {item.items.length > 1
                        ? ", +" + item.items.length + " MORE"
                        : ","}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonModal>
    </IonRow>
  );
};
