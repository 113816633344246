import React, { useState, useEffect, useRef } from "react";
import HeaderLocal from "Components/Header/HeaderLocal";
import "react-datepicker/dist/react-datepicker.css";
import useLocalStorage from "../../../Services/useLocalStorage";
import "./LocalSchedule.css";
import { IonButton, IonIcon, IonSpinner, IonToast } from "@ionic/react";
import {
  helpCircle,
  location,
  arrowBackOutline
} from "ionicons/icons";
import { Accordion } from "react-bootstrap";
import ApiRequest from "../../../Services/Api.Service";
import { useHistory } from "react-router";
import LocationSidebar from "Components/WebView/WebHeader/LocationSidebar";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import CalenderSchedule from '../../../Components/Micro/CalenderSchedule'
import TimeSchedule from '../../../Components/Micro/TimeSchedule'
import EstimatedWeight from '../../../Components/Micro/EstimatedWeight'
import RemarkOptions from '../../../Components/Micro/RemarkOptions'
import AddressSchedule from '../../../Components/Micro/AddressSchedule'
import "react-calendar/dist/Calendar.css";

import { appMetaStore, userLocationStore } from "../../../Store/createStore";

const LocalSchedule: React.FC = () => {
  const locationRef = useRef<any>(null);
  const history = useHistory();
  const getLocation = async () => { }; // Location Disabled
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");

  const { register, formState: { errors }, handleSubmit, setValue, clearErrors, resetField }: any = useForm();
  const [LoadingSpinner, setLoadingSpinner] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const [showItem, setShowItem] = useState<any>("map");
  const [position] = useState();

  const [selectObject] = useState<any>({});

  const [allowedToFetchSchedule, setAllowedToFetchSchedule] = useState(false)

  const [showToast1, setShowToast1] = useState({ status: false, message: "" });
  const [formReset, setFormReset] = useState(false);
  const [allowToFetchMeta, setAllowToFetchMeta] = useState(false)
  const [pickupSlots, setPickupSlots]: any = useState([])
  const [weightRange, setWeightRange]: any = useState([])
  const [pickupScreen, setPickupScreen]: any = useState({})
  const [availableDates, setAvailableDates]: any = useState([])
  const [features, setFeatures]: any = useState({});
  const [otpVerifyScreen, setOtpVerifyScreen]: any = useState({})
  const [totalPickupSlots, setTotalPickupSlots]: any = useState([])
  const [selectDate, setSelectDate] = useState('')
  const [selectSlot, setSelectSlot]: any = useState({ id: null, title: '' });
  const [selectAddress, setSelectAddress]: any = useState({})
  const { updateAppMeta } = appMetaStore()

  //Get All Meta Data 
  const getData = async ({ queryKey: [name, path] }: any) => {
    const data = await ApiRequest("GET", path, {
      isAuthenticated: false,
      token: loggedInUser?.token,
      source: "web",
    })
    return data;
  }
  let { data } = useQuery(['metaData', 'v1/meta'], getData, { enabled: allowToFetchMeta })


  useEffect(() => {
    if (data) {

      console.log('MetaData', data.data)
      updateAppMeta(data.data)

      if (data?.success) {
        setTotalPickupSlots([...data?.data?.pickupSlots])
        setWeightRange([...data?.data?.weightRanges])
        setPickupScreen({ ...data?.data?.pickupScreen })
        setAvailableDates([...data?.data?.availableDates])
        setFeatures({ ...data?.data?.features })
        setOtpVerifyScreen({ ...data?.data?.otpVerifyScreen?.notice1 })
      }
      setAllowToFetchMeta(false)
    }
  }, [data]);



  useEffect(() => {
    if (loggedInUser.token || loggedInUser.name) {
      setAllowToFetchMeta(true)
    }
  }, [loggedInUser.token]);


  const postData = ({ path, body, token }: any) => {
    return ApiRequest("POST", path, { body: body, isAuthenticated: false, token })
  }

  let newAddressCreate = useMutation(postData)
  const createSchedulePickup = (data: any) => {

    window?.dataLayer.push({
      event:'click_schedule',
      pickup_weight: data?.expected_weight,
      pickup_date: data?.pickup_date,
    })

    setLoadingSpinner(true);
    newAddressCreate.mutate({ path: "v1/users/pickup", body: data, token: loggedInUser?.token }, {
      onSuccess: (res) => {
        
        if (res.success) {
          let toastMessage = res.message
          setShowToast1({ status: true, message: toastMessage ?? 'Pickup is scheduled successfully.' })
          setFormReset(true)
          history.push('/local-pickup-complete')
        } else {
          let toastMessage = res.message
          setShowToast1({ status: true, message: toastMessage ?? 'Error Creating Pickup request. Contact Support' })
        } 
        setLoadingSpinner(false)
      },
      onError: (err: any) => {
        setLoadingSpinner(false)
      }
    })
  };


  return (
    <>
      <HeaderLocal
        show={{ ul: true, location: false, search: false, profile: false }}
      />
      <IonToast
        isOpen={showToast1.status}
        onDidDismiss={() => setShowToast1({ status: false, message: "" })}
        message={showToast1.message}
        duration={3000}
        position='bottom'
      />
      <div className="local_schdule_div style-2">
        <LocationSidebar
          setSelectAddress={setSelectAddress}
          features={features}
          getLocation={getLocation}
          position={position}
          showItem={[showItem, setShowItem]}
          locationRef={locationRef}
          sideBarOpen={[sideBarOpen, setSideBarOpen]}
          setAllowedToFetchSchedule={setAllowedToFetchSchedule}
        />
        <form onSubmit={handleSubmit(createSchedulePickup)} className="container ">
          <div className="row">
            <div className="col-md-12 col-lg-7 schedule_pickup_div style-2">
              <div className="back_icon_" onClick={() => history.goBack()}>
                <IonIcon
                  color="black"
                  icon={arrowBackOutline}
                />
              </div>
              <Accordion>
                <div className="date_nd_time">
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <div className="schedule_date">
                        <p className="schedule_title">Enter Date</p>
                        <div style={errors.pickup_date ? { border: '1px solid red' } : {}} className="calendar_nd_date">
                          <CalenderSchedule setSelectSlot={setSelectSlot} selectDate={selectDate} setSelectDate={setSelectDate} availableDates={availableDates} totalPickupSlots={totalPickupSlots} setPickupSlots={setPickupSlots} formReset={formReset} setFormReset={setFormReset} resetField={resetField} clearErrors={clearErrors} errors={errors} setValue={setValue} register={register} />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-3">
                      <div className="schedule_time">
                        <p className="schedule_title">Enter Time</p>
                        <div className="calendar_nd_date" style={errors.pickup_slot ? { border: '1px solid red' } : {}}>
                          <TimeSchedule selectSlot={selectSlot} setSelectSlot={setSelectSlot} selectDate={selectDate} pickupSlots={pickupSlots} formReset={formReset} setFormReset={setFormReset} resetField={resetField} clearErrors={clearErrors} errors={errors} setValue={setValue} register={register} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="location_tab ">
                  <div className="">
                    <p className="schedule_title">Select an Address</p>
                    <AddressSchedule
                      selectAddress={selectAddress}
                      setSelectAddress={setSelectAddress}
                      setShowToast1={setShowToast1}
                      formReset={formReset}
                      setFormReset={setFormReset}
                      resetField={resetField}
                      clearErrors={clearErrors}
                      errors={errors}
                      setValue={setValue}
                      register={register}
                      setAllowedToFetchSchedule={setAllowedToFetchSchedule}
                      allowedToFetchSchedule={allowedToFetchSchedule}
                      setSideBarOpen={setSideBarOpen}
                    />
                  </div>
                </div>
                <EstimatedWeight
                  weightRange={weightRange}
                  formReset={formReset}
                  setFormReset={setFormReset}
                  resetField={resetField}
                  clearErrors={clearErrors}
                  errors={errors}
                  setValue={setValue}
                  register={register}
                  selectObject={selectObject}
                />
                {
                  pickupScreen?.notice1?.is_shown &&
                  <div className="pickup_schedule_short_info" dangerouslySetInnerHTML={{ __html: pickupScreen?.notice1?.content }} />
                }
                <RemarkOptions formReset={formReset} setFormReset={setFormReset} resetField={resetField} register={register} required={false} />
                <div className="location_tab pb-2">
                  {true && (
                    LoadingSpinner ? (
                      <button
                        type="submit"
                        className="schedule_pickup_button_local"
                      >
                        <IonSpinner name="lines" />
                      </button>
                    ) : (
                      <IonButton
                        type="submit"
                        className="schedule_pickup_button_local"
                      >
                        Schedule A Pickup
                      </IonButton>
                    )
                  )}
                  <p
                    style={{
                      fontSize: '.9rem',
                      fontWeight: 500,
                      marginBottom: '4rem',
                    }}
                  >
                    <span style={{
                      fontStyle: 'italic'
                    }}>
                      To view the scheduled pickups click
                    </span>
                    <span
                      onClick={() => history.push('/w/pickup-view')}
                      style={{
                        color: 'green',
                        fontWeight: 600,
                        marginLeft: '.4rem',
                        cursor: 'pointer'
                      }}
                    >
                      Check My Pickups
                    </span>
                  </p>
                  {otpVerifyScreen?.is_shown ?
                    <div className="help_text schedule_page" dangerouslySetInnerHTML={{ __html: otpVerifyScreen?.content }} />
                    : null
                  }
                  <p className="fill_schedule_text">
                    <IonIcon
                      className="help_circle_icon"
                      icon={helpCircle}
                    ></IonIcon>
                    Please fill all the required details to schedule pickup
                  </p>
                </div>
              </Accordion>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LocalSchedule;