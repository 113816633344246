import React, { useState } from "react"
import useLocalStorage from "../../Services/useLocalStorage";
import ApiRequest from "../../Services/Api.Service";
import { useMutation, useQuery } from "react-query";
import { IonButton, IonIcon, IonSpinner, IonToast } from "@ionic/react";
import {
    alertCircle,
    calendar,
    chevronDown,
    cube,
    helpCircle,
    location,
    time,
    trashOutline,
    arrowBackOutline
} from "ionicons/icons";

const AddressLi = ({ item, selectAddress, onChangeAddress, setShowToast1, setSelectAddress, setAllowedToFetchSchedule }: any) => {
    const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
    const [loading, setLoading] = useState(false)

    const postData = ({ path, body, token }: any) => {
        return ApiRequest("DELETE", path, { body: body, isAuthenticated: false, token })
    }
    let newAddressCreate = useMutation(postData)

    const delectAddress = (id: any) => {
        setLoading(true);
        try {
            newAddressCreate.mutate({ path: `v1/users/address/${id}}`, body: {}, token: loggedInUser?.token }, {
                onSuccess: (res) => {
                    if (res.success) {
                        if (id === selectAddress.id) {
                            setSelectAddress({})
                        }
                        setShowToast1({ status: true, message: 'Delete Address Successfully.' })
                        setAllowedToFetchSchedule(true)
                    } else {
                        setShowToast1({ status: true, message: res?.message })
                    }
                    setLoading(false)
                },
                onError: (err: any) => {
                    setLoading(false)
                    setShowToast1({ status: true, message: err?.message })

                }
            })
        }
        catch (err) {
            console.log(err)
        }
    };

    return (
        <li

            className={
                selectAddress?.id === item.id
                    ? "active local_address_li"
                    : "local_address_li"
            }
        >

            <div onClick={(event: any) => onChangeAddress(event, item)} className="local_address_div" title={`${item?.line1 ? `${item?.line1}, ` : ''}` +
                `${item?.landmark ? `${item?.landmark}, ` : ''}` +
                `${item?.city ? `${item?.city}` : ''}` +
                `${item?.state ? `, ${item?.state}` : ''}` +
                `${item?.pincode ? `, ${item?.pincode}` : ''}`
            }>
                <p className={`category_text ${selectAddress?.id === item.id ? 'active' : ''}`}>{item?.title}</p>
                <p className="address_line" >
                    {/* {item.line1} */}
                    {`${item?.line1 ? `${item?.line1}, ` : ''}` +
                        `${item?.landmark ? `${item?.landmark}, ` : ''}` +
                        `${item?.city ? `${item?.city}` : ''}` +
                        `${item?.state ? `, ${item?.state}` : ''}` +
                        `${item?.pincode ? `, ${item?.pincode}` : ''}`
                    }
                </p>
            </div>
            {
                loading ?
                    <IonSpinner name="crescent" />
                    :
                    <div onClick={() => delectAddress(item.id)} className='trash_div'>
                        <IonIcon className={`trash_icon ${selectAddress?.id === item.id ? 'text-white' : ''}`} icon={trashOutline} />
                    </div>
            }
        </li>
    )
}

export default AddressLi