import { IonButton } from "@ionic/react";
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import SideBar from "../SideBar/SideBar";
import WebHeader from "../WebHeader/WebHeader";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useLocalStorage from "Services/useLocalStorage";
import ApiRequest from "../../../Services/Api.Service";
import { IonRow, IonCol } from "@ionic/react";
import dayjs from "dayjs";

const ImgPic = styled.img`
  width: 100%;
  border-radius: 10px;
`;
const Div = styled.div`
  padding-top: 1rem;
`;
const ImpactImg = styled.img`
  maxWidth: 100%;
  marginTop: 1rem;
  width: ;100%;

`;
interface PropsApi {
  homeContentInfo: any;
}

const HomeContent: React.FC<PropsApi> = ({ homeContentInfo }) => {
  const [filterDate, setFilterData] = useState("");
  const [addItems, setAddItems] = useState<any>({});
  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <div>
      <WebHeader
        sideBarOpen={[sideBarOpen, setSideBarOpen]}
        setAddItems={setAddItems}
        filterData={[filterDate, setFilterData]}
      />
      <div className="web_view_main_content">
        <SideBar />
        <div className="web_view_impact_content web_view_top">
          <div className="content_div style-2">
            <h2 className="header_title h2">Impact by Scrapuncle</h2>
            <div className="middle_content_image">
              <div className="recycled_kg">
                <p>
                  {homeContentInfo?.impact && homeContentInfo?.impact?.recycled}
                </p>
                <span>RECYCLED</span>
              </div>
              <div className="earned">
                <p>{homeContentInfo?.impact && homeContentInfo?.impact?.earned}</p>
                <span>EARNED</span>
              </div>
              <ImpactImg
                src="https://ikp.edgekit.net/y8s2vhk66ef/impact_QK7lGbCGlH.png"
                alt=""
              />
            </div>
            <h2 className="header_title">Recycle Responsibly</h2>
            <div className="middle_content_image pt">
              <div className="overlay">
                <h3 className="overlay_text">
                  Week-long Garbage-Free Country?
                </h3>
                <ImgPic
                  src="https://ikp.edgekit.net/y8s2vhk66ef/norendoModi_MyUS6uh1x.png"
                  alt=""
                />
              </div>
            </div>
            <div className="middle_content_image pt">
              <div className="">
                <h3 className="overlay_text">Kabadiwala: The Green Agents</h3>
                <ImgPic
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Rectangle229_lnEWw2J2c.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <Div className="web_view_upcoming_picksup web_view_top">
          {homeContentInfo?.upcoming_pickup &&
            homeContentInfo?.upcoming_pickup?.length !== 0 ? (
            <>
              <Calendar
                className="calender"
                defaultActiveStartDate={
                  homeContentInfo?.upcoming_pickup &&
                  dayjs(homeContentInfo?.upcoming_pickup?.pickup_date).toDate()
                }
                value={
                  homeContentInfo?.upcoming_pickup &&
                  dayjs(homeContentInfo?.upcoming_pickup?.pickup_date).toDate()
                }
              />
              <h2 className="header_title">Upcoming Pickup</h2>
              {homeContentInfo?.upcoming_pickup && (
                <IonRow>
                  <IonCol>
                    <div className="d-flex page_three_box">
                      <div className="date">
                        <h3>
                          {dayjs(homeContentInfo?.upcoming_pickup?.pickup_date)
                            .toDate()
                            .toString()
                            .slice(0, 3)}
                        </h3>

                        <b>
                          {dayjs(
                            homeContentInfo?.upcoming_pickup?.pickup_date
                          ).date() < 10 && "0"}
                          {dayjs(
                            homeContentInfo?.upcoming_pickup?.pickup_date
                          ).date()}
                        </b>
                      </div>

                      <div className="location">
                        <p>
                          {homeContentInfo?.upcoming_pickup &&
                            homeContentInfo?.upcoming_pickup?.pickup_time.slice(
                              0,
                              8
                            )}
                        </p>
                        <p>{homeContentInfo?.upcoming_pickup?.address.line1}</p>
                        <span>
                          {homeContentInfo?.upcoming_pickup?.items[0].title},
                          {homeContentInfo?.upcoming_pickup &&
                            homeContentInfo?.upcoming_pickup?.items?.length > 1 &&
                            " +" + homeContentInfo?.upcoming_pickup &&
                            homeContentInfo?.upcoming_pickup?.items?.length +
                            " MORE"}
                        </span>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              )}
              <Link to="/pickup">
                <IonButton
                  expand="block"
                  className="btn-schedule-pickup"
                  color="success"
                >
                  See all Pickups
                </IonButton>
              </Link>
            </>
          ) : (
            <>
              <h2 className="header_title text-center">Upcoming Pickup</h2>
              <p className="text-center mt-5">There is No Pickups Right Now</p>
            </>
          )}
        </Div>
      </div>
    </div>
  );
};

export default HomeContent;
