import { API_CONFIG, TRUECALLER_CONFIG } from "Config";
import { UniqueDeviceID } from '@ionic-native/unique-device-id'
import { v4 as uuidv4 } from 'uuid';
import ApiRequest from "./Api.Service";

let TrueCaller_INTENT_URL:string = `truecallersdk://truesdk/web_verify?type=btmsheet
&requestNonce=UNIQUE_REQUEST_ID
&partnerKey=${TRUECALLER_CONFIG.APP_KEY}
&partnerName=${TRUECALLER_CONFIG.APP_NAME}
&lang=en`


// TrueCaller_INTENT_URL += `&privacyUrl=${TRUECALLER_CONFIG.PRIVACY_PAGE_URL}
// &termsUrl=${TRUECALLER_CONFIG.TNC_PAGE_URL}`

TrueCaller_INTENT_URL += `&loginPrefix=continue
&loginSuffix=verifymobile
&ctaPrefix=continuewith
&ctaColor=%233ab34a
&ctaTextColor=%23ffffff
&btnShape=round
&skipOption=FOOTER_CTA_STRING`

declare global {
    interface Window { 
        Pusher: any; 
        pusher: any;
    }
}

export function TruecallerLoginStart(){

    /** TODO: Set Expiry for truecaller_uuid */
    const InvokeTruecaller = (x_uuid:string) => {

        let uuid = x_uuid;
        let local_uuid = localStorage.getItem('truecaller_uuid');
        
        if(local_uuid){
            uuid = local_uuid
        } 

        const req_nonce = `${uuid}`;  // random number of length 8 to 64 characters
        
        localStorage.setItem('truecaller_uuid', req_nonce)

        TrueCaller_INTENT_URL = TrueCaller_INTENT_URL.replace("UNIQUE_REQUEST_ID", req_nonce)
        TrueCaller_INTENT_URL = TrueCaller_INTENT_URL.replace(/(\r\n|\n|\r)/gm, "");
        

        try{
            window.location.href = TrueCaller_INTENT_URL;
        }catch(e){
            console.error('Redirect Error:', e)
        }

    }

    UniqueDeviceID.get()
        .then((uuid: any) => {

            InvokeTruecaller(uuid)
    
        })
        .catch((error: any) =>{

            let uid = uuidv4();
            InvokeTruecaller(uid)

        });

}

let autofill_called:boolean = false;
export function TruecallerLoginEventResponse(autofillPhone:any){
    
    window.Pusher.logToConsole = true;

    const pusher = new window.Pusher('5cca0198a61d443db479', {
      cluster: 'ap2'
    });

    window.pusher = pusher;

    const channel = pusher.subscribe('auth-channel');
    const truecaller_uuid = localStorage.getItem('truecaller_uuid')

    channel.bind(`login-${truecaller_uuid}`, function(data:any) {

    });
    
    channel.bind(`login-phone-${truecaller_uuid}`, function(data:any) {

        let user_phone = data['message']['phone'];
        user_phone = user_phone.toString()

        if(user_phone){
            user_phone = user_phone.substring(2)
            if(!autofill_called){
                autofillPhone(user_phone, true)
                autofill_called = true;
            }
        }

    });

}

export function TruecallerLoginConfirm(){
    
    return new Promise((resolve, reject)=>{

        const truecaller_uuid = localStorage.getItem('truecaller_uuid')
        ApiRequest("POST", "/auth/verify", { body:{
            auth_provider:'truecaller',
            auth_data:{
                truecaller_uuid
            }
        }})
            .then((data) => {

                if(data.success === true){
                    resolve(data)
                }else{
                    reject(new Error(data.message))
                }
            })
            .catch(err => {

                reject(err)

            })
        
    })
}

export function DisconnectTruecallerAuthChannel(){
    if(window.pusher){
        try{
            window.pusher.disconnect();
        }catch(e){
            console.error("Error Disconnecting Pusher")
        }
    }
}