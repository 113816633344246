import ImageKit from "imagekit-javascript";
import { IMAGEKIT_CONFIG } from "Config";

var imagekit = new ImageKit({
  publicKey: IMAGEKIT_CONFIG.PUBLIC_KEY,
  urlEndpoint: IMAGEKIT_CONFIG.URL_ENDPOINT,
  authenticationEndpoint: IMAGEKIT_CONFIG.AUTH_ENDPOINT,
});

const uploads = (e) => {
  e.preventDefault(e);
  return imagekit;
};

export default uploads;
