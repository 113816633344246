import React from "react";
import { IonCol,  IonGrid, IonRow } from "@ionic/react";
interface ContainerProps {
  title: string;
}
const Title: React.FC<ContainerProps> = ({ title }) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <h3 className="upcoming_pickup_detail_title">{title}</h3>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Title;
