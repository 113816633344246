import React, { useState } from "react";
import { IonPage } from "@ionic/react";
import "./Support.css";
import styled from "styled-components";

import WebHeader from "../WebView/WebHeader/WebHeader";
import SideBar from "../WebView/SideBar/SideBar";
import Support from "./Support";

const DivPadding = styled.div`
  padding-top: 0px;
  padding-left: 13rem;
  padding-right: 0px;
`;
const Div = styled.div`
  padding-top: 0px;
`;
const DivHeight = styled.div`
  overflow: hidden;
  height: 88vh;
  margin-top: 1rem;
`;

const SupportView: React.FC = () => {
  const [addItems, setAddItems] = useState<any>({});
  const [filterDate, setFilterData] = useState("");
  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <IonPage>
      <div className="mobile-view">
        <Support Header={true} />
      </div>

      <div className="web-view style-2">
        <>
          <div>
            <WebHeader
              sideBarOpen={[sideBarOpen, setSideBarOpen]}
              setAddItems={setAddItems}
              filterData={[filterDate, setFilterData]}
            />
            <div className="web_view_main_content">
              <SideBar />
              <DivPadding className="web_view_impact_content web_view_top">
                <DivHeight>
                  <Support Header={false} />
                </DivHeight>
              </DivPadding>
              <Div className="web_view_upcoming_picksup web_view_top"></Div>
            </div>
          </div>
        </>
      </div>
    </IonPage>
  );
};

export default SupportView;
