import React from "react";
import { IonContent, IonRow, IonGrid, IonCol, IonButton } from "@ionic/react";
import "./SignUpOTP.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {  useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

type Inputs = {
  example: string;
  exampleRequired: string;
};
const BussinessDetails: React.FC = () => {
  const history = useHistory();
  const {  handleSubmit } = useForm<Inputs>();
  const onSubmit = (data: any) => {
    history.push("/home");
  };
  return (
    <IonContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ionGrid">
          <IonRow>
            <IonCol>
              <div className="sign_header">
                <p className="bussiness_header">
                  Enter business details to complete profle
                </p>
              </div>
              <p className="sign_header_paragraph">nature of business</p>
              <div id="bussiness_nature" className="sign_header_input">
                <input
                  className="sign_header_paragraph_input"
                  type="text"
                  name="natureBussiness"
                  id=""
                  required
                />
                <ArrowDropDownIcon className="arrow_key" />
              </div>

              <p className="sign_header_paragraph">COMPANY NAME</p>
              <input
                className="sign_header_input"
                type="text"
                name="companyName"
                id=""
                required
              />

              <p className="sign_header_paragraph">COMPANY ADDRESS</p>
              <input
                className="sign_header_input"
                type="text"
                name="companyAddress"
                id=""
                required
              />

              <p className="sign_header_paragraph">PIN CODE</p>
              <input
                className="sign_header_input"
                type="number"
                name="pin"
                id=""
                required
              />

              <p className="sign_header_paragraph">GSTIN</p>
              <input
                className="sign_header_input"
                type="text"
                name="gstin"
                id=""
                required
              />

              <label className="check_terms_box" htmlFor="term_condition">
                <input
                  className="checkbox"
                  type="checkbox"
                  name="termCondition"
                  id="term_condition"
                  required
                />
                <p className="terms_condition">
                  I agree to the terms and conditions and privacy policy
                </p>
              </label>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonButton
          id="bottom_btn_bussiness_btn"
          className="bottom_Btn"
          type="submit"
          color="success"
          expand="full"
        >
          {" "}
          NEXT{" "}
        </IonButton>
      </form>
    </IonContent>
  );
};

export default BussinessDetails;
