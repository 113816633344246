import React from "react"
import { IonButton, IonIcon, IonSpinner, IonToast } from "@ionic/react";
import {
    alertCircle,
    calendar,
    chevronDown,
    cube,
    helpCircle,
    location,
    time,
    trashOutline,
    arrowBackOutline
} from "ionicons/icons";

const ScheduleAccordionTitle = ({ errors = {}, defaultTitle = '', title = '', icon, customClass = '' }: any) => {
    return (<div className="d-flexs align-items-center ">
        <IonIcon
            className={title ? 'completed' : ''}
            icon={icon}
        />
        {title ? <span style={{ color: '#3AB34A' }} className="title_text">{title}</span> : <span>{defaultTitle}</span>}
        <IonIcon
            className="arrow_down_icon"
            icon={chevronDown}
        />

    </div>
    )
}

export default ScheduleAccordionTitle