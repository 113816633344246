import React, { useContext, useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useLocation, useHistory } from "react-router-dom";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { UserLocation } from "../App.tsx";
import ApiRequest from "../Services/Api.Service";
import useLocalStorage from "../Services/useLocalStorage";
import { GOOGLE_MAP } from "Config";

const PlacesAutocomplete = ({ states, position, getLocation }) => {
  const [targetLocation, setTargetLocation] = useContext(UserLocation);
  const [loggedInUser] = useLocalStorage("LoginUser", "");
  const [recentLocationSearch, setRecentLocationSearch] = useLocalStorage(
    "recentLocationSearch",
    []
  );
  const [address, setAddress] = useState([]);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    ApiRequest("GET", "v1/users/address", {
      isAuthenticated: false,
      token: loggedInUser.token,
    }).then((data) => setAddress(data.data));
  }, []);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  Geocode.setApiKey(GOOGLE_MAP.GOOGLE_MAP_KEY);
  Geocode.setLanguage("en");
  const handleSelect =
    ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        // clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            Geocode.fromLatLng(lat, lng).then(
              (response) => {
                const address = response.results[0].formatted_address;
                let city, state, country, pincode;
                for (
                  let i = 0;
                  i < response.results[0].address_components.length;
                  i++
                ) {
                  for (
                    let j = 0;
                    j < response.results[0].address_components[i].types.length;
                    j++
                  ) {
                    switch (response.results[0].address_components[i].types[j]) {
                      case "locality":
                        city =
                          response.results[0].address_components[i].long_name;
                        break;
                      case "administrative_area_level_1":
                        state =
                          response.results[0].address_components[i].long_name;
                        break;
                      case "country":
                        country =
                          response.results[0].address_components[i].long_name;
                        break;
                      case "postal_code":
                        pincode =
                          response.results[0].address_components[i].long_name;
                        break;
                      default:
                    }
                  }
                }
                setRecentLocationSearch([
                  ...recentLocationSearch.slice(0, 3),
                  {
                    line2: address,
                    locality: city,
                    city,
                    state,
                    pincode,
                    country,
                    lat,
                    lng,

                  },
                ]);
                setTargetLocation({
                  line2: address,
                  locality: city,
                  city,
                  state,
                  pincode,
                  country,
                  lat,
                  lng,

                });
                history.push({
                  locationInfo: {
                    line2: address,
                    locality: city,
                    city,
                    state,
                    pincode,
                    country,
                    lat,
                    lng,

                  },
                  pathname: "/mapaddress",
                  state: states
                });
              },
              (error) => {
                console.error(error);
              }
            );
          })
          .catch((error) => {
            console.log("😱 Error: ", error);
          });
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        // <Link  to="/mapaddress">
        <li
          key={place_id}
          className="result_search"
          onClick={handleSelect(suggestion)}
        >
          <img
            src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_location_solid_1__-E5nm9Ese.png"
            alt=""
          />
          <div className="search_name">
            <div>
              <strong>{main_text}</strong>
            </div>{" "}
            <small>{secondary_text}</small>
          </div>
        </li>
        // </Link>
      );
    });
  useEffect(() => {
    setTargetLocation(null);
  }, []);

  const addressClick = (item, searchName) => {
    if (searchName === 'saved') {
      const latLng = JSON.parse(item.location);
      setTargetLocation({ ...targetLocation, ...item, lat: latLng.lat, lng: latLng.lng, });
    } else {
      setTargetLocation({ ...targetLocation, ...item });
    }
    history.push({ pathname: "/mapaddress", state: states });
  };
  return (
    <div className="search_div">
      <div className="search_bar-container">
        <input
          className="search_bar"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Enter area, street name..."
        />
      </div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" ? (
        <>
          <div className="result_search" onClick={getLocation}>
            {
              <>
                <img
                  className="search_location_img"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_Pin_2Ar_7ZsWI.png"
                  alt="location"
                />
                <p className="search_text">
                  <span className="search_text_label">Use Current Location</span>
                  <span className="search_text_desc">Using GPS</span>
                </p>
              </>
            }
          </div>
          <ul className="search_ul">{renderSuggestions()}</ul>
        </>
      ) : (
        <>
          <div className="result_search" onClick={getLocation}>
            {
              <>
                <img
                  className="search_location_img"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_Pin_2Ar_7ZsWI.png"
                  alt="location"

                />
                <p className="search_text">
                  <span className="search_text_label">Use Current Location</span>
                  <span className="search_text_desc">Using GPS</span>
                </p>

              </>
            }
          </div>

          <ul className="search_ul">
            {JSON.stringify(address) !== "[]" && (
              <span className="recent_search_mobile">SAVED ADDRESS</span>
            )}
            {address.length > 5
              ? address
                .slice(Math.max(address.length - 5, 1))
                .reverse()
                .map((item, index) => (
                  <li
                    key={index}
                    onClick={() => addressClick(item, 'saved')}
                    className="result_search"
                  >
                    <img
                      src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_location_solid_1__-E5nm9Ese.png"
                      alt="location"

                    />
                    <div className="search_name">
                      <div className="title">
                        <strong>{item.title}</strong>
                      </div>{" "}
                      <small>{item.line1}</small>
                    </div>
                  </li>
                ))
              : address.map((item, index) => (
                <li
                  key={index}
                  onClick={() => addressClick(item, 'saved')}
                  className="result_search"
                >
                  <div className="icon_container">
                    <img
                      src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_location_solid_1__-E5nm9Ese.png"
                      alt="location"

                    />
                  </div>
                  <div className="search_name">
                    <div className="title">
                      <strong>{item.title}</strong>
                    </div>{" "}
                    <small>{item.line1}</small>
                  </div>
                </li>
              ))}

          </ul>

          <ul className="search_ul">
            {JSON.stringify(recentLocationSearch) !== "[]" && (
              <>

                <span className="recent_search_mobile">RECENT SEARCH</span>
                {recentLocationSearch
                  .reverse()
                  .slice(0, 3)
                  .map((item, index) => (
                    <li
                      key={index}
                      className="result_search"
                      onClick={() => addressClick(item, 'recent')}
                    >
                      <div className="icon_container">
                        <img
                          src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_location_solid_1__-E5nm9Ese.png"
                          alt="location"

                        />
                      </div>
                      <div className="search_name">
                        <div className="title">
                          <strong>
                            {item.city}, {item.country}
                          </strong>
                        </div>{" "}
                        <small>{item.line1}</small>
                      </div>
                    </li>
                  ))}
              </>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
