import React, { useContext, useEffect } from "react";
import {
  IonPage,
} from "@ionic/react";
import Profile from "../Components/Profile/Profile";
import ProfileWeb from "../Components/WebView/ProfileWeb/ProfileWeb";
import {  useLocation } from "react-router";
import { UseActiveTabShow, UserProfile } from "../App";

const Tab5: React.FC = () => {
 
  const [profile, setProfile] = useContext<any>(UserProfile);
  const location = useLocation();
  const [activeTabShow, setActiveTabShow] = useContext<any>(UseActiveTabShow);
  useEffect(() => {
    setProfile({ ...profile, location: location.pathname });
  }, []);
  useEffect(() => {
    setActiveTabShow(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <IonPage>
      <div className="mobile-view">
        <Profile />
      </div>

      <div className="web-view style-2">
        <ProfileWeb />
      </div>
    </IonPage>
  );
};

export default Tab5;
