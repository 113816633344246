import React, { useState, useEffect } from "react"
import useLocalStorage from "../../Services/useLocalStorage";
import { useHistory } from "react-router";
import ApiRequest from "../../Services/Api.Service";
import { useMutation, useQuery } from "react-query";
import { Accordion } from "react-bootstrap";
import ScheduleAccordionTitle from "./ScheduleAccordionTitle"
import { IonButton, IonIcon, IonSpinner, IonToast } from "@ionic/react";
import AddressLi from "./AddressLi"
import {
    helpCircle,
    location,
    arrowBackOutline
  } from "ionicons/icons";
  
const AddressSchedule = ({ selectAddress, setSelectAddress, setShowToast1, formReset, setFormReset, resetField, allowedToFetchSchedule, setAllowedToFetchSchedule, setSideBarOpen, clearErrors, errors, setValue, register }: any) => {
    const [loggedInUser] = useLocalStorage<any>("LoginUser", {});
    const [addressList, setAddressList] = useState([]);
    const history = useHistory();

    //Get All Address 
    const getData = async ({ queryKey: [name, path] }: any) => {
        const data = await ApiRequest("GET", path, {
            isAuthenticated: false,
            token: loggedInUser?.token,
            source: "web",
        })
        return data;
    }
    let { data, isLoading } = useQuery(['address', 'v1/users/address'], getData, { enabled: allowedToFetchSchedule })

    useEffect(() => {
        if (!loggedInUser?.token) {
            history.push("/");
        }
        if (data) {
            if (data?.success) setAddressList(data?.data);
            setAllowedToFetchSchedule(false)
        }
    }, [data]);

    const addressClick = () => {
        setSideBarOpen(true)
    }

    useEffect(() => {
        if (loggedInUser.token) {
            setAllowedToFetchSchedule(true)
        }
    }, [loggedInUser.token])

    const onChangeAddress = (event: any, item: any) => {
        console.log('Selected Address', item)
        window?.dataLayer.push({pickup_address:`${item?.line2}, ${item?.landmark}, ${item?.pincode}`})
        window?.dataLayer.push({pickup_pincode: item?.pincode})
        setSelectAddress({ ...item })
    }

    useEffect(() => {
        register('address_id', { required: true })
        if (selectAddress?.id) {
            setValue('address_id', selectAddress?.id)
        }
    }, [selectAddress])


    useEffect(() => {
        if (formReset) {
            setSelectAddress({})
            resetField('address_id')
            setFormReset(false)
        }
    }, [formReset])


    return (
        <Accordion.Item eventKey="3">
            <div style={errors.address_id ? { border: '1px solid red' } : {}} className="address_local calendar_nd_date">
                <Accordion.Header className="w-100">
                    <ScheduleAccordionTitle
                        // title={selectAddress?.line1}
                        title={`${selectAddress?.line1 ? `${selectAddress?.line1}, ` : ''}` +
                            `${selectAddress?.landmark ? `${selectAddress?.landmark}, ` : ''}` +
                            `${selectAddress?.city ? `${selectAddress?.city}` : ''}` +
                            `${selectAddress?.state ? `, ${selectAddress?.state}` : ''}` +
                            `${selectAddress?.pincode ? `, ${selectAddress?.pincode}` : ''}`
                        }
                        defaultTitle={'Choose Address'}
                        icon={location}
                        customClass="location_ion_icon"
                    />
                </Accordion.Header>

                <Accordion.Body>
                    <ul className="local_address_ul">
                        {isLoading ?
                            <div className="flex justify-center p-2">
                                <IonSpinner name="lines" />
                            </div>
                            :
                            Array.isArray(addressList) && !addressList?.length ? (
                                <p className="text-center my-2 p-2">Add a new address to schedule a pickup!</p>
                            ) : (
                                addressList.map((item: any) => (
                                    <AddressLi
                                        key={item.id}
                                        item={item}
                                        selectAddress={selectAddress}
                                        onChangeAddress={onChangeAddress}
                                        setShowToast1={setShowToast1}
                                        setSelectAddress={setSelectAddress}
                                        setAllowedToFetchSchedule={setAllowedToFetchSchedule}
                                    />
                                ))
                            )
                        }
                        <li
                            onClick={addressClick}
                            className="local_address_li add_new_address_btn"
                        >
                            Add New Address
                        </li>
                    </ul>
                </Accordion.Body>
            </div>
        </Accordion.Item >
    )
}

export default AddressSchedule