import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonAlert,
  IonRow,
  IonThumbnail,
  IonRippleEffect,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { userTabClick } from "../../events";
import useLocalStorage from "../../Services/useLocalStorage";
import { UserProfile } from "./../../App";
import Config from "../../Config";
import "./Profile.css";
const LinkP = styled.p`
  texttransform: uppercase !important;
  alignself: flex-end;
  font-weight: bold;
  margin: 0px;
`;
const P = styled.p`
  margin-bottom: 0px;
  margintop: 10px;
  texttransform: uppercase;
`;
const ArrowImg = styled.img`
  width: 10px;
  height: 18px;
  alignself: center;
`;
const Div = styled.div`
  margin-left: 15px;
`;

const Profile: React.FC = () => {
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>("LoginUser", "");
  const history = useHistory();
  const [logOutBtn, setLogOutBtn] = useState(false);

  //Checking window or mobile screen is available
  const checkingScreenSize = () => {
    if (window.screen.availWidth > 992) {
      logOut();
    } else {
      setLogOutBtn(true);
    }
  };

  //User Logout Function
  const logOut = () => {
    userTabClick("/logout");
    setLoggedInUser("", "");
    setProfile("");

    history.push("/login-page");
  };

  return (
    <IonContent>
      <div className="webviewProfileHeader">
        <div className="profile_header">
          <div>
            <h1>{profile.name || loggedInUser.name}</h1>
            <P>+91 {profile.phone || loggedInUser.phone}</P>
          </div>
          <LinkP className="edit">
            <Link to="/editProfile">Edit</Link>
          </LinkP>
        </div>
      </div>
      <IonGrid>
        <IonRow>
          <IonCol className="webviewProfileBody">
            <Link to="/saveaddresses">
              <div className="list ion-activatable ripple-parent">
                <div className="list_item ">
                  <div className="list_item_d-flex">
                    <IonThumbnail slot="start">
                      <IonImg
                        className="icon"
                        src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_location_solid_qVIOANvIZ.png"
                      />
                    </IonThumbnail>

                    <Div>
                      <h3>Saved Addresses</h3>
                      <P>your addresses for pickups</P>
                    </Div>
                  </div>
                  <ArrowImg
                    src="https://ikp.edgekit.net/y8s2vhk66ef/Vector705_Stroke__1__H-EeLWcX6.png"
                    alt=""
                  />
                </div>
                <IonRippleEffect></IonRippleEffect>
              </div>
            </Link>

            <Link to="/referfriend">
              <div className="list ion-activatable ripple-parent">
                <div className="list_item">
                  <div className="list_item_d-flex">
                    <IonThumbnail slot="start">
                      <IonImg
                        className="icon"
                        src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_share_ouline_fe0bLrNnK.png"
                      />
                    </IonThumbnail>
                    <Div>
                      <h3>Refer A Friend</h3>
                      <P>Earn Rs x for Friend Refer</P>
                    </Div>
                  </div>
                  <ArrowImg
                    src="https://ikp.edgekit.net/y8s2vhk66ef/Vector705_Stroke__1__H-EeLWcX6.png"
                    alt=""
                  />
                </div>
                <IonRippleEffect></IonRippleEffect>
              </div>
            </Link>
            <Link to="/support">
              <div className="list ion-activatable ripple-parent">
                <div className="list_item">
                  <div className="list_item_d-flex">
                    <IonThumbnail slot="start">
                      <IonImg
                        className="icon"
                        src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_support_outline_BePia3jF2.png"
                      />
                    </IonThumbnail>
                    <Div>
                      <h3>Support</h3>
                      <P>Contact swapeco support</P>
                    </Div>
                  </div>
                  <ArrowImg
                    src="https://ikp.edgekit.net/y8s2vhk66ef/Vector705_Stroke__1__H-EeLWcX6.png"
                    alt=""
                  />
                </div>
                <IonRippleEffect></IonRippleEffect>
              </div>
            </Link>
            <Link to="/about">
              <div className="list ion-activatable ripple-parent">
                <div className="list_item">
                  <div className="list_item_d-flex">
                    <IonThumbnail slot="start">
                      <IonImg
                        className="icon"
                        src="https://ikp.edgekit.net/y8s2vhk66ef/Vector__1__REmyNETs8.png"
                      />
                    </IonThumbnail>
                    <Div>
                      <h3>About</h3>
                      <P>Learn more about Scrapuncle</P>
                    </Div>
                  </div>
                  <ArrowImg
                    src="https://ikp.edgekit.net/y8s2vhk66ef/Vector705_Stroke__1__H-EeLWcX6.png"
                    alt=""
                  />
                </div>
                <IonRippleEffect></IonRippleEffect>
              </div>
            </Link>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="app_info">
            <P className="cursor-pointer" onClick={checkingScreenSize}>
              LogOut
            </P>
            <p className="custom"></p>
            <p className="app_version">App Version 2.0.1</p>
            <IonAlert
              isOpen={logOutBtn}
              onDidDismiss={() => setLogOutBtn(false)}
              cssClass="my-custom-class"
              header={"Logout?"}
              mode="ios"
              buttons={[
                {
                  text: "Cancel",
                  role: "cancel",
                },
                {
                  text: "Ok",
                  cssClass: "secondary",
                  handler: () => logOut(),
                },
              ]}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Profile;
