import { IonIcon } from "@ionic/react";
import {

  closeOutline,
  locateOutline,
  locationOutline,
} from "ionicons/icons";
import React, { useContext, useState, useEffect, useRef } from "react";
import ApiRequest from "../../../Services/Api.Service";
import useLocalStorage from "../../../Services/useLocalStorage";
import "./WebHeader.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Geocode from "react-geocode";
import { UseReload, UserLocation } from "../../../App";
import { useLocation } from "react-router";
import MapWithAMarker from "../../SearchAddress/MapWithAMarker";
import AddressSet from "./AddressSet";
import { Geolocation, Geoposition } from "@ionic-native/geolocation";
import { GOOGLE_MAP } from "Config";

interface SideBarOpen {
  sideBarOpen: any;
  locationRef: any;
  showItem: any;
  getLocation: any;
  position: any;
  setAllowedToFetchSchedule: any;
  features: any;
  setSelectAddress?:any
}
interface LocationError {
  showError: boolean;
  message?: string;
}

const LocationSidebar: React.FC<SideBarOpen> = (props) => {
  const [sideBarOpen, setSideBarOpen] = props.sideBarOpen;
  const [loggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [recentLocationSearch, setRecentLocationSearch] = useLocalStorage<any>(
    "recentLocationSearch",
    []
  );
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);
  const [address, setAddress] = useState<any>([]);
  const [showItem, setShowItem] = props.showItem;
  const [loading, setLoading] = useState<boolean>(false);
  const locationRef = useRef<any>(null);


  const [error, setError] = useState<LocationError>({
    showError: false,
    message: "",
  });
  const [position, setPosition] = useState<Geoposition>();
  const [reload] = useContext<any>(UseReload);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      ApiRequest("GET", "v1/users/address", {
        isAuthenticated: false,
        token: loggedInUser.token,
      }).then((data) => setAddress(data.data));
    }, 0)
    return () => clearTimeout(delayDebounceFn)
  }, [reload.address]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });


  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: any) =>
      () => {
        setShowItem("map");

        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }: any) => {
            Geocode.fromLatLng(lat, lng).then(
              (response) => {
                const address = response.results[0].formatted_address;
                let city, state, country, pincode;
                for (
                  let i = 0;
                  i < response.results[0].address_components.length;
                  i++
                ) {
                  for (
                    let j = 0;
                    j < response.results[0].address_components[i].types.length;
                    j++
                  ) {
                    switch (response.results[0].address_components[i].types[j]) {
                      case "locality":
                        city =
                          response.results[0].address_components[i].long_name;
                        break;
                      case "administrative_area_level_1":
                        state =
                          response.results[0].address_components[i].long_name;
                        break;
                      case "country":
                        country =
                          response.results[0].address_components[i].long_name;
                        break;
                      case "postal_code":
                        pincode =
                          response.results[0].address_components[i].long_name;
                        break;
                      default:
                    }
                  }
                }
                setRecentLocationSearch([
                  ...recentLocationSearch.reverse().slice(0, 3),
                  {
                    line2: address,
                    locality: city,
                    city,
                    state,
                    pincode,
                    country,
                    lat,
                    lng,
                  },
                ]);
                setTargetLocation({
                  line2: address,
                  locality: city,
                  city,
                  state,
                  pincode,
                  country,
                  lat,
                  lng,
                });
              },
              (error) => {
                console.error(error);
              }
            );
          })
          .catch((error) => {
            console.log("😱 Error: ", error);
          });
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          className="result_search"
          onClick={handleSelect(suggestion)}
        >
          <IonIcon
            className="ion_web_icon saved_address_icon_web"
            icon={locationOutline}
          />
          <div className="search_name">
            <div>
              <strong>{main_text}</strong>
            </div>{" "}
            <small>{secondary_text}</small>
          </div>
        </li>
      );
    });

  const inputClear = (item: any) => {
    setValue("");
  };
  const addressClick = (item: any, click: any) => {


    if (click === "save") {
      const latLng = JSON.parse(item.location);
      setTargetLocation({ ...targetLocation, ...item, ...latLng });
      setShowItem("map");
    } else if (click === "recent") {

      setTargetLocation({ ...targetLocation, ...item, lat: item.lat, lng: item.lng });
      setShowItem("map");
    }
  };

  Geocode.setApiKey(GOOGLE_MAP.GOOGLE_MAP_KEY);
  Geocode.setLanguage("en");
  const getLocation = async () => {
    setLoading(true);
    try {
      const position = await Geolocation.getCurrentPosition();
      setPosition(position);
      setLoading(false);
      setError({ showError: false, message: undefined });
      const lat = position.coords.latitude.toString();
      const logss = position.coords.longitude.toString();
      Geocode.fromLatLng(lat, logss).then(
        (response: any) => {
          const address = response.results[0].formatted_address;
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          setTargetLocation({
            ...targetLocation,
            line2: address,
            locality: city,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            city: city,
            state: state,
            country: country,
          });
          setShowItem("map");
        },
        (error: any) => {
          console.error(error);
        }
      );
    } catch (err) {
      // @ts-ignore
      const message = err?.message ?? "Cannot get user. Check Permission. ";
      setError({ showError: true, message });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error.showError === true) {
      setTimeout(() => {
        setError({ showError: false, message: "" });
      }, 5000);
    }
  }, [error]);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (locationRef && !locationRef.current.contains(event.target)) {
        setSideBarOpen(false);
        setShowItem("map");
        setValue('')
      }
      // if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      //   setOpenCart(false);
      // }
    }
    if (window.screen.availWidth > 992) {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [locationRef]);
  return (
    <>
      <div
        className={sideBarOpen ? "sidebar_overlayOpen" : "sidebar_overlayClose"}
      ></div>
      <div
        ref={locationRef}
        className={sideBarOpen ? "location_sidebar open" : "location_sidebar"}
      >
        {showItem === "map" ? (
          <div className="sidebar-content style-2">
            <div className="sidebar_content_top">
              {/* <div className='cursor-pointer' onClick={() => {setShowItem("search"); setValue('')}}>

              <IonIcon
                style={{fontSize:'1.8rem'}}
                className="close_icon"
                icon={arrowBackOutline}
                />
              </div> */}
              <div className='cursor-pointer' onClick={() => setSideBarOpen(false)} >
                <IonIcon
                  style={{ fontSize: '1.8rem' }}
                  icon={closeOutline}
                />
              </div>
              <p> Set pickup location</p>
            </div>

            <div className="map_design hidden">
              <MapWithAMarker />
            </div>
            <AddressSet
              setSelectAddrss={props.setSelectAddress}
              features={props?.features}
              setAllowedToFetchSchedule={props.setAllowedToFetchSchedule}
              setValue={setValue}
              setSideBarOpen={setSideBarOpen}
              setShowItem={setShowItem}
            />
          </div>
        ) : (
          <div className="sidebar-content style-2">
            <div className='cursor-pointer' onClick={() => setSideBarOpen(false)} >
              <IonIcon
                style={{ fontSize: '1.8rem' }}
                className="close_icon"
                icon={closeOutline}
              />
            </div>

            <div className="address_search_box">
              <input
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Search for area, street name..."
                type="text"
                name="address"
                className="searchBox_input"
                id="search_input"
                autoFocus={true}
                autoComplete="off"
              />

              <div className='cursor-pointer' onClick={inputClear}>

                <IonIcon
                  className="close_btn"
                  icon={closeOutline}
                />
              </div>
            </div>

            <div className=" address_content style-2 web_search_result">
              {status === "OK" ? (
                <>
                  <ul className="search_ul">{renderSuggestions()}</ul>
                </>
              ) : (
                <>
                  <div className="current_location hidden" onClick={getLocation}>
                    <IonIcon className="ion_web_icon" icon={locateOutline} />
                    <p className="location_text">
                      Get current locations <span>Using GPS</span>
                    </p>
                  </div>
                  {error.showError && (
                    <p className="error_para error_location">{error.message}</p>
                  )}
                  {JSON.stringify(address) !== "[]" && (
                    <div className="saved_address_box">
                      <span className="saved_address_web_span">
                        SAVED ADDRESSES
                      </span>
                      {address &&
                        address.map((item: any, index: number) => (
                          <div
                            key={index}
                            onClick={() => addressClick(item, "save")}
                            className="saved_address_web"
                          >
                            <IonIcon
                              className="ion_web_icon saved_address_icon_web"
                              icon={locationOutline}
                            />
                            <div className="ionside_part">
                              <p className="location_text">
                                {item.title}
                                <span className="address_line1">
                                  {item.line1}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}

                  { }
                  {JSON.stringify(recentLocationSearch) !== "[]" && (
                    <div className="saved_address_box">
                      <span className="saved_address_web_span">
                        RECENT SEARCH
                      </span>
                      {recentLocationSearch &&
                        recentLocationSearch
                          .reverse()
                          .slice(0, 3)
                          .map((item: any, index: number) => (
                            <div
                              key={index}
                              onClick={() => addressClick(item, "recent")}
                              className="saved_address_web"
                            >
                              <IonIcon
                                className="ion_web_icon saved_address_icon_web mb-0"
                                icon={locationOutline}
                              />
                              <div className="ionside_part">
                                <p className="location_text">
                                  {item.city}, {item.country}
                                  <span className="address_line1">
                                    {item.line1}
                                  </span>
                                </p>
                              </div>
                            </div>
                          ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LocationSidebar;
