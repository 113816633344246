import React, { useState } from "react";
import { IonContent, IonCol, IonButton } from "@ionic/react";
import SideBar from "../SideBar/SideBar";
import WebHeader from "../WebHeader/WebHeader";

import Profile from "../../Profile/Profile";
import styled from "styled-components";

const DivPadding = styled.div`
  padding-top: 0px;
  padding-left: 13rem;
  padding-right: 0px;
`;
const Div = styled.div`
  padding-top: 0px;
`;
const DivHeight = styled.div`
  overflow: hidden;
  height: 88vh;
  margin-top: 1rem;
`;
const ProfileWeb: React.FC = () => {
  const [filterDate, setFilterData] = useState("");
  const [addItems, setAddItems] = useState<any>({});
  const [sideBarOpen, setSideBarOpen] = useState(false);
  return (
    <>
      <div>
        <WebHeader
          sideBarOpen={[sideBarOpen, setSideBarOpen]}
          setAddItems={setAddItems}
          filterData={[filterDate, setFilterData]}
        />
        <div className="web_view_main_content">
          <SideBar />
          <DivPadding className="web_view_impact_content web_view_top">
            <DivHeight>
              <Profile />
            </DivHeight>
          </DivPadding>
          <Div className="web_view_upcoming_picksup web_view_top"></Div>
        </div>
      </div>
    </>
  );
};

export default ProfileWeb;
