import React, { useState } from "react"
import ApiRequest from "../../Services/Api.Service";
import { userProfileStore } from "../../Store/createStore";
import CustomInput from "./CustomInput"
import { IonButton, IonIcon, IonSpinner, IonToast } from "@ionic/react";
import { useForm } from "react-hook-form";
import { TrackEvent } from "Services/TEvents.Service";


const FirstLoginView = (props: any) => {

    const { user, onSuccess } = props;
    const { register, handleSubmit, formState: { errors }, clearErrors }: any = useForm();
    const [selectCategory, setSelectCategory] = useState("household");
    const [err, setErr] = useState({ status: false, message: "" });
    const [loadingSpin, setLoadingSping] = useState(false);
    const { setUserProfile } = userProfileStore()

    const onSubmit = (data: any) => {

        

        if (selectCategory == "household") data.is_enterprise = false
        else data.is_enterprise = true

        const totalData = { ...data };
        setLoadingSping(true);

        TrackEvent("user_signup",{
            name: data.name, 
            email: data.email,
            pincode: data.pincode,
        })

        ApiRequest("PUT", "v1/users/profile", {
            isAuthenticated: false,
            token: user.token,
            body: totalData,
        }).then((res: any) => {
            setLoadingSping(false);
            if (res.success === true) {
                setUserProfile(res.data)
                onSuccess();
            } else {
                setErr({ status: true, message: res.message });
            }
        });
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="webview_number_input_box">
                    <CustomInput
                        clearErrors={clearErrors}
                        name='name'
                        type='text'
                        placeholder="Name"
                        register={register}
                        errors={errors}
                        required={true}
                    />
                </div>

                <div className="webview_number_input_box last_child sign_up_middle_child">
                    <CustomInput
                        clearErrors={clearErrors}
                        name='pincode'
                        type='text'
                        placeholder="Pincode"
                        register={register}
                        errors={errors}
                        required={true}
                        maxLength={6}
                    />
                </div>
                <div className="webview_number_input_box sign_up_middle_child">
                    <CustomInput
                        clearErrors={clearErrors}
                        name='email'
                        type='text'
                        placeholder="Email"
                        register={register}
                        errors={errors}
                        required={false}
                        optional={true}
                    />
                </div>
                <div className="category">
                    <h6>CATEGORY THAT BEST DESCRIBES YOU?</h6>
                    <div className="category_box">
                        <div className="category_box_box">
                            <img
                                className={
                                    selectCategory === "household"
                                        ? "selected_icon"
                                        : "non_selected_icon"
                                }
                                onClick={() => setSelectCategory("household")}
                                src="https://ikp.edgekit.net/y8s2vhk66ef/household_coloured1__R95zz9GH.png"
                                alt=""
                            />
                            <p
                                className={
                                    selectCategory === "household"
                                        ? "selected_text"
                                        : "non_selected_text"
                                }
                            >
                                HOUSEHOLD
                            </p>
                        </div>
                        <div className="category_box_box">
                            <img
                                className={
                                    selectCategory === "enterprise"
                                        ? "selected_icon"
                                        : "non_selected_icon"
                                }
                                onClick={() => setSelectCategory("enterprise")}
                                src="https://ikp.edgekit.net/y8s2vhk66ef/enterprise_bw1_RZxZ80dRv.png"
                                alt=""
                            />
                            <p
                                className={
                                    selectCategory === "enterprise"
                                        ? "selected_text"
                                        : "non_selected_text"
                                }
                            >
                                ENTERPRISE
                            </p>
                        </div>
                    </div>
                </div>
                {err.status === true && (
                    <p className="sign_resend_code invalid">{err.message}</p>
                )}

                <IonButton className="login_btn_web_view" color="success" type="submit">
                    {loadingSpin ? <IonSpinner name="lines" /> : "Next"}
                </IonButton>
            </form>
        </>
    );
};

export default FirstLoginView