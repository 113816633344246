import React, { useEffect, useMemo, useState } from "react";
import HeaderLocal from "Components/Header/HeaderLocal";
import "./LocalHome.css";
import LocalLogin from "./LocalLogin/LocalLogin";
import { callOutline, mailOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import useLocalStorage from "Services/useLocalStorage";
import { useHistory, useLocation } from "react-router";
import Config from "../../Config";
import { userProfileStore } from "Store/createStore";
import { TrackEvent } from "Services/TEvents.Service";
import { useQuery } from "react-query";
import ApiRequest from "Services/Api.Service";

const HOME_DATA = {
  TESTIMONIALS: [
    {
      avatar:
        "https://lh3.googleusercontent.com/a-/AOh14GiaQhuPvr5ZE-qavJR_5W_GpbA_mBjuEDnfn6p3=w60-h60-p-rp-mo-br100",
      link: "https://goo.gl/maps/nL8KmTZNfbSJJMpFA",
      name: "Sudip Chatterjee",
      review:
        "The Swapeco Team was really professional and their services the best that I have seen in my entire life of 45 years. Could not have asked anything more. Simply awesome 👏",
    },
    {
      avatar:
        "https://lh3.googleusercontent.com/a-/AOh14Gikx9QkqnN5C6eGtKJLQFfHnNO1-Xbp00RuhV4BLw=w36-h36-p-rp-mo-br100",
      link: "https://goo.gl/maps/cW2dJqErk9LfuZje9",
      name: "Akash Pandey",
      review: `It was such a pleasant experience with Swapeco, the process was so simple, i just simply filled the details online and selected the date and time for pickup, the concerned person contacted me and reached at the desired time, he carried the weighing machine and bags to collect the scrap, Best part is that the weighing machine ... [[SEE_MORE]]
      `,
    },
    {
      avatar:
        "https://lh3.googleusercontent.com/a/AATXAJy2MUY0DTrh51qOOsqKEEppsqghzsS4iyIHzO_l=w36-h36-p-rp-mo-ba4-br100",
      link: "https://goo.gl/maps/aU5RmumGeC9TxHFGA",
      name: "Paritosh Vinod",
      review:
        "Was fed up of our local society kabadiwala who did as per his own whim bcoz of the monopoly.. tried swapeco and found the service to be smooth, transparent, convenient and extremely professional.. great initiative.. will surely use again and recommend to all in Delhi NCR..",
    },
    {
      avatar:
        "https://lh3.googleusercontent.com/a-/AOh14GjIHLsxsvDS35GIu9GQvAzyup8KN8uX11Lg_j8m9KQ=w36-h36-p-rp-mo-br100",
      link: "https://goo.gl/maps/AwomTRtLQQEDLTBA6",
      name: "Zoya Wahi",
      review:
        "Extremely prompt service. Very polite customer interhelper- and the collection team was thorough, helpful and very neat. Really appreciate the work put in.",
    },
    {
      avatar:
        "https://lh3.googleusercontent.com/a/AATXAJzVtCMrK6AVvgasC_bUQWsJisEoYn9JossFotNP=w36-h36-p-rp-mo-br100",
      link: "https://goo.gl/maps/SKHLwuwypxYpvMbL6",
      name: "Preeti Verma",
      review:
        "Hassel free good experience with Swapeco. Home pickup service is very convenient, and a great initiative to save the environment. Will surely recommend to others.",
    },
    {
      avatar:
        "https://lh3.googleusercontent.com/a-/AOh14GiAc-46qdOnP9ynB5FSAiANASpsspKVWUoM0ZTljg=w36-h36-p-rp-mo-br100",
      link: "https://goo.gl/maps/eLTJhgivnEAEbzWF7",
      name: "Pushkin Gupta",
      review:
        "I have used swapeco twice for scrap collection. It is easy to schedule your request via website, collection was on time and they offer great rates.",
    },
    {
      avatar:
        "https://lh3.googleusercontent.com/a-/AOh14GjpNqL3dNfJq_Pv07PuLD6tnMsBpx8q-ho490RirA=w36-h36-p-rp-mo-br100",
      link: "https://goo.gl/maps/PNFzjrw82ktpUfY57",
      name: "Prince Madan",
      review:
        " Very good app to sell your scrap, instant payment, and on-time pickup, good customer support..keep rising 👍🏻.. ",
    },
    {
      avatar:
        "https://lh3.googleusercontent.com/a-/AOh14GinljIE8VBYb3GzRghvQCO7qVhrdwTxV_xUQMto=w36-h36-p-rp-mo-br100",
      link: "https://goo.gl/maps/oEL2pf6nnFd5ezkMA",
      name: "Aman Sapra",
      review:
        " Well behaved staff and I m so glad recycling is happening the right way not just the usual Kabadi waala ",
    },
    // {
    //   avatar: '',
    //   link: '',
    //   name: '',
    //   review:'',
    // },
  ],
  ADVANTAGES: {
    HOUSEHOLDS: [
      "On-Demand Doorstep Pickups",
      "Accurate Digital Weighing",
      "Safety (Trained & Verified Staff)",
    ],
    BUSINESS: [
      "Formal Payments & Documentation",
      "Sustainability Reports",
      "Competitive Prices",
    ],
  },
};

const BlogPosts = () => {
  const {data, status, isLoading} = useQuery("allBlogs", ()=>ApiRequest("GET","v1/meta/blogs"))
  
  const recentPosts = useMemo(()=>{
    
    if(data) {
      console.log('BlogAPIKey', data[0]?.yoast_head_json?.og_image[0]?.url)
      return data.slice(0,3)
    }
    return []
  },[data])

  useEffect(()=>{
    console.log({recentPosts})
  },[recentPosts])


  return (
    <>
      <section className="blog-posts">
        {recentPosts.length > 0 && <h2 className="testimonials_title">Our Blog</h2>}
        <div className="blog-grid">
          {recentPosts.map((post: any) => {
            return (
              <div className="blog-post-item" key={post.id}>
                <div
                  className="blog-featured-image"
                  style={{ backgroundImage: `url(${post.yoast_head_json?.og_image[0]?.url})` }}
                ></div>
                <div className="blog-item-content">
                  <h3>{post.title.rendered}</h3>
                  <p
                    className="blog-meta"
                    dangerouslySetInnerHTML={{
                      __html: post?.excerpt?.rendered,
                    }}
                  ></p>
                  <a
                    className="blog-read-more"
                    href={post.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More »
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

const LocalHome: React.FC = () => {
  let { state } = useLocation();
  const [loginShow, setLoginShow] = useState<any>("phone");
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  const history = useHistory();
  const { userProfile } = userProfileStore()
  const [scheduleErr, setScheduleErr] = useState({ status: false, msg: '' });

  useEffect(() => {
    console.log("Current Hash", window.location.hash);
    if (window.location?.hash == "#schedulePickup") {
      setTimeout(() => {
        // @ts-ignore
        document
          .querySelector(".login-mobile-box")
          .scrollIntoView({ block: "start", behavior: "smooth" });
        const navMenu = document.querySelector(".navbar-collapse");
        if (!navMenu?.classList.contains("collapse"))
          navMenu?.classList.add("collapse");
      }, 500);

      // // @ts-ignore
      // var element = document.querySelector(".login_form_open");
      // var headerOffset = 45;

      // // @ts-ignore
      // var elementPosition = element?.getBoundingClientRect().top;

      // // @ts-ignore
      // var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      // window.scrollTo({
      //   top: offsetPosition,
      //   behavior: "smooth",
      // });
    }
  }, [window.location.hash]);


  useEffect(() => {
    if (state !== undefined && state === "whyus") {
      let documents: any = document;
      documents.getElementById("whyus").scrollIntoView({ behavior: "smooth" });
    }
  }, [state]);
  useEffect(() => {
    if (loggedInUser.name || loggedInUser.token) {
      setLoginShow("first_login");
      TrackEvent('Session_started', {phone: loggedInUser?.phone})
    }
  }, [loggedInUser]);

  useEffect(()=>{
    const helperAction = document.querySelector('.helper-action');
    helperAction?.addEventListener('click', ()=> {
      helperAction?.classList.toggle('active')
    })
  },[])

  const gotoSchedule = () => {
    if (loggedInUser) {
      history.push('/w/schedule-pickup')
    } else {
      setScheduleErr({ status: true, msg: 'Please enter the phone number' })
    }
  }

  return (
    <div className="local_home_content style-2">

      {/* <button onClick={gotoSchedule} className="schedule-pickup-btn">Schedule a Pickup</button> */}

      <div className="local_home_div">
        <HeaderLocal
          show={{ ul: true, location: true, search: false, profile: false }}
        />

        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 local_home_leftSide  mt-5 pt-5">
              <h1 className="local_home_title">
                Sell your recyclables <br />
                online with <span className="highlight">ScrapUncle!</span>
              </h1>
              <div className="local_home_sub_title">
                {/* Get your scrap recycled with ScrapUncle's on-demand doorstep pickups! */}
                <span className="local_home_pickup_items">
                  Paper - Plastics - Metals - Appliances{" "}
                </span>
                <p className="download_app_text">
                  You can also download the
                  <a
                    href="https://play.google.com/store/apps/details?id=com.swapeco.app"
                    style={{ fontWeight: 700, color: "#0072c6" }}
                  >
                    {" "}
                    ScrapUncle App
                  </a>
                </p>
                <br />
              </div>
              <img
                style={{
                  maxHeight: "200px",
                  width: "auto",
                  marginTop: "10px",
                  marginLeft: "50px",
                }}
                src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/assets/ScrapuncleHomeBanner_ky5a6zP3j.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663270651534"
                alt='assets'
              />
            </div>
            <div className="col-lg-6 login-mobile-box" id="schedulePickup">
              <div style={loginShow === 'first_login' ? { height: '100vh' } : { height: '75vh' }} id="SchedulePickup" className="local_home_rightSide">
                <div
                  className={
                    loginShow === "" ? "newUser_oldUser_div" : "login_form_open"
                  }
                >
                  {(loginShow === "phone" ||
                    loginShow === "otp" ||
                    loginShow === "update_profile" ||
                    loginShow === "first_login") && (
                      <LocalLogin setScheduleErr={setScheduleErr} scheduleErr={scheduleErr} loginShow={[loginShow, setLoginShow]} />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* overlay section */}

      <section className="swapeco_received_overlay">
        <img src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/Shark_Tank_1__b25EEhiRuH.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675293031804" className="sharktank-banner" />
        <br />
        <br />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h2 className="received_title">
                Scrapuncle received Mbillionth Award 2020
              </h2>
              <p className="received_text">
                Recognized as the winner in the "Early Stage Startups" category
                of the Mbillionth awards South Asia organized by Digital
                Empowerment Foundation (DEF) & Facebook
              </p>
            </div>
            <div className="col-lg-6 col-md-12 award-slider-container">

              <img src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/awards/mbillionth.jpeg_NccYSt1oMdSD.jpeg" alt='assets' />
            </div>
          </div>
        </div>
      </section>

      <section className="how_works">
        <div className="container text-center">
          <h2 className="how_works_title">How it works</h2>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="how_works_col">
                <h4>Schedule a pickup</h4>
                <img
                  src="https://ikp.edgekit.net/y8s2vhk66ef/undraw_Online_calendar_re_wk3t_1_SHrgqjm1w6l.png?updatedAt=1628624813421"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="how_works_col">
                <h4>Pickup at your address</h4>
                <img
                  src="https://ikp.edgekit.net/y8s2vhk66ef/undraw_Street_food_re_uwex_1_tHCc3auJgJY.png?updatedAt=1628624814853"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="how_works_col">
                <h4>Receive payment</h4>
                <img
                  src="https://ikp.edgekit.net/y8s2vhk66ef/undraw_Credit_card_payments_re_qboh_1_Sop8u3hvUiX.png?updatedAt=1628624812461"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="whyus" className="why_us">
        <div className="container text-center">
          <h2 className="header--why-us">Why us</h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xl-3">
              <div className="why_us_col">
                <img
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/why-us/rupee_h5cohN94jkyh.png"
                  alt="Best Rates"
                />
                <div>
                  <h4>Best Rates</h4>
                  <p>
                    We provide the best value for your scrap from our network of
                    Recyclers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xl-3">
              <div className="why_us_col">
                <img
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/why-us/thumbs-up_vOIp-YChzZhh.png"
                  alt="Convenience"
                />
                <div>
                  <h4>Convenience</h4>
                  <p>
                    Doorstep pickup according to user's convenient date & time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xl-3">
              <div className="why_us_col">
                <img
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/why-us/trust_TmQdK2fLBVD.png"
                  alt="why_us_image"
                />
                <div>
                  <h4>Trust</h4>
                  <p>
                    Trained & Verified Pickup Staff with Swapeco Smart Weighing
                    Scale
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xl-3">
              <div className="why_us_col">
                <img
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/why-us/eco_wwfqNtl3n-r.png"
                  alt="why_us_image"
                />
                <div>
                  <h4>Eco-friendly</h4>
                  <p>We ensure responsible recycling of your scrap items. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="deferent_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h2 className="deferent_title">
                    Households & Businesses have different needs and we cater to
                    each of them
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row f-sm-column-reverse">
            <div className="col-md-6">
              <div className="content-holder">
                <p className="deferent_sub_title">For Households </p>
                <ul className="deferent_ul">
                  {HOME_DATA.ADVANTAGES.HOUSEHOLDS.map((item, index) => (
                    <li key={index}>
                      <img src="https://ik.imagekit.io/99x/startup/99xCheck_S1K62CB8I.svg" alt='assets' />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6 mb-4 text-center">
              <img
                className="deferent_image"
                src="https://ikp.edgekit.net/y8s2vhk66ef/home_illust_1_vqbxsy5za.png?updatedAt=1628667425589"
                alt="defent_image"
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <div className="col-md-6 text-center">
              <img
                className="deferent_image_household"
                src="https://ikp.edgekit.net/y8s2vhk66ef/business_illust1_1_UjVieCFRixz.png?updatedAt=1628667425278"
                alt="defent_image"
              />
            </div>
            <div className="col-md-6">
              <div className="content-holder">
                <p className="deferent_sub_title">For Businesses </p>
                <ul className="deferent_ul">
                  {HOME_DATA.ADVANTAGES.BUSINESS.map((item, index) => (
                    <li key={index}>
                      <img src="https://ik.imagekit.io/99x/startup/99xCheck_S1K62CB8I.svg" alt='assets' />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="brands">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="brands_title">Leading brands trust ScrapUncle</h2>
              {/* <p className='brands_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent maecenas feugiat facilisis aliquam congue parturient. Felis convallis natoque at etiam. A urna netus imperdiet sit. Ligula interdum tortor tellus, posuere non tincidunt vulputate est, placerat. Dapibus ultrices volutpat lorem </p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="brands_second_row">
                <img
                  className="brands_image"
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/Godrej_YFed2weyc.png"
                  alt="Godrej"
                />
                <img
                  className="brands_image"
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/IIITD_W2Vm8BwJ-.png"
                  alt="IIIT-Delhi"
                />
                <img
                  className="brands_image"
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/Screenshot_2021-12-10_at_06.00.24_5atFfjtN1s1.png"
                  alt="DS Group"
                />
                <img
                  className="brands_image"
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/Li_and_Fung__oRk7-0rOeg.png"
                  alt="LI & Fung"
                />
                <img
                  className="brands_image"
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/Tata_Steel_t8NNDeFISKC.png"
                  alt="Tata Steel"
                />
                <img
                  className="brands_image"
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/Thermax_SNEUT74wiO.png"
                  alt="Thermax"
                />
                <img
                  className="brands_image"
                  src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/UrbanCompany_xDr91X1UceL.png"
                  alt="UrbanCompany"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="testimonial_nd_footer">
        <section className="testimonials">
          <div className="container text-center">
            <h2 className="testimonials_title">Testimonials</h2>
            <div className="row">
              {HOME_DATA.TESTIMONIALS &&
                HOME_DATA.TESTIMONIALS.map((t, i) => {
                  let review = t.review;
                  review = review.replace(
                    "[[SEE_MORE]]",
                    `<a href="${t.link}" target="_blank">See Full</a>`
                  );
                  return (
                    <div className="col-md-4 col-sm-6 col-lg-3" key={i}>
                      <div className="testimonial_content_box">
                        <div className="testimonial_content_title">
                          <img src={t?.avatar} alt='avatar' />
                          <span>{t.name}</span>
                        </div>
                        <div className="testimonial_content_text_container">
                          <span className="first_">“</span>
                          <p
                            className="testimonial_content_text"
                            dangerouslySetInnerHTML={{ __html: review }}
                          ></p>
                          <span className="second_">”</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>

        <section>
          <BlogPosts />
        </section>

        <footer className="footer_section">
          <div className="container footer_container">
            <div className="row footer_padding">
              <div className="col-md-4">
                <p className="contact_us">Contact Us</p>
                <p>
                  <IonIcon icon={callOutline} /> &nbsp; 
                    <a href={`tel:+91${Config.CONTACT.SUPPORT_PHONE}`}>+91 {Config.CONTACT.SUPPORT_PHONE}</a>
                </p>
                <p>
                  <IonIcon icon={mailOutline} /> &nbsp; {Config.CONTACT.SUPPORT_EMAIL}
                </p>
                <p style={{ fontWeight: 700 }}>
                  Customer Support available <br />
                  from 10am - 7pm
                </p>

                <p className="find_us">Find Us Here</p>
                <ul className="find_us_ul">
                  <li>
                    <a
                      href="https://www.instagram.com/scrapuncle/"
                      target="_blank"
                      rel='noreferrer'
                    >
                      <img
                        src="https://ikp.edgekit.net/y8s2vhk66ef/image_2_Bi5cqcyBFNT.png?updatedAt=1628624823915"
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li>

                    <a href="https://twitter.com/scrapuncleapp" target="_blank" rel='noreferrer' >
                      <img
                        src="https://ikp.edgekit.net/y8s2vhk66ef/image_3_yByOZld4XFh.png?updatedAt=1628624824789"
                        alt="twitter"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/scrapuncle"
                      target="_blank"
                      rel='noreferrer'
                    >
                      <img
                        src="https://ikp.edgekit.net/y8s2vhk66ef/image_6_zHp_XCLcq9Z.png?updatedAt=1628624826605"
                        alt="linkedin"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/scrapuncle"
                      target="_blank"
                      rel='noreferrer'
                    >
                      <img
                        src="https://ikp.edgekit.net/y8s2vhk66ef/image_5_3EElSEX6sCW.png?updatedAt=1628624825705"
                        alt="facebook"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <p className="contact_us">Download the Scrapuncle App</p>
                <a href={Config.APP_LINK.ANDROID} target="_blank" rel='noreferrer'>
                  <img
                    src="https://ikp.edgekit.net/y8s2vhk66ef/image_1_3f6VbLy6MIW.png?updatedAt=1628624822923"
                    alt="playstore"
                  />
                </a>

                <p className="company">
                  <a href="/local-rate">Scrap Rates</a>
                </p>
                <p className="cursor-pointer">
                  <a href="https://blog.scrapuncle.com" target="_blank" rel='noreferrer'>
                    Blog
                  </a>
                </p>
                {/* <p className='cursor-pointer'>
                  <a href="https://forms.gle/yBmjvvHKXPX1XbmY6" >Franchise</a>
                </p> */}
              </div>
              <div className="col-md-4 footer-address">
                <img
                  className="logo_class"
                  src="https://ik.imagekit.io/99x/_/scrapuncle/ScrapUncle-Logo-RGB-White_1__Vrw87qAXz.png"
                  alt="Scrapuncle"
                />
                <p className="swapeco">
                  <b>Swapeco Solutions Private Limited </b>
                </p>
                <p style={{ display: "block" }}>
                  <b>Regd. Address:</b> <br />
                  C-171, Phase-I, Okhla Industrial Estate Phase 1, New Delhi - 110020
                </p>
                <p style={{ display: "block" }}>
                  <br />
                  <b>Corr Address:</b>
                  <br /> Incubation Center, IIIT Delhi, Okhla Phase 3, New Delhi
                  - 110020
                </p>
              </div>
            </div>
          </div>

          <div className="helper-action active">
            <button>+</button>
            <ul>
              <li>
                <a href={`tel:+91${Config.CONTACT.SUPPORT_PHONE}`}>
                  <img src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/assets/telephone-call_0Etr04ShlH.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675339109083" style={{background:'#fff',borderRadius:'100%'}} /></a>
              </li>
              <li><a href={`https://wa.me/91${Config.CONTACT.SUPPORT_PHONE}`}><img src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/assets/whatsapp_VhssjIqsN5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675339111494" /></a></li>
            </ul>
          </div>

        </footer>
      </div>
    </div>
  );
};

export default LocalHome;
