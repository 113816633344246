import React, { useState } from "react";
import HeaderLocal from '../../Header/HeaderLocal';
import "./LocalRate.css";
import LocalRateItem from "./LocalRateItem";
import Config from "Config";

const LocalRate: React.FC = () => {
  const [addItems, setAddItems] = useState<any>({});
  const [search, setSearch] = useState("");

  return (
    <div className="local_rate_div">
      <HeaderLocal setSearch={setSearch} show={{ ul: true, location: false, search: true, profile: false }} />
      {/* <a href="/w/schedule-pickup">
        <button className="schedule-pickup-btn">Schedule a Pickup</button>
      </a> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <LocalRateItem
              popUp={true}
              setAddItems={setAddItems}
              searchData={[search, setSearch]}
            />
          </div>
        </div>
        <div className="helper-action active">
            <button>+</button>
            <ul>
              <li>
                <a href={`tel:+91${Config.CONTACT.SUPPORT_PHONE}`}>
                  <img src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/assets/telephone-call_0Etr04ShlH.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675339109083" style={{background:'#fff',borderRadius:'100%'}} /></a>
              </li>
              <li><a href={`https://wa.me/91${Config.CONTACT.SUPPORT_PHONE}`}><img src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/assets/whatsapp_VhssjIqsN5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675339111494" /></a></li>
            </ul>
          </div>
      </div>
    </div>
  );
};

export default LocalRate;
