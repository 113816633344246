import { IonButton, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import "./SignUpOTP.css";
import styled from "styled-components";
import ApiRequest from "../../Services/Api.Service";
import { UserProfile } from "../../App";
import useLocalStorage from "Services/useLocalStorage";

const Img = styled.img`
  margin-top: 0px;
`;
type Inputs = {
  example: string;
  exampleRequired: string;
};

const SignUpDetails: React.FC = () => {
  const [selectName, setSelectName] = useState("household");
  const history = useHistory();
  const { register, handleSubmit }: any = useForm<Inputs>();
  const [category, setCategory] = useState("household");
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const onSubmit = (data: any) => {
    ApiRequest("PUT", "/profile/", {
      isAuthenticated: false,
      token: profile.token,
      body: data,
    }).then((res) => {
      if (res.success === true) {
        setLoggedInUser({ ...loggedInUser, ...profile, ...data });
        setProfile({ ...profile, ...data });
        if (category === "household") {
          history.push("/home");
        }
        if (category === "enterprise") {
          history.push("/bussinessDetails");
        }
        if (category === "recycling") {
          history.push("/home");
        }
      } else {
        alert("Create account failed! Pleace Sign up Again! ");
        setTimeout(() => {
          history.push("/login-page");
        }, 1500);
      }
    });
  };

  const selected = (name: string) => {
    switch (name) {
      case "household":
        setSelectName("household");
        setCategory("household");
        break;

      case "enterprise":
        setSelectName("enterprise");
        setCategory("enterprise");
        break;

      case "recycling":
        setSelectName("recycling");
        setCategory("recycling");
        break;

      default:
        setSelectName("household");
        setCategory("household");
        break;
    }
  };

  return (
    <IonContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ionGrid">
          <IonRow>
            <IonCol>
              <div className="sign_header">
                <h3 className="sign_header_h3">Sign Up</h3>
              </div>
              <p className="sign_header_paragraph">Name</p>
              <input
                {...register('name')}
                className="sign_header_input details"
                type="text"
                name="name"
                id=""
                required
              />

              <p className="sign_header_paragraph">AREA NAME</p>
              <input
                {...register('address')}
                className="sign_header_input details"
                type="text"
                name="address"
                id=""
                required
              />

              <p className="sign_header_paragraph">EMAIL (OPTIONAL)</p>
              <input
                {...register('email')}
                className="sign_header_input details"
                type="email"
                name="email"
                id=""
              />

              <p className="sign_header_paragraph">
                Category that best describes you?
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol onClick={() => selected("household")}>
              <div className="household_box">
                {" "}
                <Img
                  className={
                    selectName === "household"
                      ? "household active"
                      : "household"
                  }
                  src="https://ikp.edgekit.net/y8s2vhk66ef/household_coloured1__R95zz9GH.png"
                  alt=""
                />{" "}
              </div>
              <p
                className={
                  selectName === "household"
                    ? "household_para actives"
                    : "household_para "
                }
              >
                Household
              </p>
            </IonCol>
            <IonCol onClick={() => selected("enterprise")}>
              <div className="household_box">
                {" "}
                <Img
                  className={
                    selectName === "enterprise"
                      ? "household active"
                      : "household"
                  }
                  src="https://ikp.edgekit.net/y8s2vhk66ef/enterprise_bw1_RZxZ80dRv.png"
                  alt=""
                />{" "}
              </div>
              <p
                className={
                  selectName === "enterprise"
                    ? "household_para actives"
                    : "household_para "
                }
              >
                enterprise
              </p>
            </IonCol>
            <IonCol onClick={() => selected("recycling")}>
              <div className="household_box">
                {" "}
                <Img
                  className={
                    selectName === "recycling"
                      ? "household active"
                      : "household"
                  }
                  src="https://ikp.edgekit.net/y8s2vhk66ef/enterprise_bw1_RZxZ80dRv.png"
                  alt=""
                />{" "}
              </div>
              <p
                className={
                  selectName === "recycling"
                    ? "household_para actives"
                    : "household_para "
                }
              >
                recycling hero
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonButton
          className="bottom_Btn"
          type="submit"
          color="success"
          expand="full"
          id="bottom_btn"
        >
          {" "}
          NEXT{" "}
        </IonButton>
      </form>
    </IonContent>
  );
};

export default SignUpDetails;
