import React, { useState, useEffect } from "react"
import dayjs from "dayjs"
import { calendar } from "ionicons/icons";
import { Accordion } from "react-bootstrap";
import ScheduleAccordionTitle from './ScheduleAccordionTitle'
import DatePicker from "react-datepicker";
import { appMetaStore } from "Store/createStore";
import userSchedulePickupStore from "Store/userPickupStore";

const checkAvailable = (availableDates: any[], date: string) => {
  return availableDates.find((item: any) => item.date === date)
}

const CalenderSchedule = ({ setSelectSlot, selectDate, setSelectDate, totalPickupSlots, setPickupSlots, formReset, setFormReset, resetField, clearErrors, errors, setValue, register, availableDates }: any) => {
  // const tomorrowDate = useRef<any>(dayjs().add(1, "days").toDate());
  const { appMeta } = appMetaStore()
  const { setTimeslotOptions } = userSchedulePickupStore()
  const [value, setPickupDate] = useState<any>('');

  useEffect(() => {
    register('pickup_date', { required: true })
  }, [])

  const dateChange = (val: any) => {
    setSelectSlot({ id: null, title: '' })
    setPickupDate(val)

    const completedPickupDate = dayjs(val).format('YYYY-MM-DD')

    let selectedDate = checkAvailable(availableDates, completedPickupDate)
    console.log({ availableDates, completedPickupDate, selectedDate })

    if (selectedDate) {

      const allPickupSlotOptions = appMeta.pickupSlots;
      const availableTimeSlots = []

      for (const [slotId, slotAvailibilityCount] of Object.entries(selectedDate.slots)) {
        console.log({ slotId, slotAvailibilityCount })
        if (slotAvailibilityCount as any > 0) {
          availableTimeSlots.push({
            id: slotId,
            title: allPickupSlotOptions?.find((opt:any) => opt?.id == parseInt(slotId))?.title,
          })
        }
      }

      console.log({availableTimeSlots})
      setTimeslotOptions(availableTimeSlots)

      // console.log({selectDate, completedPickupDate})
      let pickupSlotsFilter: any = [];
      for (const property in selectedDate.slots) {
        const element = selectedDate.slots[property];
        if (element > 0) {
          let findSlot = totalPickupSlots.find((slot: any) => `${slot.id}` === property);
          if (findSlot) {
            pickupSlotsFilter.push(findSlot)
          }
        }
      }
      console.log({ pickupSlotsFilter })
      setPickupSlots(pickupSlotsFilter)
    }

    setSelectDate(completedPickupDate)
    setValue('pickup_date', completedPickupDate)
    clearErrors('pickup_date')
  }

  useEffect(() => {
    if (formReset) {
      setSelectDate('')
      setPickupDate('')
      resetField('pickup_date')
      setFormReset(false)
    }
  }, [formReset])
  const isWeekday = (date: Date) => {
    for (let i = 0; i < availableDates.length; i++) {
      const element = availableDates[i];
      if (new Date(element.date).toDateString() === date.toDateString()) {
        return true
      }
    }
    return false
  }

  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header className=" w-100">
        <ScheduleAccordionTitle
          errors={errors}
          title={selectDate}
          defaultTitle={'DD/MM/YY'}
          icon={calendar}
        />
      </Accordion.Header>
      <Accordion.Body>
        <DatePicker
          className="schedule_calendar"
          selected={value || ''}
          onChange={dateChange}
          startDate={new Date(availableDates[0]?.date || false)}
          inline
          filterDate={isWeekday}
        />
        <Accordion.Header
          className="ok_btn"
        >
          OK
        </Accordion.Header>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default CalenderSchedule