import React, { useState, useEffect } from "react"
import { IonButton, IonIcon, IonSpinner, IonToast } from "@ionic/react";
import {
    alertCircle,
    calendar,
    chevronDown,
    cube,
    helpCircle,
    location,
    time,
    trashOutline,
    arrowBackOutline
} from "ionicons/icons";
import { Accordion } from "react-bootstrap";
import ScheduleAccordionTitle from './ScheduleAccordionTitle'

const EstimatedWeight = ({ weightRange = [], formReset, setFormReset, resetField, clearErrors, errors, setValue, register, }: any) => {

    const [servicable, setServicable] = useState({ status: false, error_message: '' })
    const [selectVal, setSelectVal]: any = useState({ title: '', id: null });

    const validaeWeight = (val: any) => {
        if (!val.servicable) {
            setServicable({ status: true, error_message: val.error_message })
        } else {
            setSelectVal(val)
            setValue('expected_weight', val.title)
            clearErrors('expected_weight')
            setServicable({ status: false, error_message: '' })
        }
    }

    useEffect(() => {
        if (formReset) {
            setServicable({ status: false, error_message: '' })
            resetField('expected_weight')
            setSelectVal({ title: '', id: null })
            clearErrors('expected_weight')
            setFormReset(false)
        }
    }, [formReset])

    useEffect(() => {
        register('expected_weight', { required: true })
    }, [])

    const isLoading = false;

    return (
        <div className="location_tab">
            <div className="weight_select_div">
                <div className="schedule_title">
                    <span className="estimate_title">Estimated Weight </span>
                    {
                        servicable.status &&
                        <div className='minimum_weigth_err_div'>
                            <span className="minimum_weigth_err">
                                <IonIcon
                                    className="alert_icon"
                                    icon={alertCircle}
                                />
                                <span className="alert_message_estimate_weight">
                                    {servicable?.error_message}
                                </span>
                            </span>
                        </div>
                    }
                </div>
                <Accordion.Item eventKey="4">
                    <div style={errors.address_id ? { border: '1px solid red' } : {}} className="address_local calendar_nd_date">
                        <Accordion.Header className="w-100">
                            <ScheduleAccordionTitle
                                title={selectVal.title}
                                defaultTitle={'Select Weight'}
                                icon={cube}
                                customClass="location_ion_icon"
                            />
                        </Accordion.Header>

                        <Accordion.Body>
                            <ul className="local_address_ul">
                                {isLoading ?
                                    <div className="flex justify-center p-2">
                                        <IonSpinner name="lines" />
                                    </div>
                                    :
                                    Array.isArray(weightRange) && !weightRange?.length ? (
                                        <p className="text-center my-2 p-2">No Estimate weight!</p>
                                    ) : (
                                        weightRange.map((item: any) => (
                                            <li
                                                onClick={(e) => validaeWeight(item)}
                                                className={
                                                    selectVal?.id === item?.id
                                                        ? "active local_weight_li"
                                                        : "local_weight_li"
                                                }
                                                key={item.id} value={item.id + '/' + item.title + '/' + item.servicable + '/' + item.error_message || ''}>

                                                {item.title}
                                            </li>
                                        ))
                                    )
                                }
                            </ul>
                        </Accordion.Body>
                    </div>
                </Accordion.Item >

            </div>
        </div>
    )
}

export default EstimatedWeight