import React, { useState, useEffect, useCallback } from "react"
import {
    time,
} from "ionicons/icons";
import { Accordion } from "react-bootstrap";
import ScheduleAccordionTitle from './ScheduleAccordionTitle'
import { appMetaStore } from "../../Store/createStore"
import userSchedulePickupStore from "Store/userPickupStore";

const TimeSchedule = ({ selectSlot, setSelectSlot, selectDate, pickupSlots = [], formReset, setFormReset, resetField, clearErrors, setValue, register }: any) => {
    const [timeSlotShowing, setTimeSlotShow] = useState(false);
    const { appMeta } = appMetaStore()
    const { schedulePickupData } = userSchedulePickupStore()

    const selectTime = useCallback((value:any, timeId: number)=>{
        if(typeof timeId == 'string') timeId = parseInt(timeId)
        window?.dataLayer.push({pickup_time: value})
        setSelectSlot({ id: timeId, title: value })
        setValue('pickup_slot', timeId)
        clearErrors('pickup_slot')
    },[])

    useEffect(() => {
        console.log('AvailableDates', appMeta.availableDates)
    }, [appMeta, selectDate])

    useEffect(() => {
        if (pickupSlots?.length) {
            for (let i = 0; i < pickupSlots.length; i++) {
                const element = pickupSlots[i];
                if (element?.is_active) {
                    setTimeSlotShow(true)
                    break;
                }
            }
        }
        register('pickup_slot', { required: true })
    }, [pickupSlots])

    useEffect(() => {
        if (formReset) {
            setSelectSlot({ id: null, title: '' })
            resetField('pickup_date')
            setFormReset(false)
        }
    }, [formReset])

    return (
        <Accordion.Item eventKey="2">
            <Accordion.Header className=" w-100">
                <ScheduleAccordionTitle
                    title={selectSlot.title}
                    defaultTitle={'Select Time'}
                    icon={time}
                />
            </Accordion.Header>
            <Accordion.Body>
                <div className="time_details">
                    { schedulePickupData.options.timeSlots.length == 0 && <>
                        Please choose a different date.
                    </>
                    }
                    {
                        schedulePickupData.options.timeSlots.map((timeSlot: any, i: any) => (

                            <span
                                key={timeSlot.id}
                                className={`${selectSlot.id === timeSlot.id ? "active_time" : ""}`}
                                onClick={() => selectTime(timeSlot.title, timeSlot.id)}
                            >
                                {timeSlot.title}
                            </span>

                        ))
                    }
                </div>
                <Accordion.Header className="ok_btn">
                    OK
                </Accordion.Header>
            </Accordion.Body>
        </Accordion.Item >

    )
}

export default TimeSchedule