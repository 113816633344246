import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

const appMetaStore = create(devtools((set: any) => ({
    appMeta: {
        impact: {
            recycled: '1,443,515 KG',
            earned: 'Rs 100'
        },
        sliders: [],
        pickupSlots: [
            {
                id: 1,
                title: '11 AM - 3 PM'
            },
            {
                id: 2,
                title: '3 PM - 5 PM'
            }
        ],
        weightRanges: [
            {
                id: 1,
                title: 'Less than 20 Kg',
                servicable: false,
                error_message: "Service unavailable as the minimum weight requirement is of 20 Kg"
            },
            {
                id: 2,
                title: '20-50 Kg',
                servicable: true,
            },
            {
                id: 3,
                title: '50-100 Kg',
                servicable: true,
            },
            {
                id: 4,
                title: '100-700 Kg',
                servicable: true,
            },
            {
                id: 5,
                title: 'More than 700 Kg',
                servicable: true,
            },
        ],
        features: {},
        supportPage: {
            notice1: {
                is_shown: true,
                content: `<p>ScrapUncle is always active in supporting their customers.</p>`
            }
        },
        config: {
            GOOGLE_MAPS_API_KEY: "AIzaSyA2qy2lFASUCsACEu2N8aQyy63O5OkVZok"
        },

        links: {
            otpLess: {
                loginUrl: 'https://scrapuncle.authlink.me'
            },
            aboutPage: {
                title: 'About Scrapuncle',
                href: 'https://scrapuncle.com'
            }
        },

        enabledFeatures: [''],
        availableDates: {} as any
    },
    updateAppMeta: (appMeta: any) => {
        set((state: any) => ({
            appMeta: {
                ...state.appMeta,
                ...appMeta,
                enabledFeatures: Object.entries(appMeta.features).filter(([featureName, isEnabled], index) => isEnabled).map(feature => feature[0])
            },
        }))
    }
})))

const userProfileStore = create(devtools((set: any) => ({
    userProfile: {
        name: '',
        phone: '',
        email: '',
        token: '',
        refer_code: '',
        pincode: '',
        banner: '',
    },
    setUserProfile: (userProfile: any) => {
        set((state: any) => ({
            userProfile,
        }))
    },
    logoutUser: () => {
        set((state: any) => {
            localStorage.clear()
            return {
                userProfile: {
                    name: '',
                    phone: '',
                    email: '',
                    token: '',
                    refer_code: ''
                }
            }
        })
    }
})))

const userLocationStore = create(devtools((set: any) => ({
    targetLocation: {
        id: -1,
        title: '',
        line1: '',
        line2: '',
        houseflatno: '',
        landmark: '',
        pincode: '',
        address: '',
        lat: '',
        lng: '',
        approx_location: {
            lat: -1,
            lng: -1,
            plus_code: {
                compound_code: '',
                global_code: ''
            }
        },
        location: {
            lat: -1,
            lng: -1,
            plus_code: {
                compound_code: '',
                global_code: ''
            }
        }
    },
    redirectTo: '/',
    setTargetLocation: (targetLocation: any) => {
        set((state: any) => ({
            targetLocation,
            redirectTo: state.redirectTo,
        }))
    },
    setAddressAddedRedirect: (redirectTo: any) => {
        set((state: any) => ({
            targetLocation: state.targetLocation,
            redirectTo
        }))
    }
})))

export {
    appMetaStore,
    userProfileStore,
    userLocationStore
}