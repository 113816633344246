import React from "react";
import { IonSkeletonText } from "@ionic/react";

const Skeleton: React.FC = () => {
  return (
    <>
      <div className="ion-padding custom_skeleton_div">
        <div className="left_box"></div>
        <div className="skeleton">
          <IonSkeletonText animated />
          <IonSkeletonText animated style={{ width: "95%" }} />
          <IonSkeletonText animated style={{ width: "30%" }} />
          <IonSkeletonText animated style={{ width: "20%" }} />
        </div>
      </div>
      <div className="ion-padding custom_skeleton_div">
        <div className="left_box"></div>
        <div className="skeleton">
          <IonSkeletonText animated />
          <IonSkeletonText animated style={{ width: "95%" }} />
          <IonSkeletonText animated style={{ width: "30%" }} />
          <IonSkeletonText animated style={{ width: "20%" }} />
        </div>
      </div>
      <div className="ion-padding custom_skeleton_div">
        <div className="left_box"></div>
        <div className="skeleton">
          <IonSkeletonText animated />
          <IonSkeletonText animated style={{ width: "95%" }} />
          <IonSkeletonText animated style={{ width: "30%" }} />
          <IonSkeletonText animated style={{ width: "20%" }} />
        </div>
      </div>
    </>
  );
};

export default Skeleton;
