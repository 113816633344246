import { useIonToast } from "@ionic/react";
import React, { useEffect, useContext, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { UserProfile } from "../../App";
import useLocalStorage from "../../Services/useLocalStorage";
import ApiRequest from "../../Services/Api.Service";
import { useHistory, useLocation } from "react-router";
import FirstLoginView from "Components/Micro/FirstLogin";

const AuthCallback: React.FC = () => {
    const location = useLocation()
    const history = useHistory()
    const waId = useMemo(() => {
        const xUrl = new URL(`http://localhost/callback${location.search}`)
        const otpLessWaId = xUrl.searchParams.get('waId')
        return otpLessWaId
    }, [location.search])
    const [isSignupUser, setIsSignUpUser] = useState(false)

    const [present] = useIonToast()

    const [profile, setProfile] = useContext<any>(UserProfile);
    const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
        "LoginUser",
        ""
    );

    const { mutateAsync: loginWithOtpLess, isSuccess, data: authResponse } = useMutation((waId: string) => {
        return ApiRequest("POST", "v1/users/auth/verify", {
            body: {
                phone: '9999999999',
                code: 'XXXX',
                auth_provider: 'otpless',
                auth_data: waId
            }
        }).then(res => {
            if (!res.success) throw new Error(res.message)
            return res.data
        })

    }, {
        mutationKey: 'loginWithOtpess',
        onSuccess: (successRes: any) => {
            const timeNow = Math.floor(+new Date() / 1000);
            const authToken = successRes.token;
            const userProfile = successRes.user;
            const isFirstLogin = successRes.first_login
            const authExpiry = successRes.expiry
            setProfile(userProfile)
            setLoggedInUser({ ...userProfile, token: authToken, first_login: isFirstLogin, expiry: authExpiry })
            if (!isFirstLogin) history.push("/w/schedule-pickup");
            else setIsSignUpUser(true)
        },
        onError: (errorMessage: any) => {
            present({
                message: 'Error:' + errorMessage.message,
                duration: 2000,
                position: 'bottom'
            })
            history.push("/")
        },
        retry: 1,
    })

    useEffect(() => {
        if (waId && waId.length > 0) loginWithOtpLess(waId)
    }, [waId])

    return (<>
        {!isSignupUser && <div>Please Wait! Logging In</div>}

        {isSignupUser && <>
            <div style={{ padding: '10px' }}>
                <h2>Signup</h2>
                <FirstLoginView 
                user={loggedInUser}
                onSuccess={() => {
                    history.push("/w/schedule-pickup")
                }} />
            </div>
        </>}

    </>)
}

export default AuthCallback