import React, { useContext, useEffect, useRef, useState } from "react";
import "./SearchAddress.css";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IonLoading, IonToast } from "@ionic/react";
import { Geolocation, Geoposition } from "@ionic-native/geolocation";
import PlacesAutocomplete from "../PlacesAutocomplete";
import { UserLocation } from "../../App";
import Geocode from "react-geocode";
import { GOOGLE_MAP } from "Config";
import useLocalStorage from "Services/useLocalStorage";

interface LocationError {
  showError: boolean;
  message?: string;
}

const SearchAddress: React.FC = (props) => {
  const history = useHistory();
  const { state } = useLocation();
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<LocationError>({ showError: false });
  const [position, setPosition] = useState<Geoposition>();
  const [recentLocationSearch, setRecentLocationSearch] = useLocalStorage<any>(
    "recentLocationSearch",
    []
  );
  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  Geocode.setApiKey(GOOGLE_MAP.GOOGLE_MAP_KEY);
  Geocode.setLanguage("en");
  const getLocation = async () => {
    setLoading(true);
    try {
      const position = await Geolocation.getCurrentPosition();
      setPosition(position);
      setLoading(false);
      setError({ showError: false, message: undefined });
      const lat = position.coords.latitude.toString();
      const logss = position.coords.longitude.toString();
      Geocode.fromLatLng(lat, logss).then(
        (response: any) => {
          const address = response.results[0].formatted_address;
          let city, states, country, pincode;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  states = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
                case "postal_code":
                  pincode = response.results[0].address_components[i].long_name;
                  break;
                default:
              }
            }
          }
          setRecentLocationSearch([
            ...recentLocationSearch.slice(0, 3),
            {
              line2: address,
              locality: city,
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              city,
              state: states,
              pincode,
              country,
            },
          ]);
          setTargetLocation({
            ...targetLocation,
            line2: address,
            locality: city,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            city,
            state: states,
            pincode,
            country,
          });

          if (_isMounted.current) {
            history.push({ pathname: "/mapaddress", state: state });
          }
        },
        (error: any) => {
          console.error(error);
        }
      );
    } catch (err) {
      // @ts-ignore
      const message = err?.message ?? "Cannot get user. Check Permission. "
      setError({ showError: true, message });
      setLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonLoading
              isOpen={loading}
              onDidDismiss={() => setLoading(false)}
              message={"Getting Loaction..."}
            />
            <IonToast
              isOpen={error.showError}
              message={error.message}
              onDidDismiss={() =>
                setError({ message: undefined, showError: false })
              }
              duration={3000}
            />

            <IonCol className="d-flex-header-twopage arrawSearch">
              <Link to="/home">
                <img
                  className="arrawIcon search"
                  src="https://ikp.edgekit.net/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
                  alt=""
                />
              </Link>
            </IonCol>

            <PlacesAutocomplete
              states={state}
              getLocation={getLocation}
              position={position}
            />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SearchAddress;
