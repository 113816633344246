import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { UserProfile } from "../../App";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";
import "./SignUpOTP.css";
import { TrackEvent } from '../../Services/TEvents.Service'

type Inputs = {
  example: any;
  exampleRequired: any;
};

const SignUpOTPVerification: React.FC = () => {
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [timeOut, setTimeOut] = useState(false);
  const history = useHistory();
  const { handleSubmit }: any = useForm<Inputs>();
  const location = useLocation<any>();
  const [phone, setPhone] = useState<any>(null);
  const [inValidOtp, setInValidOtp] = useState(false);
  const [invalidOtpMessage, setInvalidOtpMessage] = useState("Invalid OTP Entered")

  const [stateOtp, setStateOtp] = useState({ otp: "" });
  const [loadingSpin, setLoadingSping] = useState(false);
  const [resendCodeStop, setResendCodeStop] = useState(0);
  const [showToast1, setShowToast1] = useState(false);
  // Similar to useState but first arg is key to the value in local storage.
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );

  useEffect(() => {
    const phones = location.state.phone;
    setPhone(phones);
  }, [location]);

  // WEB OTP CODE HERE !!
  // useEffect(()=>{
  //   if ('OTPCredential' in window) {
  //     window.addEventListener('DOMContentLoaded', e => {
  //     const ac = new AbortController();
  //     navigator.credentials.get({
  //     otp:<> { transport:['sms'] },
  //     signal: ac.signal
  //     }).then(otp => {
  //     alert(otp.code)
  //     }).catch(err => {
  //     });
  //     })
  //     } else {
  //     alert('WebOTP not supported!.')
  //     }
  // },[])

  const onSubmit = () => {
    const codes = stateOtp.otp;
    const phoneCode = { phone: phone, code: codes };
    setLoadingSping(true);
    if (stateOtp.otp.length === 4) {
      ApiRequest("POST", "/auth/verify", { body: phoneCode }).then((data) => {
        if (data.success === true) {
          ApiRequest("GET", "/profile/", {
            isAuthenticated: false,
            token: data.token,
          }).then((resData) => {
            if (resData.success === true) {

              TrackEvent('Session_started', { phone: data?.phone })
              const dataInfo = resData.data[0];
              setLoggedInUser({ ...loggedInUser, ...dataInfo, ...data });
              setProfile({ ...profile, ...dataInfo, ...data });
              setShowToast1(true);
              setLoadingSping(false);
              setInValidOtp(false);

              setTimeout(() => {
                if (data.first_login) {
                  history.push("/auth/signup");
                } else {
                  history.push("/home");
                }
              }, 2000);
            }
          });
        } else {
          setInValidOtp(true);
          setInvalidOtpMessage(data.message || "Invalid OTP")
          setLoadingSping(false);
        }
      });
    } else {
      setInValidOtp(true);
      setLoadingSping(false);
    }

    // history.push('/signupdetails')
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds === 1 && minutes === 0) {
        setTimeOut(true);
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const resendCode = () => {
    setMinutes(1);
    setResendCodeStop(resendCodeStop + 1);
    setTimeOut(false);
    const phoneOtp = { phone: phone };

    ApiRequest("POST", "/auth/re-send", { body: phoneOtp }).then((data) => { });
  };

  const handleChange = (otp: any) => {
    setStateOtp({ otp: otp });
    setInValidOtp(false);
  };

  //Web OTP api Code

  //  useEffect(()=>{
  //   if ('OTPCredential' in window) {
  //     const input = document.querySelector('.input_get_otp');
  //     navigator.credentials.get({
  //       otp: { transport:['sms'] },
  //     }).then(otp => {
  //     }).catch(err => {
  //     });
  //   }
  //  },[])

  return (
    <IonContent>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Login Successfully"
        duration={700}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ionGrid">
          <IonRow>
            <IonCol>
              <div className="sign_header">
                <h3 className="sign_header_h3">Verification Code</h3>
              </div>
              <p className="sign_header_paragraph">
                Enter verification code we sent to
              </p>
              <p className="sign_header_paragraph m-0">
                +91 {location.state && location.state.phone}
              </p>
              <p className="sign_header_paragraph">OTP</p>

              <OtpInput
                containerStyle="otpInput_main_div"
                value={stateOtp.otp}
                onChange={handleChange}
                inputStyle="input_get_otp"
                numInputs={4}
                isInputNum={true}
                shouldAutoFocus={true}
                separator={<span>-</span>}
              />
              {inValidOtp && (
                <p className="sign_resend_code invalid">
                  {invalidOtpMessage}
                </p>
              )}

              {resendCodeStop === 3 ? (
                <p className="check_inbox">
                  {invalidOtpMessage}
                </p>
              ) : timeOut ? (
                <p onClick={resendCode} className="sign_resend_code resend">
                  Resend Code
                </p>
              ) : (
                <p className="sign_resend_code">
                  No code? Resend after
                  {minutes === 0 && seconds === 0 ? null : (
                    <>
                      {" "}
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </>
                  )}
                </p>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>

        <Buttton type="submit">
          {loadingSpin ? (
            <IonSpinner color={"#ffffff"} name="lines" />
          ) : (
            " Verify OTP "
          )}
        </Buttton>
      </form>
    </IonContent>
  );
};

export default SignUpOTPVerification;

const Buttton = styled.button`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #3ab34a;
  font-weight: 500;
  height: 3rem;
  color: white;
  outline: none;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
`;
