import React, { useState } from "react";
import { IonContent, IonHeader, IonPage } from "@ionic/react";
import HeaderTwoPage from "./HeaderTwoPage";
import PageTwoContent from "./PageTwoContent/PageTwoContent";
import AddItem from "../WebView/AddItem/AddItem";
import SideBar from "../WebView/SideBar/SideBar";

const PageTwo: React.FC = () => {
  const [addItems, setAddItems] = useState<any>({});
  const [filterDate, setFilterData] = useState("");

  return (
    <IonPage>
      <div className="mobile-view">
        <IonHeader>
          <HeaderTwoPage filterData={[filterDate, setFilterData]} />
        </IonHeader>
        <IonContent>
          <PageTwoContent
            popUp={true}
            setAddItems={setAddItems}
            filterData={[filterDate, setFilterData]}
          />
        </IonContent>
        <SideBar />
      </div>

      <div className="web-view style-2">
        <AddItem filterData={[filterDate, setFilterData]} />
      </div>
    </IonPage>
  );
};

export default PageTwo;
