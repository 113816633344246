import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
  IonThumbnail,
  IonToast,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { UserContext } from "../../../App";
import PageTwoContent from "../../PageTwo/PageTwoContent/PageTwoContent";
import SideBar from "../SideBar/SideBar";
import WebHeader from "../WebHeader/WebHeader";
import "./AddItem.css";

const Div = styled.div`
  padding-left: 0px;
  padding-top: 0px;
`;
const ImgIcon = styled.img`
  width: 50px;
  height: 50px;
`;
const P = styled.p`
  text-align: center;
`;
const Span = styled.span`
  coloe: #3ab34a;
`;

interface ContainerProps {
  filterData: any;
}
const AddItem: React.FC<ContainerProps> = (props) => {
  const [cartItems, setCartItems] = useContext<any>(UserContext);
  const [count, setCount] = useState(1);
  const location = useLocation();
  const [addItems, setAddItems] = useState<any>({});
  const [filterDate, setFilterData] = props.filterData;
  const [showToast1, setShowToast1] = useState({ status: false, message: "" });
  const [selected, setSelected] = useState<string>("category1");
  const [sideBarOpen, setSideBarOpen] = useState(false);
  useEffect(() => {
    if (location.state) {
      setAddItems(location.state);
    }
  }, [location.state]);
  useEffect(() => {
    setCount(addItems.kg || 1);
    setSelected(addItems.category || "category1");
  }, [addItems]);

  const plus = () => {
    setCount(count + 1);
  };
  const minus = () => {
    if (count <= 1) {
      return;
    } else {
      setCount(count - 1);
    }
  };

  const addToCart = () => {
    if (addItems.category) {
      const findIndex = cartItems.findIndex(
        (item: any) => item.id === addItems.id
      );
      const changeItem = [...cartItems];
      changeItem[findIndex] = { ...changeItem[findIndex], kg: count, selected };
      setCartItems(changeItem);
      setShowToast1({ status: true, message: "Item Update Successfylly" });
    } else {
      if (cartItems.find((item: any) => item.id === addItems.id)) {
        setShowToast1({ status: true, message: "Item Alredy Added On Cart" });
      } else {
        setCartItems([
          ...cartItems,
          { ...addItems, kg: count, category: selected },
        ]);
        setShowToast1({ status: true, message: "Item Added To Cart" });
      }
    }
  };

  return (
    <>
      <IonToast
        isOpen={showToast1.status}
        onDidDismiss={() => setShowToast1({ status: false, message: "" })}
        message={showToast1.message}
        duration={1000}
      />
      <WebHeader
        sideBarOpen={[sideBarOpen, setSideBarOpen]}
        setAddItems={setAddItems}
        filterData={[filterDate, setFilterData]}
      />
      <div className="web_view_main_content">
        <SideBar />
        <Div className="web_view_impact_content web_view_top">
          <div className="addItem_web_view style-2">
            <PageTwoContent
              popUp={false}
              setAddItems={setAddItems}
              filterData={[filterDate, setFilterData]}
            />
          </div>
        </Div>
        <Div className="web_view_upcoming_picksup web_view_top">
          <div
            className={
              JSON.stringify(addItems) === "{}" ? "" : "showItem_addItem"
            }
          >
            {JSON.stringify(addItems) === "{}" ? null : (
              <>
                <IonGrid>
                  <IonRow className="mt-3">
                    <IonCol>
                      <div className="addItem web_item">
                        <IonThumbnail className="web_Ion_Icon" slot="start">
                          <IonImg
                            className="images"
                            alt="icon"
                            src={`https://swapeco.com/${addItems.img}`}
                          />
                        </IonThumbnail>
                        <div className="descrip descriptLeft">
                          <h3>{addItems.title}</h3>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="btnColor mb-0">
                      <IonButton
                        onClick={() => setSelected("category1")}
                        color={selected === "category1" ? "success" : "dark"}
                        fill="outline"
                      >
                        Category 1
                      </IonButton>
                      <IonButton
                        onClick={() => setSelected("category2")}
                        color={selected === "category2" ? "success" : "dark"}
                        fill="outline"
                      >
                        Category 2
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <input className="rangeInput" type="range" />
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div className="count">
                        <p onClick={minus}>-</p>
                        <p>{count}</p>
                        <p onClick={plus}>+</p>
                      </div>
                      <h5 className="text-centers">
                        {addItems.m_unit} Quantity
                      </h5>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <P>
                        RS <Span>{addItems.rate}</Span> {addItems.m_unit}
                      </P>
                      <P>
                        TOTAL= <Span>RS {addItems.rate * count}</Span>{" "}
                      </P>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonButton onClick={addToCart} color="success" expand="full">
                  {addItems && addItems.category
                    ? "Update Cart"
                    : "ADD TO CARTs"}
                </IonButton>
              </>
            )}
          </div>
        </Div>
      </div>
    </>
  );
};

export default AddItem;
