module.exports = {
  API_CONFIG: {
    BASE_URL: "https://dev-scrapuncle.onrender.com",
    NEW_BASE_URL: "https://scrapuncle-api.onrender.com",
    OLD_BASE_URL: 'https://scrapuncle.w3api.net/v1'
  },
  IMAGEKIT_CONFIG: {
    PUBLIC_KEY: "public_2TUjeb/4RX0SHDMCvoqAnFKy10o=",
    URL_ENDPOINT: "https://ikp.edgekit.net/y8s2vhk66ef",
    AUTH_ENDPOINT: "https://swapeco.w3api.net/api/v2/auth/key",
  },
  TRUECALLER_CONFIG: {
    APP_NAME: "ScrapUncle Account",
    APP_KEY: "Ilqzl3150dc704503481a98e1c232b6d4d17e",
    PRIVACY_PAGE_URL: "",
    TNC_PAGE_URL: "",
  },
  APP_LINK: {
    ANDROID: 'https://play.google.com/store/apps/details?id=com.swapeco.app',
    IOS: null
  },
  GOOGLE_MAP: {
    GOOGLE_MAP_KEY: "AIzaSyA2qy2lFASUCsACEu2N8aQyy63O5OkVZok",
  },
  EXTERNAL_IMAGES: {

    ICONS: {
      ESTIMATED_WEIGHT: "https://ik.imagekit.io/domestica/swapeco/image_28_hVpvb87hn.png",
      CHECK_ICON: "https://ikp.edgekit.net/y8s2vhk66ef/Icon_check_outline_JeVSTUtt3.png",
      ARROW_DOWN: "https://ikp.edgekit.net/y8s2vhk66ef/Icon_down_outline_iO9QgVTOoX.png",
      QUESTION_MARK: "https://ik.imagekit.io/domestica/swapeco/Subtract_OLem3TzJrD.svg",
      MAP_PIN: "https://ik.imagekit.io/domestica/swapeco/pin_1_cwd5RkRPsb.png",
    },

    PAGES: {
      PICKUP_SUCCESS: {
        BANNER: "https://ikp.edgekit.net/y8s2vhk66ef/3713351_N1B0DcawTb.png",
        CHECK_ICON: "https://ikp.edgekit.net/y8s2vhk66ef/Icon_check_outline_JeVSTUtt3.png"
      },
      REFER_FRIEND: {
        BANNER: "https://ik.imagekit.io/domestica/swapeco/Humaaans_Plants_lLD82r50n.png"
      }
    }
  },
  CONTACT:{
    SUPPORT_PHONE: '8595358613',
    SUPPORT_EMAIL:'team@scrapuncle.com'
  }
};
