import { IonCol, IonContent, IonHeader, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { UseActiveTabShow, UserProfile } from "../App";
import Header from "../Components/Header/Header";
import CompletePickup from "../Components/Pickup/CompletePickup";
import UpcomingPickup from "../Components/Pickup/UpcomingPickup";
import PickupsWebView from "../Components/WebView/PickupsWebView/PickupsWebView";
import useLocalStorage from "../Services/useLocalStorage";
import "./Tab4.css";

const Tab4: React.FC = () => {
  const [state, setState] = useState("upcoming");
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [profile, setProfile] = useContext<any>(UserProfile);
  const location = useLocation();
  const [clickPickupToShow, setClickPickupToShow] = useState<any>({});
  const [activeTabShow, setActiveTabShow] = useContext<any>(UseActiveTabShow);
  const history = useHistory();
  useEffect(() => {
    setProfile({ ...profile, location: location.pathname });
  }, []);
  useEffect(() => {
    setActiveTabShow(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <IonPage>
      <div className="mobile-view">
        <IonHeader mode="ios">
          <Header />
        </IonHeader>

        <IonContent>
          <IonRow>
            <IonCol
              onClick={(e) => setState("upcoming")}
              className={
                state === "complete" ? "complete_title" : "upcoming_title"
              }
            >
              Upcoming
            </IonCol>
            <IonCol
              onClick={(e) => setState("complete")}
              className={
                state === "upcoming" ? "complete_title" : "upcoming_title"
              }
            >
              Completed
            </IonCol>
          </IonRow>
          {state === "complete" ? (
            <CompletePickup setClickPickupToShow={setClickPickupToShow} />
          ) : (
            <UpcomingPickup setClickPickupToShow={setClickPickupToShow} />
          )}
        </IonContent>
      </div>
      <div className="web-view style-2">
        <PickupsWebView />
      </div>
    </IonPage>
  );
};

export default Tab4;
