import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonAlert,
  IonRow,
  IonIcon,
  IonToast,
} from "@ionic/react";
import Skeleton from "Components/Skeleton";
import { trashOutline, createOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { UseReload, UserLocation } from "../../App";
import { userTabClick } from "../../events";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";
import Title from "../Title";
import "./SaveAddress.css";

const Div = styled.div`
  margin-left: 15px;
`;
const ImgAlign = styled.img`
  align-self: "center";
`;
const P = styled.p`
  margin: 0px;
  margintop: 5px;
`;

interface ContainerProps {
  Header: boolean;
}

const SaveAddress: React.FC<ContainerProps> = (props) => {
  const [loggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [address, setAddress] = useState<any>({ status: false, fullData: [] });
  const history = useHistory<any>();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const location = useLocation<any>();
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);
  const [reload, setReload] = useContext<any>(UseReload);
  const [toast, setToast] = useState({ status: false, message: "" });

  useEffect(() => {
    setAddress({ fullData: [], status: false });
    userTabClick(window.location.pathname);
    ApiRequest("GET", "address/", {
      isAuthenticated: false,
      token: loggedInUser.token,
    })
      .then((res) => {

        if (res.success) {
          setAddress({ fullData: res.data, status: res.success });
        }
      })
      .catch((err) => { });
  }, [reload.address]);

  const addressEdit = (item: any) => {

    const locationCenter = JSON.parse(item.location);

    setTargetLocation({
      ...item,
      ...locationCenter,
    });
    history.push({ pathname: "/mapaddress", state: { pathname: location.pathname, action: 'PUT' } });
  };

  const removeAddressCallFunc = (id: number) => {
    ApiRequest("DELETE", `address/${id}`, {
      isAuthenticated: false,
      token: loggedInUser.token,
    })
      .then((res) => {
        if (res.success) {
          setReload({ ...reload, address: !reload.address });
          setToast({ status: true, message: res.message });
        }
      })
      .catch((err) =>
        setToast({ status: true, message: err.message })
      );
  };

  const AddressRemoveBtnClick = (id: number) => {
    if (window.screen.availWidth > 992) {
      removeAddressCallFunc(id);
    } else {
      setOpenRemoveModal(true);
    }
  };

  return (
    <>
      <IonHeader id="ion_header" className="d-flex-pickupdetails">
        <img
          onClick={() => history.goBack()}
          src="https://ikp.edgekit.net/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
          alt="left arrow"
        />
        <p onClick={() => history.goBack()}>Back</p>
      </IonHeader>

      <IonToast
        isOpen={toast.status}
        onDidDismiss={() => setToast({ status: false, message: "" })}
        message={toast.message}
        duration={1000}
      />
      <IonContent>
        <Title title="Saved Addresses" />
        <IonGrid>
          <IonRow>
            <IonCol>
              {address.status === false && <Skeleton />}
              {address.status === true &&
                JSON.stringify(address.fullData) === "[]" ? (
                <p className="gray-text">You Have no address added yet!</p>
              ) : address.fullData.map((adrr: any) => (
                <div className="list address_list" key={adrr.id}>
                  <div className="list_item">
                    <div className="list_item_d-flex saved_address">
                      <ImgAlign
                        className="icon"
                        src="https://ikp.edgekit.net/y8s2vhk66ef/Icon_location_solid_qVIOANvIZ.png"
                        alt=""
                      />
                      <Div>
                        <h3>{adrr.title} </h3>
                        <P>
                          {" "}
                          {adrr.line1} <br></br>
                          {adrr.city}-{adrr.pincode}
                        </P>
                      </Div>
                    </div>

                    <span
                      onClick={() => addressEdit(adrr)}
                      className="Ion_icon_custom"
                      title="Edit"
                    >
                      <IonIcon
                        className="mr-2 icon-delete"
                        icon={createOutline}
                        title="false"
                      />
                    </span>
                    <span
                      onClick={() => AddressRemoveBtnClick(adrr.id)}
                      className="Ion_icon_custom"
                      title="Delete"
                    >
                      <IonIcon
                        className="icon-delete"
                        icon={trashOutline}
                        title="Delete"
                      />
                    </span>
                  </div>
                  <IonAlert
                    isOpen={openRemoveModal}
                    onDidDismiss={() => setOpenRemoveModal(false)}
                    cssClass="my-custom-class"
                    header={"Are you sure?"}
                    mode="ios"
                    buttons={[
                      {
                        text: "Cancel",
                        role: "cancel",
                      },
                      {
                        text: "Ok",
                        cssClass: "secondary",
                        handler: () => removeAddressCallFunc(adrr.id),
                      },
                    ]}
                  />
                </div>
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default SaveAddress;
