import { IonBadge, IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import "./HeaderTwoPage.css";

interface ContainerProps {
  filterData: any;
}
const HeaderTwoPage: React.FC<ContainerProps> = (props) => {
  const [filterDate, setFilterData] = props.filterData;
  const [cartItems, setCartItems] = useContext<any>(UserContext);
  const [searchText, setSearchText] = useState("");

  // onChange={(e)=> setFilterData(e.target.value)}
  useEffect(() => {
    setFilterData(searchText);
  }, [searchText]);
  return (
    <div className="container bg-white">
      <IonGrid>
        <IonRow>
          <IonCol className="d-flex-header-twopage">
            <IonSearchbar
            placeholder='Search for your item'
              className="search_bar"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            />
            <div className="cart_icon_div">
              {cartItems.length ? (
                <div className="cartIcon">
                  <Link to="/cartitems">
                    <img
                      src="https://ikp.edgekit.net/y8s2vhk66ef/Vector_3__DFPL3VQF8.png"
                      alt=""
                    />
                    <IonBadge className="badgeStyle" color="danger">
                      {cartItems.length}
                    </IonBadge>
                  </Link>
                </div>
              ) : (
                <div className="cartIcon">
                  <Link to="/cartitems">
                    <img
                      src="https://ikp.edgekit.net/y8s2vhk66ef/Vector_3__DFPL3VQF8.png"
                      alt=""
                    />
                  </Link>
                </div>
              )}

              {/* 
              {cartItems.length ? (
                <div className="cartIcon">
                  <Link to="/cartitems">
                    {" "}
                    <img className="cart_icons" src={cart_icon} alt="" />
                    <IonBadge className="badgeStyle" color="danger">
                      {cartItems.length}
                    </IonBadge>
                  </Link>
                </div>
              ) : (
                <div className="cartIcon">
                  <Link to="/cartitems">
                    {" "}
                    <img className="cart_icons" src={cart_icon} alt="" />
                  </Link>
                </div>
              )} */}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default HeaderTwoPage;
