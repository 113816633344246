import { IonButton, IonCol, IonGrid, IonModal, IonRow } from "@ionic/react";
import React, { useContext, useState } from "react";
import { UserContext } from "../../../App";
import "./PageTwoContent.css";
interface ContainerProps {
  item: any;
  open: any;
  edit: boolean;
  setItemAdded: any;
}

const ShowModal: React.FC<ContainerProps> = (props: any) => {
  const [cartItems, setCartItems] = useContext<any>(UserContext);
  const { title, id, img, m_unit, rate, kg } = props.item;
  const [open, SetOpen] = props.open;
  const [count, setCount] = useState(kg || 1);
  const [showModal, setShowModal] = useState<any>(true);
  const [category, setCategory] = useState(props.item.category || "category1");

  const plus = () => {
    setCount(count + 1);
  };
  const minus = () => {
    if (count <= 0) {
      return;
    } else {
      setCount(count - 1);
    }
  };

  const addToCart = () => {
    if (props.edit) {
      const findIndex = cartItems.findIndex((item: any) => item.id === id);
      const changeItem = [...cartItems];
      changeItem[findIndex] = { ...changeItem[findIndex], kg: count, category };

      setCartItems(changeItem);

      SetOpen(false);
      props.setItemAdded({ status: true, message: "Item Updated" });
    } else {
      if (cartItems.find((item: any) => item.id === id)) {
        SetOpen(false);
        props.setItemAdded({ status: true, message: "Item Already Added" });
      } else {
        setCartItems([...cartItems, { ...props.item, kg: count, category }]);
        SetOpen(false);
        props.setItemAdded({ status: true, message: "Item Added" });
      }
    }
  };

  return (
    <IonModal
      swipeToClose={true}
      onDidDismiss={() => SetOpen(false)}
      isOpen={showModal}
      className="my-custom-class"
      mode="ios"
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <div className="addItem">
              <img
                className="images"
                src={`https://swapeco.com/${img}`}
                alt="icon"
              />
              <div className="descrip">
                <h3>{title}</h3>
                <p>
                  RS {rate}/{m_unit}
                </p>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="category_section">
            <IonButton
              onClick={() => setCategory("category1")}
              color={category === "category1" ? "success" : "dark"}
              fill="outline"
            >
              Category 1
            </IonButton>
            <IonButton
              onClick={() => setCategory("category2")}
              color={category === "category2" ? "success" : "dark"}
              fill="outline"
            >
              Category 2
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <div className="count">
              <p onClick={minus}>-</p>
              <p>{count}</p>
              <p onClick={plus}>+</p>
            </div>
            <h5 className="text-centers text-uppercase">{m_unit} Quantity</h5>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButton
        onClick={addToCart}
        className="bottomBtn"
        color="success"
        expand="full"
      >
        {props.item.category ? "ADD TO CART" : "UPDATE ITEM"}
      </IonButton>
    </IonModal>
  );
};

export default ShowModal;
