import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ApiRequest from "../../Services/Api.Service";
import "./SignUpOTP.css";
import {
  TruecallerLoginStart,
  TruecallerLoginEventResponse,
  TruecallerLoginConfirm
} from "Services/Truecaller.Service"

import useLocalStorage from "../../Services/useLocalStorage";
import { UserProfile } from "../../App";

const Div = styled.div`
  padding-left: .2rem;
  margin-top; 1rem;

`;
const Span = styled.span`
  padding: 2%;
  padding-right: 0.2rem;
  width: 10%;
`;
const Input = styled.input`
  width: 88%;
  padding-left: 0.2rem;
`;

type Inputs = {
  example: string;
  exampleRequired: string;
  firstName: string;
  lastName: string;
  email: string;
  isDeveloper: boolean;
};

const SignUpOTP: React.FC<any> = (props) => {
  const [promoShow, setPromoShow] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, setValue }: any = useForm<Inputs>();
  const [error, setError] = useState("");
  const [numberLength, setNumberLength] = useState("");
  const [loadingSpin, setLoadingSping] = useState(false);
  const [phoneNumberLength, setPhoneNumberLength] = useState<any>(0);

  const [profile, setProfile] = useContext<any>(UserProfile);
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );

  const autofillPhone = (phone: string, auto_login = false) => {
    setTimeout(() => {
      let phoneEl: any = document.getElementsByName("phoneNumber")
      if (phoneEl && phoneEl.length > 0) {
        phoneEl[0].value = phone
      }
      if (auto_login) {
        setLoadingSping(true)
        TruecallerLoginConfirm()
          .then((res: any) => {
            const data = res;
            if (data.token) {
              ApiRequest("GET", "/profile/", {
                isAuthenticated: false,
                token: data.token,
              }).then((resData) => {
                if (resData.success === true) {

                  const dataInfo = resData.data[0];
                  setLoggedInUser({ ...loggedInUser, ...dataInfo, ...data });
                  setProfile({ ...profile, ...dataInfo, ...data });
                  setLoadingSping(false);

                  setTimeout(() => {
                    if (data.first_login) {
                      history.push("/auth/signup");
                    } else {
                      history.push("/home");
                    }
                  }, 2000);
                }
              })
            } else {
              setError("Unknown Error Occured. Please restart the app.")
            }
          })
          .catch(err => {
            setError(err.message)
          })
          .finally(() => {
            setLoadingSping(false)
          })
      }
    });
  }

  useEffect(() => {
    autofillPhone("")
    TruecallerLoginStart()
    TruecallerLoginEventResponse(autofillPhone)

    return () => {

    }

  }, [])

  const onSubmit = (data: any) => {
    const phoneNumber = data.phoneNumber;
    const phoneOtp = { phone: phoneNumber };
    setLoadingSping(true);
    if (numberLength.length >= 10) {
      ApiRequest("POST", "auth/send", { body: phoneOtp }).then((data) => {
        if (data.success) {
          setLoadingSping(false);
          history.push({
            pathname: "/auth/verify",
            state: { phone: phoneNumber },
          });
        } else {
          setError("Enter a valid Phone Number. Number should be 10 digit!");
          setLoadingSping(false);
        }
      });
    } else {
      setError("Enter a valid Phone Number. Number should be 10 digit!");
      setLoadingSping(false);
    }
  };

  const promoCode = () => {
    setPromoShow(true);
  };

  const numberLengthSet = (e: any) => {
    setNumberLength(e.target.value);
    setError("");
    const val = e.target.value;
    const valString = val.toString();
    const max = 10;

    if (valString.length <= max) {
      setPhoneNumberLength(Number(val));
    }
  };

  return (
    <IonContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ionGrid">
          <IonRow>
            <IonCol>
              <div className="sign_header">
                <h3 className="sign_header_h3">Get OTP</h3>
              </div>
              <p className="sign_header_paragraph">
                Enter your mobile number to start{" "}
              </p>
              <p className="sign_header_paragraph m-0">selling recyclabes</p>
              <p className="sign_header_paragraph">MOBILE NUMBER</p>
              <Div className="sign_header_input">
                <Span>+91</Span>

                <Input
                  {...register('phoneNumber')}
                  value={phoneNumberLength === 0 ? false : phoneNumberLength}
                  onChange={(e: any) => numberLengthSet(e)}
                  className="sign_header_input"
                  type="number"
                  name="phoneNumber"
                  id="otp"
                />
              </Div>
              <p className="error_para">{error}</p>

              {promoShow ? (
                <>
                  <p className="sign_header_paragraph">PROMO CODE</p>
                  <input
                    {...register('promoCode')}
                    className="sign_header_input mt-1"
                    type="text"
                    name="promoCode"
                    id="promoCode"
                    required
                  />
                  <p
                    onClick={() => setPromoShow(false)}
                    className="sign_resend_code not_now"
                  >
                    not now!
                  </p>
                </>
              ) : (
                <p onClick={promoCode} className="sign_resend_code">
                  have a promo code?
                </p>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonButton
          className="bottom_Btn login_btn_app"
          type="submit"
          color="success"
          expand="full"
          id="bottom_btn"
        >
          {loadingSpin ? <IonSpinner name="lines" /> : " GET OTP "}
        </IonButton>
      </form>
    </IonContent>
  );
};

export default SignUpOTP;
