import {
  IonCol,
  IonModal,
  IonIcon,
  IonGrid,
  IonImg,
  IonRow,
} from "@ionic/react";
import React, { useState, useEffect, useContext } from "react";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";
import dayjs from "dayjs";
import { UseReload } from "App";
import Skeleton from "Components/Skeleton";
import {
  closeOutline,
  location,
  calendar,
  time,
  list,
  layers,
  personCircleOutline,
} from "ionicons/icons";
interface ContainerProps {
  status: any;
  setClickPickupToShow: any;
}
const PageThreeContent: React.FC<ContainerProps> = (props: any) => {
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  const [upcomingData, setUpcomingData] = useState<any>({
    status: false,
    fullData: [],
  });
  const [reload] = useContext<any>(UseReload);

  useEffect(() => {
    setUpcomingData({ fullData: [], status: false });
    ApiRequest("GET", "pickup/all?filter=status%3Dpending", {
      isAuthenticated: false,
      token: loggedInUser.token,
    }).then(
      (data) =>
        data.data && setUpcomingData({ fullData: [...data.data], status: true })
    );
  }, [reload.schedule]);

  const [status, setStatus] = props?.status;

  return (
    <div className="upcoming-pickup">
      <IonGrid>
        {status === "pending" ? (
          upcomingData.status === false ? (
            <Skeleton />
          ) : upcomingData.status === true &&
            JSON.stringify(upcomingData.fullData) === "[]" ? (
            JSON.stringify(
              upcomingData.fullData.filter(
                (item: any) => item.status === "PENDING"
              )
            ) !== "[]" &&
            upcomingData.fullData
              .filter((item: any) => item.status === "PENDING")
              .map((item: any, i: number) => (
                <Schedule
                  setClickPickupToShow={props?.setClickPickupToShow}
                  key={i}
                  item={item}
                />
              ))
          ) : (
            <p className="text-center mt-2">Nothing pending!</p>
          )
        ) : null}

        {status === "cancelled" ? (
          upcomingData.status === false ? (
            <Skeleton />
          ) : upcomingData.status === true &&
            JSON.stringify(upcomingData.fullData) === "[]" ? (
            JSON.stringify(
              upcomingData.fullData.filter(
                (item: any) => item.status === "CANCELLED"
              )
            ) !== "[]" &&
            upcomingData.fullData
              .filter((item: any) => item.status === "CANCELLED")
              .map((item: any, i: number) => (
                <Schedule
                  setClickPickupToShow={props?.setClickPickupToShow}
                  key={i}
                  item={item}
                />
              ))
          ) : (
            <p className="text-center mt-2">Nothing Cancelled!</p>
          )
        ) : null}

        {status === "schedule" ? (
          upcomingData.status === false ? (
            <Skeleton />
          ) : JSON.stringify(
              upcomingData.fullData.filter(
                (item: any) => item.status === "SCHEDULED"
              )
            ) !== "[]" ? (
            upcomingData.fullData
              .filter((item: any) => item.status === "SCHEDULED")
              .map((item: any, i: number) => (
                <Schedule
                  setClickPickupToShow={props?.setClickPickupToShow}
                  key={i}
                  item={item}
                />
              ))
          ) : (
            <p className="text-center mt-2">Nothing Schedule!</p>
          )
        ) : null}

        <IonImg
          className="bg_three_page"
          src="https://ikp.edgekit.net/y8s2vhk66ef/Subtract_1__OKoMNtv7qW.png"
          alt="Bg"
        />
      </IonGrid>
    </div>
  );
};

export default PageThreeContent;

const Schedule = (props: any) => {
  const [itemShow, setItemShow] = useState<any>(false);
  const showItem = () => {
    if (window.screen.availWidth > 992) {
      props?.setClickPickupToShow(props?.item);
    } else {
      setItemShow(true);
    }
  };

  return (
    <IonRow>
      <IonCol onClick={showItem}>
        <div className="d-flex page_three_box">
          <div className="date">
            <h3>
              {dayjs(props?.item?.pickup_date).toDate().toString().slice(0, 3)}
            </h3>

            <b>
              {dayjs(props?.item?.pickup_date).date() < 10 && "0"}
              {dayjs(props?.item?.pickup_date).date()}
            </b>
          </div>

          <div className="location">
            <p>{props?.item?.pickup_time.slice(0, 15)}</p>
            <p>{props?.item?.address?.line1}</p>
            <span>
              {props?.item?.items[0]?.title},
              {props?.item?.items?.length > 1 &&
                " +" + props?.item?.items?.length + " MORE"}
            </span>
          </div>
        </div>
      </IonCol>

      <IonModal
        backdropDismiss={true}
        swipeToClose={true}
        isOpen={itemShow}
        className="pickup_info_modal"
        mode="ios"
        onDidDismiss={() => setItemShow(false)}
      >
        <div className="modal_cover_div">
          <div className="modal_header">
            <h1 className="m-0 font-weight-bold">Pickup Details </h1>
            <div onClick={() => setItemShow(false)}>
              <IonIcon className="closeIcon" icon={closeOutline} />
            </div>
          </div>
          <div className="modal_content">
            <div className="pickup_agent">
              {props?.item.pickup_agent?.status === "NOT_ASSIGNED" ? (
                <>
                  <IonIcon
                    className="pickup_agent_profile"
                    icon={personCircleOutline}
                  />
                  {props?.item.pickup_agent?.status}
                </>
              ) : (
                <>
                  <img
                    src={props?.item?.pickup_agent?.details?.photo}
                    alt="Pickup Agent"
                  />
                  <div className="pickup_detail">
                    <p className="pickup_agent_name">
                      {props?.item?.pickup_agent?.details?.name}
                    </p>
                    <p className="pickup_agent_mobile">
                      {props?.item?.pickup_agent?.details?.mobile}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="pickup_detail_div">
              <div className="pickup_first_details">
                <p className="details_text m-0 text-muted">Details</p>
                <div className="first_details_text">
                  <p className="first_details_text_invoice m-0">Status</p>
                  <p className=" m-0 text-muted">{props?.item?.status}</p>
                </div>
                <div className="first_details_text">
                  <p className="first_details_text_invoice m-0">Invoice</p>
                  <p className=" m-0 text-muted">{props?.item?.invoice?.status}</p>
                </div>
                <div className="second_details_div">
               
                  <div className="second_details_text">
                    <IonIcon
                      color="danger"
                      className="pickup_details_icon"
                      icon={location}
                    />
                    <p className="text-muted m-0 pl-3">
                      {props?.item?.address?.line1}
                    </p>
                  </div>
                  <div className="second_details_text">
                    <IonIcon
                      color="tertiary"
                      className="pickup_details_icon"
                      icon={calendar}
                    />
                    <p className="text-muted m-0 pl-3">
                      {dayjs(props?.item?.pickup_date)
                        .toDate()
                        .toString()
                        .slice(0, 16)}
                    </p>
                  </div>
                  <div className="second_details_text">
                    <IonIcon
                      color="warning"
                      className="pickup_details_icon"
                      icon={time}
                    />
                    <p className="text-muted m-0 pl-3">
                      {props?.item?.pickup_time.slice(0, 16)}
                    </p>
                  </div>
                  {props.item.weight && (
                    <div className="second_details_text">
                      <IonIcon
                        color="secondary"
                        className="pickup_details_icon"
                        icon={layers}
                      />
                      <p className="text-muted m-0 pl-3">{props?.item?.weight}</p>
                    </div>
                  )}
                  <div className="second_details_text">
                    <IonIcon
                      color="tint"
                      className="pickup_details_icon"
                      icon={list}
                    />
                    <p className="text-muted m-0 pl-3">
                      {props?.item && props?.item?.items[0]?.title}
                      {props?.item?.items?.length > 1
                        ? ", +" + props?.item?.items?.length + " MORE"
                        : ","}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonModal>
    </IonRow>
  );
};
