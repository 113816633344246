import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import './pagination.css';

interface Props {
    page: number;
    totalPages: number;

    handlePagination: (page: number) => void;
}
interface PaginationProps {
    totalPage: number;
    onChange: (page: number) => void;
}

const PaginationCom: React.FC<PaginationProps> = ({ onChange, totalPage = 1 }: any) => {
    const [page, setPage] = useState(1);
    const totalPages = totalPage;
    const handlePages = (updatePage: number) => {
        setPage(updatePage)
        onChange(updatePage)
    };

    useEffect(() => {
        setPage(1)
    }, [totalPage])


    return (
        <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
        />
    );

};

export default PaginationCom;

const PaginationComponent: React.FC<Props> = ({
    page,
    totalPages,
    handlePagination,
}) => {
    return (
        <div className={'pagination'}>
            <div className={'paginationWrapper'}>

                <button
                    onClick={() => { page !== 1 && handlePagination(page - 1) }}
                    type="button"
                    className={classNames(['pageItem', `sides ${page !== 1 ? 'active_arr' : 'deactive'}`].join(' '))}
                >
                    &lt;
                </button>



                {
                    totalPages > 1 &&
                    <button
                        onClick={() => handlePagination(1)}
                        type="button"
                        className={classNames('pageItem', {
                            ['active']: page === 1,
                        })}
                    >
                        {1}
                    </button>
                }

                {page > 3 && <div className={'separator'}>...</div>}

                {page === totalPages && totalPages > 3 && (
                    <button
                        onClick={() => handlePagination(page - 2)}
                        type="button"
                        className={'pageItem'}
                    >
                        {page - 2}
                    </button>
                )}

                {page > 2 && (
                    <button
                        onClick={() => handlePagination(page - 1)}
                        type="button"
                        className={'pageItem'}
                    >
                        {page - 1}
                    </button>
                )}

                {page !== 1 && page !== totalPages && (
                    <button
                        onClick={() => handlePagination(page)}
                        type="button"
                        className={['pageItem', 'active'].join(' ')}
                    >
                        {page}
                    </button>
                )}

                {page < totalPages - 1 && (
                    <button
                        onClick={() => handlePagination(page + 1)}
                        type="button"
                        className={'pageItem'}
                    >
                        {page + 1}
                    </button>
                )}

                {page === 1 && totalPages > 3 && (
                    <button
                        onClick={() => handlePagination(page + 2)}
                        type="button"
                        className={'pageItem'}
                    >
                        {page + 2}
                    </button>
                )}

                {page < totalPages - 2 && <div className={'separator'}>...</div>}

                <button
                    onClick={() => handlePagination(totalPages)}
                    type="button"
                    className={classNames('pageItem', {
                        ['active']: page === totalPages,
                    })}
                >
                    {totalPages}
                </button>


                <button
                    onClick={() => { page !== totalPages && handlePagination(page + 1) }}
                    type="button"
                    className={[`pageItem`, `sides ${page !== totalPages ? 'active_arr' : 'deactive'}`].join(' ')}
                >
                    &gt;
                </button>

            </div>
        </div>
    );
};

export const Pagination = PaginationComponent;