import { IonSpinner } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import ApiRequest from "Services/Api.Service";
import { UserProfile } from "../../../App";
import ShowModal from "../../PageTwo/PageTwoContent/ShowModal";
import "./LocalRate.css";
import Config from "Config";

interface ContainerProps {
  searchData?: any;
  setAddItems: any;
  popUp: boolean;
}


const LocalRateItem: React.FC<ContainerProps> = (props) => {
  const [profile] = useContext<any>(UserProfile);
  const setAddItems = props.setAddItems;
  const [search] = props.searchData;
  const [open, setOpen] = useState<any>(false);
  const [items] = useState({});
  const [totalData, setTotalData] = useState<any>([]);
  const [itemAdded, setItemAdded] = useState({ status: false, message: "" });
  const [filterData, setFilterData] = useState([]);

  const fetchData = () => {
    return ApiRequest("GET", 'v1/rate-list?children=1')
  };
  const { data, status, isLoading } = useQuery("rents", fetchData);


  useEffect(() => {
    if (search) {
      let allData: any = [];
      for (let i = 0; i < totalData.length; i++) {
        const element = totalData[i];
        allData = [...allData, ...element?.children]
      }
      let searchData = allData.filter((val: any) => val.title.toLowerCase().includes(search.toLowerCase()));
      setFilterData(searchData)
    } else {
      setFilterData(totalData)
    }
  }, [search]);


  useEffect(() => {
    setTimeout(() => {
      setItemAdded({ status: false, message: "" });
    }, 1500);
  }, [itemAdded.status]);



  useEffect(() => {
    if (data) {
      if (data.success) setTotalData(data.data);
    }
  }, [data])

  useEffect(()=>{
    const helperAction = document.querySelector('.helper-action');
    helperAction?.addEventListener('click', ()=> {
      helperAction?.classList.toggle('active')
    })
  },[])


  return (
    <div className="container page_two_content">

      {open === true && (
        <ShowModal
          setItemAdded={setItemAdded}
          edit={false}
          open={[open, setOpen]}
          item={items}
        />
      )}

      <div style={{ maxWidth: "800px!important", margin: "auto" }}>
        <h4 className="recyclabels_title">{null}</h4>
        <div
          className="alert alert-primary"
          role="alert"
          style={{ maxWidth: "800px!important", margin: "auto" }}
        >
          <b>Notes</b>
          <ul>
            <li>
              The prices may vary with fluctuation in the scrap market.
            </li>
            {/* <li>
              We don't deal in toughened glass, wooden items, fabrics,
              ceramics and vehicle scrap.
            </li> */}
            <li>
              Prices may be different for bulk pickups. Call us at{" "}
              <a href={`tel:+91${Config.CONTACT.SUPPORT_PHONE}`}>+91-{Config.CONTACT.SUPPORT_PHONE}</a> to get a quote
              for bulk pickup.
            </li>
          </ul>
        </div>
      </div>
      {
        isLoading &&
        <div className="flex justify-center p-2">
          <IonSpinner name="lines" />
        </div>
      }
      <div>
        {!isLoading && status === "success" &&
          (!search ?
            totalData?.length ?
              totalData.map((block: any) => (
                block?.is_active &&
                <React.Fragment key={block?.id}>
                  <p className="block_title">{block?.title}</p>
                  <div className="flex flex-wrap item_block_web">
                    {
                      block?.children?.map((item: any) => (
                        <Block key={item?.id} item={item} />

                      ))
                    }
                  </div>
                </React.Fragment>
              ))
              :
              <p className="text-center mt-4 w-full">Not Data! </p>
            :
            filterData?.length ?
              <div className="flex flex-wrap item_block_web">
                {filterData.map((item: any) => (
                  item?.is_active &&
                  <Block key={item?.id} item={item} />
                ))
                }
              </div>
              :
              <p className="text-center mt-4 w-full">Not Data! </p>)
        }


      </div>
    </div>
  );
};

export default LocalRateItem;


const Block = ({ item }: any) => {
  return (
    <div

      className={`box_outside web_box_outside`}
    >
      <div
        className="block_box local_rate_block_box web_local_rate_block_box"
      >
        <img src={item?.icon} alt="icon" className="rate_image" />
        <p className="rate_price">
          RS {item?.unit_price}/
          {item?.unit_label}
        </p>
      </div>
      <p className="local_specific_rate_title">
        {item?.title}
      </p>
    </div>
  )
}