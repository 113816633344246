import React, { useState, useEffect } from "react"

const RemarkOptions = ({ formReset, setFormReset, resetField, register, required = false }: any) => {

    useEffect(() => {
        if (formReset) {
            resetField('message')
            setFormReset(false)
        }
    }, [formReset])
    return (
        <div className="row">
            <div className="col-md-6 mt-3">
                <div className="location_tab ">
                    <div className="">
                        <p className="schedule_title remark_title">
                            <span>
                                Remarks
                            </span>
                            <span className="local_optional_text">(Optional)</span>
                        </p>
                        <div className="remarks_local calendar_nd_date">
                            <input {...register('message', { required })} className="remarks_input" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemarkOptions