import React from "react";
import "./PageThreeContent.css";

const PageThreeTitle: React.FC = () => {
  return (
   <>
     {/* <div className="page_three_container"> */}
      {/* <h3 className="text-threePage">Upcoming Pickups</h3> 
    //         <div>
    //             <img className="threeBar" src={threeBar} alt="threeBar"/>
    //             <img className="calender" src={calender} alt="calender"/>
    //         </div> */}
     {/* </div> */}
   </>
  );
};

export default PageThreeTitle;
