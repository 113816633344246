import { addOutline, closeCircleOutline } from "ionicons/icons";
import React, { useState } from "react";
import { IonIcon } from "@ionic/react";

function MultipleImageUpload(props) {
  const [file, setFile] = props.setFile;
  const [selectedImage, setSelectedImage] = useState([]);

  const FileChange = (e) => {
    setFile([...file, ...e.target.files]);
    if (e.target.files) {
      const fileArray = [...e.target.files].map((file) => ({
        id: Math.random(),
        file: file,
        url: URL.createObjectURL(file),
      }));

      setSelectedImage([...selectedImage, ...fileArray]);

      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };
  const deleteIcon = (item) => {
    setSelectedImage(selectedImage.filter((items) => items.id !== item.id));

    const filterFile = file.filter((items) => item.file !== items);
    setFile(filterFile);
  };

  return (
    <div style={{flexDirection:'row'}} className="blockTwoPage style-2">
      {props.show ? (
        <label htmlFor="addMoreItem">
          <input
            style={{ display: "none" }}
            type="file"
            className="form-control"
            onChange={(e) => FileChange(e)}
            name="image_upload"
            id="addMoreItem"
            multiple
          />
          <div className="upload_more_photos">
            <IonIcon className="location_ion_icon" icon={addOutline}></IonIcon>
            <p>Attach Photos</p>
          </div>
        </label>
      ) : (
        <label
        
          htmlFor="addMoreItem"
          className="block_box upload_more_div"
        >
          <div className="plus_image">
            <div className="plus fileUploadPlus">+</div>
            <img src="" alt="" />
          </div>
          <input
            style={{ display: "none" }}
            type="file"
            className="form-control"
            onChange={(e) => FileChange(e)}
            name="image_upload"
            accept="image/*"
            id="addMoreItem"
            multiple
          />
          <div className="title_rate">
            <h3 className="upload_text">
              Attach
              <br />Photos{" "}
            </h3>
          </div>
        </label>
      )}

      <div className="show_upload_image" id="style-2">
        {selectedImage.map((item) => (
          <div
         
            key={item.id}
            className="block_box upload_image_block_box d-nones upload_box upload_image_show"
          >
            <img className="upload_image" src={item.url} alt={"icon"} />
            <div className="closeIcon_upload" onClick={() => deleteIcon(item)}>
              <IonIcon icon={closeCircleOutline} className="closeIcon_upload" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MultipleImageUpload;
