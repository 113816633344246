import {
  IonButton,
  IonPage,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
} from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import "./LoginPage.css";
import LoginPageWebView from "./LoginPageWebView";

const LoginPage: React.FC = () => {
  return (
    <IonPage>
      <div className="mobile-view">
        <IonContent>
          <div className="logo_image">
            <img
              className="logo"
              src="https://ikp.edgekit.net/h1rxzpffx/scrapuncle/ScrapuncleLogo_SNODTjW3kT.png"
              alt=""
            />
            <img
              className="middle_image"
              src="https://ikp.edgekit.net/y8s2vhk66ef/homeMiddle_Asp0dqEK2.png"
              alt=""
            />
          </div>
          <IonGrid>
            <IonRow>
              <IonCol className="title_box">
                <h3 className="welcome_page_title">Welcome to Scrapuncle</h3>
                <p className="welcome_page_subtitle">
                  India's first digital platform to sell recyclables
                </p>
                <ul className="welcome_page_ul">
                  <li>papers</li>
                  <li>plastics</li>
                  <li>metals</li>
                  <li>ewaste</li>
                </ul>
              </IonCol>
            </IonRow>
          </IonGrid>
          <Link className="login_btn_app" to="/auth/init">
            {" "}
            <IonButton
              className="bottom_Btn"
              color="success"
              expand="full"
              id="bottom_btn"
            >
              GET STARTED
            </IonButton>
          </Link>
        </IonContent>
      </div>
      <div className="web-view web_view_margin">
        <LoginPageWebView />
      </div>
    </IonPage>
  );
};

export default LoginPage;
