import { IonButton, IonIcon } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import './GoogleMapAreaSelect.css';
import { GoogleMap } from "@react-google-maps/api";
import Config from 'Config';
import Geocode from "react-geocode";
import { GOOGLE_MAP } from "Config";

const GoogleMapAreaSelect = ({ openGoogleMap, setOpenGoogleMap, location, setSelectAddress, selectAddress }) => {
    const [myMap, setMyMap] = useState(null);
    const [center] = useState({ lat: location?.lat, lng: location?.lng });
    const [selectMapDetails, setSelectMapDetails] = useState({});

    const handleChange = () => {
        Geocode.setApiKey(GOOGLE_MAP.GOOGLE_MAP_KEY);
        Geocode.setLanguage("en");
        Geocode.fromLatLng(
            myMap && myMap.state.map.center.lat(),
            myMap && myMap.state.map.center.lng()
        ).then(
            (response) => {
                const address = response.results[0].formatted_address;
                let city, state, country, pincode;
                for (
                    let i = 0;
                    i < response.results[0].address_components.length;
                    i++
                ) {
                    for (
                        let j = 0;
                        j < response.results[0].address_components[i].types.length;
                        j++
                    ) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                state = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = response.results[0].address_components[i].long_name;
                                break;
                            case "postal_code":
                                pincode = response.results[0].address_components[i].long_name;
                                break;
                            default:
                                break;
                        }
                    }
                }
                let getAddress = {
                    line2: address,
                    locality: city,
                    location: { lat: myMap.state.map.center.lat(), lng: myMap.state.map.center.lng() },
                    city: city,
                    state: state,
                    country: country,
                    pincode: pincode,
                }
                setSelectMapDetails(getAddress)
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const saveAddress = () => {
        if (selectMapDetails.line1 !== selectAddress.line1) {
            setSelectAddress({ ...selectMapDetails })
        }
        setOpenGoogleMap(false)
    }

    useEffect(() => {
        if (JSON.stringify(selectMapDetails) === '{}') {
            setSelectMapDetails(selectAddress)
        }
    }, [])

    return (
        <div className={`google_map_location_main ${openGoogleMap ? 'show' : 'hide'}`}>
            <div className="google_map">
                <div className="google_map_header">
                    <div onClick={() => setOpenGoogleMap(false)}>
                        <IonIcon
                            className="google_map_icon"
                            icon={arrowBackOutline}
                        />
                    </div>
                </div>
                <GoogleMap
                    mapContainerStyle={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    zoom={15}
                    center={center}
                    ref={(map) => setMyMap(map)}
                    onDragEnd={() => setTimeout(handleChange)}
                >
                    <div className="marker-google_location">
                        <div className="marker_div">
                            <img
                                className="marker_pic"
                                src={Config.EXTERNAL_IMAGES.ICONS.MAP_PIN}
                                alt=""
                            />
                        </div>
                        <div className="pulse-css">
                        </div>

                    </div>
                </GoogleMap>
            </div>
            <div className="other_thing">
                <div className='map_info_main_div'>
                    <div className='map_info_div'>
                        <p className='map_info_label'>Locality</p>
                        <p className='map_info'>{selectMapDetails?.locality}</p>
                    </div>
                    <div className='map_info_div'>
                        <p className='map_info_label'>City:</p>
                        <p className='map_info'>{selectMapDetails?.city}</p>
                    </div>
                    <div className='map_info_div'>
                        <p className='map_info_label'>State:</p>
                        <p className='map_info'>{selectMapDetails?.state}</p>
                    </div>
                    <div className='map_info_div'>
                        <p className='map_info_label'>Country:</p>
                        <p className='map_info'>{selectMapDetails?.country}</p>
                    </div>
                </div>
                <IonButton
                    onClick={saveAddress}
                    type="button"
                    className="save_proceed_btn"
                >
                    SAVE AND PROCEED
                </IonButton>
            </div>
        </div>
    );
};

export default GoogleMapAreaSelect;


