function TrackEvent(eventName: string, metaData?: any) {

    if (eventName === 'Session_started') {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const utmSource = urlParams.get('utm_source')
        const tmPayload = {
            event: 'Session_started',
            UTM: utmSource,
            Device: 'web', // TODO
            Event_time: +new Date(), // TODO
            'Referral URL': '',
            phone: metaData?.phone
        }
        window?.dataLayer.push(tmPayload);
    }

    if(eventName === 'click_topmenu'){
        window?.dataLayer.push({
            event: eventName,
            'menu_name': metaData
        })
    }
    
    if(eventName === 'click_schedule'){
        // console.log('click_schedule',{metaData})
        window?.dataLayer.push({
            event: eventName,
            pickup_weight: metaData?.pickup_weight,
            pickup_date: metaData?.pickup_date,
        })
    }

    if(eventName === "user_signup"){
        window?.dataLayer.push({
            event: eventName,
            user_name: metaData?.name,
            user_email: metaData?.email,
            pincode: metaData?.pincode
        })
    }

    if(eventName === "otp_entered"){
        window?.dataLayer.push({
            event: eventName,
        })
    }
}

export {
    TrackEvent
}