import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import dayjs from 'dayjs'

interface ITimeSlot {
    id: number;
    title: string;
}

const DEFAULT_DATA = {
    selected: {
        pickup_slot: {
            id: 0,
            title: 'Select Time'
        },
        expected_weight: {
            id: -1,
            title: 'ESTIMATED WEIGHT'
        },
        pickup_date: {
            title: 'Select Date',
            date: null
        }
    },
    schedule: {
        weight: null,
        date: {
            year: '',
            month: '',
            date: ''
        },
        time: {
            title: '',
            day: ''
        },
        repeat: {
            title: '',
            number: 0,
        },
        address: {
            id: 0,
        },
        photoes: {},
        // New Fields:
        pickup_date: '',
        pickup_slot: -1,
        expected_weight: '',
    },
    options: {
        addresses: [],
        timeSlots: [],
    }
}

const userSchedulePickupStore = create(devtools((set: any) => ({
    schedulePickupData: DEFAULT_DATA,
    // setAddressOptions: (options:any)
    setSchedule: (schedule: any) => {
        set((state: any) => ({
            schedulePickupData: {
                ...state.schedulePickupData,
                schedule: schedule,
            }
        }))
    },
    setAddress: (address: any) => {
        // console.log('SetAddress',{address})
        set((state: any) => ({
            schedulePickupData: {
                ...state.schedulePickupData,
                schedule: {
                    ...state.schedulePickupData.schedule,
                    address: address,
                }
            }
        }))
    },
    setPickupDate: (date: any) => {
        console.log({ date })
        set((state: any) => ({
            schedulePickupData: {
                ...state.schedulePickupData,
                selected: {
                    ...state.schedulePickupData.selected,
                    pickup_date: {
                        title: dayjs(date).format('dddd, MMM DD, YYYY'),
                        date: date,
                    }
                },
                schedule: {
                    ...state.schedulePickupData.schedule,
                    pickup_date: dayjs(date).format('YYYY-MM-DD'),
                }
            }
        }))
    },
    setPickupWeight: (weight: any) => {
        set((state: any) => ({
            schedulePickupData: {
                ...state.schedulePickupData,
                selected: {
                    ...state.schedulePickupData.selected,
                    expected_weight: weight
                },
                schedule: {
                    ...state.schedulePickupData.schedule,
                    expected_weight: weight.id,
                }
            }
        }))
    },
    setPickupTimeSlot: (timeSlot: any) => {
        set((state: any) => ({
            schedulePickupData: {
                ...state.schedulePickupData,
                selected: {
                    ...state.schedulePickupData.selected,
                    pickup_slot: timeSlot,
                },
                schedule: {
                    ...state.schedulePickupData.schedule,
                    pickup_slot: timeSlot.id,
                },
            }
        }))
    },
    setTimeslotOptions: (timeSlotOptions: any[]) => {
        set((state: any) => ({
            schedulePickupData: {
                ...state.schedulePickupData,
                options: {
                    ...state.schedulePickupData.options,
                    timeSlots: timeSlotOptions
                }
            }
        }))
    },
    resetPickupStore: () => {
        set((state: any) => ({
            schedulePickupData: {
                selected: DEFAULT_DATA.selected,
                schedule: {
                    ...DEFAULT_DATA.schedule,
                    address: state.schedulePickupData.schedule.address,
                },
                options: {
                    ...state.schedulePickupData.options,
                }
            }
        }))
    }
})))

export default userSchedulePickupStore;