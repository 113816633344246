import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { UseActiveTabShow, UserProfile } from "../App";
import PageTwo from "../Components/PageTwo/PageTwo";
import "./Tab2.css";

const Tab2: React.FC<any> = () => {
  const location = useLocation();
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [activeTabShow, setActiveTabShow] = useContext<any>(UseActiveTabShow);
  useEffect(() => {
    setProfile({ ...profile, location: location.pathname });
    setActiveTabShow(window.location.pathname);
  }, [window.location.pathname]);

  return <PageTwo />;
};

export default Tab2;
