import React, { useState } from "react"

const CustomInput = ({ clearErrors, customClass = '', errors, register, name = '', required, type = 'text', placeholder = '', maxLength = 500, optional = false }: any) => {
    const [val, setVal] = useState('');

    const onInputChange = (value: any) => {
        setVal(value)
        if (name === 'email') {
            let isValidEmail = validateEmail(value)
            if (isValidEmail) {
                clearErrors(name)
            }
        } else {
            clearErrors(name)
        }
    };

    const validateEmail = (email: string) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        return regex.test(email)
    };

    return (
        <div id="float-label" className={errors[name] ? 'border-red-1' : ''}>
            <input
                {...register(name, { required })}
                onChange={(e) => onInputChange(e.target.value)}
                type={type}
                name={name}
                maxLength={maxLength}
                className={`${val ? 'Active' : ''}  ${customClass}`}
            />
            <label htmlFor={name} className={`${val ? 'Active' : ''}  ${errors[name] ? 'text-red' : ''}  capitalize`}>{placeholder} {optional && `(Optional)`}</label>
        </div>


    )
}

export default CustomInput