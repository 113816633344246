import React from "react";
import "./ConfirmationPickup.css";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
} from "@ionic/react";
import Title from "../Title";
import { Link, useHistory } from "react-router-dom";
import Config from "../../Config"

interface PropsDetails {
  header: boolean;
}

const ConfirmationPickup: React.FC<PropsDetails> = ({ header }) => {
  const history = useHistory();
  const ontherSchedule = () => {
    history.push("/schedulefill");
  };

  return (
    <>
      <IonContent>
        <IonGrid className='pt-5'>
          <IonRow>
            <IonCol className="confirmation_box">
              <img
                 src={Config.EXTERNAL_IMAGES.PAGES.PICKUP_SUCCESS.CHECK_ICON}
                alt=""
              />
              <div className="confirmation_title">
                <Title title="Pickup Scheduled" />
              </div>
              <img
                className="confirmation_image"
                src={Config.EXTERNAL_IMAGES.PAGES.PICKUP_SUCCESS.BANNER}
                alt=""
              />
            </IonCol>
          </IonRow>
          <IonRow className="btn_box">
            <IonCol>
              <IonButton
                onClick={ontherSchedule}
                className="btn-confirmation"
                color="success"
              >
                Schedule Another
              </IonButton>
            </IonCol>
            <IonCol>
              <Link to="/viewPickUp">
                <IonButton className="btn-confirmation" color="success">
                  View Pickups
                </IonButton>
              </Link>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default ConfirmationPickup;
