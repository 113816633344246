import {
  IonButton,
  IonSpinner,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import ApiRequest from "../../Services/Api.Service";
import { UserProfile } from "../../App";
import useLocalStorage from "../../Services/useLocalStorage";

const SignUpOTP: React.FC<any> = ({ setPhoneStatus }) => {
  const { register, handleSubmit }: any = useForm();
  const [isActive, setIsActive] = useState(false);
  const [isActiveOtp, setIsActiveOtp] = useState(false);
  const [selectCategory, setSelectCategory] = useState("household");
  const [profile, setProfile] = useContext<any>(UserProfile);
  const history = useHistory();
  const [err, setErr] = useState({ status: false, message: "" });
  const [loadingSpin, setLoadingSping] = useState(false);
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );

  const onSubmit = (data: any) => {
    const totalData = { ...data, category: selectCategory };
    setLoadingSping(true);
    ApiRequest("PUT", "/profile/", {
      isAuthenticated: false,
      token: profile.token,
      body: totalData,
    }).then((res) => {
      setLoadingSping(false);
      if (res.success === true) {
        setLoggedInUser({ ...profile, ...data });
        setProfile({ ...profile, ...data });
        if (selectCategory === "household") {
          history.push("/home");
        }
        if (selectCategory === "enterprise") {
          history.push("/bussinessDetails");
        }
        if (selectCategory === "recycling") {
          history.push("/home");
        }
      } else {
        setErr({ status: true, message: res.message });
        setTimeout(() => {
          setPhoneStatus("phone");
        }, 1500);
      }
    });
  };

  const handleTextChange = (text: any) => {
    if (text !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handleOtpChange = (text: any) => {
    if (text !== "") {
      setIsActiveOtp(true);
    } else {
      setIsActiveOtp(false);
    }
  };

  return (
    <>
      <div className="signup_box">
        <h1 className="login_title">Sign Up</h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="webview_number_input_box">
          <div id="float-label">
            <input
                {...register('name')}
              type="text"
              maxLength={10}
              onChange={(e) => handleTextChange(e.target.value)}
              name="name"
              required
            />

            <label className={isActive ? "Active" : ""} htmlFor="email">
              Name
            </label>
          </div>
        </div>

        <div className="webview_number_input_box last_child sign_up_middle_child">
          <div id="float-label">
            <input
              onChange={(e) => handleOtpChange(e.target.value)}
              {...register('areaName')}

              type="text"
              name="areaName"
              required
            />
            <label className={isActiveOtp ? "Active" : ""} htmlFor="email">
              Area Name
            </label>
          </div>
        </div>
        <div className="webview_number_input_box sign_up_middle_child">
          <div id="float-label">
            <input
              {...register('email')}
              type="text"
              onChange={(e) => handleTextChange(e.target.value)}
              name="email"
            />

            <label className={isActive ? "Active" : ""} htmlFor="email">
              Email (Optional)
            </label>
          </div>
        </div>
        <div className="category">
          <h6>CATEGORY THAT BEST DESCRIBES YOU?</h6>
          <div className="category_box">
            <div className="category_box_box">
              <img
                className={
                  selectCategory === "household"
                    ? "selected_icon"
                    : "non_selected_icon"
                }
                onClick={() => setSelectCategory("household")}
                src="https://ikp.edgekit.net/y8s2vhk66ef/household_coloured1__R95zz9GH.png"
                alt=""
              />
              <p
                className={
                  selectCategory === "household"
                    ? "selected_text"
                    : "non_selected_text"
                }
              >
                HOUSEHOLD
              </p>
            </div>
            <div className="category_box_box">
              <img
                className={
                  selectCategory === "enterprise"
                    ? "selected_icon"
                    : "non_selected_icon"
                }
                onClick={() => setSelectCategory("enterprise")}
                src="https://ikp.edgekit.net/y8s2vhk66ef/enterprise_bw1_RZxZ80dRv.png"
                alt=""
              />
              <p
                className={
                  selectCategory === "enterprise"
                    ? "selected_text"
                    : "non_selected_text"
                }
              >
                ENTERPRISE
              </p>
            </div>
            <div className="category_box_box">
              <img
                className={
                  selectCategory === "recycling"
                    ? "selected_icon"
                    : "non_selected_icon"
                }
                onClick={() => setSelectCategory("recycling")}
                src="https://ikp.edgekit.net/y8s2vhk66ef/enterprise_bw1_RZxZ80dRv.png"
                alt=""
              />
              <p
                className={
                  selectCategory === "recycling"
                    ? "selected_text"
                    : "non_selected_text"
                }
              >
                RECYCLING HERO
              </p>
            </div>
          </div>
        </div>
        {err.status === true && (
          <p className="sign_resend_code invalid">{err.message}</p>
        )}

        <IonButton className="login_btn_web_view" color="success" type="submit">
          {loadingSpin ? <IonSpinner name="lines" /> : "Next"}
        </IonButton>
      </form>
    </>
  );
};

export default SignUpOTP;
