import { IonRow, IonChip, IonCol, IonGrid, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import PageThreeContent from "./../PageThreeContent/PageThreeContent";
import PageThreeTitle from "./../PageThreeContent/PageThreeTitle";

interface UpcomingProps {
  setClickPickupToShow: any;
}

const UpcomingPickup: React.FC<UpcomingProps> = (props) => {
  const [status, setStatus] = useState<any>("pending");

  return (
    <>
      <PageThreeTitle />
      <IonGrid>
        <IonRow>
          <IonCol className="pending_status">
            <IonChip
              color="tertiary"
              className={status === "pending" ? "activePending" : "pending"}
              onClick={(i) => setStatus("pending")}
            >
              Pending
            </IonChip>

            <IonChip
              color="success"
              className={
                status === "schedule"
                  ? "activeSchedule middle"
                  : "schedule middle"
              }
              onClick={(i) => setStatus("schedule")}
            >
              Schedule
            </IonChip>

            <IonChip
              color="danger"
              className={status === "cancelled" ? "activeCanceled" : "cancel"}
              onClick={(i) => setStatus("cancelled")}
            >
              Cancelled
            </IonChip>
          </IonCol>
        </IonRow>
      </IonGrid>
      <PageThreeContent
        setClickPickupToShow={props.setClickPickupToShow}
        status={[status, setStatus]}
      />
    </>
  );
};

export default UpcomingPickup;
